<template>
  <div class="sheetHeader">
    <img src="@/assets/newLogo.png" alt="" @click="linkTo" />
    <div class="sheet-btn-list">
      <!--  -->
      <div class="btn-list" :class="{ 'header-on': showHeaderNum == 1 }">
        <el-button type="text" @click="showHeader(1)">设置答题卡头</el-button>
        <div class="btn-list-detail">
          <div class="detail-list">
            <el-checkbox-group
              v-model="sheetHeader"
              @change="checkSheetContent()"
            >
              <el-checkbox label="4396" disabled> 答题卡题目 </el-checkbox>
              <el-checkbox v-if="GET_EXAM_INFORMATION.markType == 1" label="4">
                装订线
              </el-checkbox>
              <el-checkbox label="5">注意事项</el-checkbox>
              <el-checkbox label="2" :disabled="sheetHeader.indexOf('1') == -1">
                条形码
              </el-checkbox>
              <el-checkbox label="1" :disabled="sheetHeader.indexOf('2') == -1"
                >准考证号</el-checkbox
              >
              <el-input
                v-model="studentIdNum"
                class="student-id-num"
                oninput="value=value.replace(/[^0-9]/g,'')"
                @blur="studentIdNum = $event.target.value"
                @change="setNum"
                @input="setChangeNum"
                @keyup.enter.native="!studentIdNum ? 0 : studentIdNum"
              ></el-input>
              <el-checkbox label="3">AB卷</el-checkbox>
              <el-checkbox label="6" disabled>缺考</el-checkbox>
              <el-checkbox label="7" disabled>学生信息</el-checkbox>
              <el-checkbox label="8">试卷信息</el-checkbox>
              <el-checkbox label="9">二维码</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <div
        v-if="GET_EXAM_INFORMATION.markType == 0"
        class="btn-list"
        :class="{ 'header-on': showHeaderNum == 2 }"
      >
        <el-button type="text" @click="showHeader(2)">套红卡</el-button>
      </div>
      <div class="btn-list"><el-button type="text">|</el-button></div>
      <div class="btn-list" :class="{ 'header-on': showHeaderNum == 3 }">
        <el-button type="text" @click="showHeader(3)">客观题</el-button>
        <div class="btn-list-detail objective-detail">
          <div class="detail-list">
            <el-form
              ref="addQuestion"
              :model="addQuestion"
              :rules="rules"
              size="mini"
              label-width="100px"
            >
              <el-form-item label="题目名称：" prop="name">
                <el-input
                  v-model="addQuestion.name"
                  maxlength="40"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item label="起始题号：" prop="questionStartOrder">
                <el-input
                  v-model="addQuestion.questionStartOrder"
                  oninput="value=value.replace(/[^0-9]/g,'')"
                  @blur="addQuestion.questionStartOrder = $event.target.value"
                ></el-input>
              </el-form-item>
              <el-form-item label="小题个数：" prop="questionNum">
                <el-input v-model.number="addQuestion.questionNum"></el-input>
              </el-form-item>
              <el-form-item label="小题分值：" prop="score">
                <el-input
                  v-model="addQuestion.score"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                  @blur="addQuestion.score = $event.target.value"
                ></el-input>
              </el-form-item>
              <el-form-item label="选项个数：" prop="optionNum">
                <el-select
                  v-model.number="addQuestion.optionNum"
                  placeholder="选项个数"
                >
                  <el-option
                    v-for="(v, i) in optionArray"
                    :key="i"
                    :label="v.label"
                    :value="v.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="题目类型：">
                <el-radio-group v-model="addQuestion.subType">
                  <el-radio
                    v-for="(v, i) in choiceQuestionTypeList"
                    :key="i"
                    :label="v.value"
                    >{{ v.label }}</el-radio
                  >
                </el-radio-group>
              </el-form-item>
              <el-form-item
                v-if="addQuestion.subType == 2"
                label="漏选得分："
                prop="scoreMiss"
              >
                <el-input
                  v-model="addQuestion.scoreMiss"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                  @blur="addQuestion.scoreMiss = $event.target.value"
                ></el-input>
              </el-form-item>
              <div class="form-list">
                <el-checkbox v-model="addQuestion.additionalQid">
                  附加题号1（1），1（2）…
                </el-checkbox>
              </div>
              <div class="form-list">
                <el-checkbox v-model="addQuestion.mergeQid">
                  选择题合并展示
                </el-checkbox>
              </div>
              <el-form-item class="question-group" prop="questionGroupNum">
                <span>*</span
                ><el-input v-model="addQuestion.questionGroupNum"></el-input>
                道小题为一组
              </el-form-item>
              <el-form-item
                v-if="
                  GET_PRODUCTION.questionItem &&
                  GET_PRODUCTION.questionItem.length > 0
                "
                class="question-group"
              >
                在
                <el-select
                  v-model.number="addQuestion.questionIndex"
                  placeholder="请选择"
                  class="el-select-cnt"
                  clearable
                >
                  <el-option
                    v-for="(v, i) in GET_PRODUCTION.questionItem"
                    :key="i"
                    :label="v.name || i + 1"
                    :value="i"
                  >
                  </el-option>
                </el-select>
                题前插入
              </el-form-item>
              <el-form-item>
                <el-button
                  class="save-btn"
                  type="primary"
                  @click="save('addQuestion')"
                >
                  保存
                </el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <div
        v-if="GET_EXAM_INFORMATION.markType == 1"
        class="btn-list"
        :class="{ 'header-on': showHeaderNum == 4 }"
      >
        <el-button type="text" @click="showHeader(4)">填空题</el-button>
        <div class="btn-list-detail objective-detail">
          <div class="detail-list">
            <el-form
              ref="addQuestion"
              :model="addQuestion"
              :rules="rules"
              size="mini"
              label-width="100px"
            >
              <el-form-item label="题目名称：" prop="name">
                <el-input
                  v-model="addQuestion.name"
                  maxlength="40"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item label="起始题号：" prop="questionStartOrder">
                <el-input
                  v-model="addQuestion.questionStartOrder"
                  oninput="value=value.replace(/[^0-9]/g,'')"
                  @blur="addQuestion.questionStartOrder = $event.target.value"
                ></el-input>
              </el-form-item>
              <el-form-item label="小题个数：" prop="questionNum">
                <el-input v-model.number="addQuestion.questionNum"></el-input>
              </el-form-item>
              <el-form-item label="小题分值：" prop="score">
                <el-input
                  v-model="addQuestion.score"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                  @blur="addQuestion.score = $event.target.value"
                ></el-input>
              </el-form-item>
              <el-form-item
                class="is-required"
                label="每行题数："
                prop="eachRowNumber"
              >
                <el-select
                  v-model.number="addQuestion.eachRowNumber"
                  placeholder="每行题数"
                >
                  <el-option v-for="(v, i) in 3" :key="i" :label="v" :value="v">
                  </el-option>
                </el-select>
              </el-form-item>
              <!-- <el-form-item
                class="is-required"
                label="每题空数："
                prop="itemNumber"
              > -->
              <!-- <el-select
                  v-model.number="addQuestion.itemNumber"
                  placeholder="每题空数"
                >
                  <el-option v-for="(v, i) in 4" :key="i" :label="v" :value="v">
                  </el-option>
                </el-select>
              </el-form-item> -->
              <div class="form-list">
                <el-checkbox v-model="addQuestion.additionalQid">
                  附加题号1（1），1（2）…
                </el-checkbox>
              </div>
              <el-form-item
                v-if="
                  GET_PRODUCTION.questionItem &&
                  GET_PRODUCTION.questionItem.length > 0
                "
                class="question-group"
              >
                在
                <el-select
                  v-model.number="addQuestion.questionIndex"
                  placeholder="请选择"
                  class="el-select-cnt"
                  clearable
                >
                  <el-option
                    v-for="(v, i) in GET_PRODUCTION.questionItem"
                    :key="i"
                    :label="v.name || i + 1"
                    :value="i"
                  >
                  </el-option>
                </el-select>
                题前插入
              </el-form-item>
              <el-form-item>
                <el-button
                  class="save-btn"
                  type="primary"
                  @click="save('addQuestion')"
                >
                  保存
                </el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <div class="btn-list" :class="{ 'header-on': showHeaderNum == 5 }">
        <el-button type="text" @click="showHeader(5)">解答题</el-button>
        <div class="btn-list-detail objective-detail">
          <div class="detail-list">
            <el-form
              ref="addQuestion"
              :model="addQuestion"
              :rules="rules"
              size="mini"
              label-width="100px"
            >
              <el-form-item label="题目名称：" prop="name">
                <el-input
                  v-model="addQuestion.name"
                  maxlength="40"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item label="起始题号：" prop="questionStartOrder">
                <el-input
                  v-model="addQuestion.questionStartOrder"
                  oninput="value=value.replace(/[^0-9]/g,'')"
                  @blur="addQuestion.questionStartOrder = $event.target.value"
                ></el-input>
              </el-form-item>
              <el-form-item label="小题个数：" prop="questionNum">
                <el-input v-model.number="addQuestion.questionNum"></el-input>
              </el-form-item>
              <el-form-item label="小题分值：" prop="score">
                <el-input
                  v-model="addQuestion.score"
                  placeholder="多个分值使用'，'隔开"
                  @blur="addQuestion.score = $event.target.value"
                ></el-input>
              </el-form-item>
              <div v-if="GET_PRODUCTION.markType == 1" class="form-list">
                <el-checkbox v-model="addQuestion.decimals">
                  使用0.5分
                </el-checkbox>
              </div>
              <el-form-item
                v-if="
                  GET_PRODUCTION.questionItem &&
                  GET_PRODUCTION.questionItem.length > 0
                "
                class="question-group"
              >
                在
                <el-select
                  v-model.number="addQuestion.questionIndex"
                  placeholder="请选择"
                  class="el-select-cnt"
                  clearable
                >
                  <el-option
                    v-for="(v, i) in GET_PRODUCTION.questionItem"
                    :key="i"
                    :label="v.name || i + 1"
                    :value="i"
                  >
                  </el-option>
                </el-select>
                题前插入
              </el-form-item>
              <el-form-item>
                <el-button
                  class="save-btn"
                  type="primary"
                  @click="save('addQuestion')"
                >
                  保存
                </el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <div class="btn-list" :class="{ 'header-on': showHeaderNum == 6 }">
        <el-button type="text" @click="showHeader(6)">选做题</el-button>
        <div class="btn-list-detail objective-detail">
          <div class="detail-list">
            <el-form
              ref="addQuestion"
              :model="addQuestion"
              :rules="rules"
              size="mini"
              label-width="100px"
            >
              <el-form-item label="题目名称：" prop="name">
                <el-input
                  v-model="addQuestion.name"
                  maxlength="40"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item label="小题个数：" prop="questionNum">
                <el-input v-model.number="addQuestion.questionNum"></el-input>
              </el-form-item>
              <el-form-item label="小题分值：" prop="score">
                <el-input
                  v-model="addQuestion.score"
                  placeholder="多个分值使用'，'隔开"
                  @blur="addQuestion.score = $event.target.value"
                ></el-input>
              </el-form-item>
              <div v-if="GET_PRODUCTION.markType == 1" class="form-list">
                <el-checkbox v-model="addQuestion.decimals">
                  使用0.5分
                </el-checkbox>
              </div>
              <div class="question-list-box">
                <div>涂抹项名称</div>
                <div>
                  <div
                    v-for="(v, i) in questionNameList"
                    :key="i"
                    class="question-item"
                  >
                    <el-input v-model="v.questionNum" placeholder=""></el-input>
                    <span></span>
                  </div>
                </div>
              </div>
              <el-form-item
                v-if="
                  GET_PRODUCTION.questionItem &&
                  GET_PRODUCTION.questionItem.length > 0
                "
                class="question-group"
              >
                在
                <el-select
                  v-model.number="addQuestion.questionIndex"
                  placeholder="请选择"
                  class="el-select-cnt"
                  clearable
                >
                  <el-option
                    v-for="(v, i) in GET_PRODUCTION.questionItem"
                    :key="i"
                    :label="v.name || i + 1"
                    :value="i"
                  >
                  </el-option>
                </el-select>
                题前插入
              </el-form-item>
              <el-form-item>
                <el-button
                  class="save-btn"
                  type="primary"
                  @click="save('addQuestion')"
                >
                  保存
                </el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <div
        v-if="showComposition()"
        class="btn-list"
        :class="{ 'header-on': showHeaderNum == 7 }"
      >
        <el-button type="text" @click="showHeader(7)">作文题</el-button>
        <div class="btn-list-detail objective-detail composition">
          <div class="detail-list">
            <el-form
              ref="addQuestion"
              :model="addQuestion"
              :rules="rules"
              size="mini"
              label-width="100px"
            >
              <el-form-item label="题目名称：" prop="name">
                <el-input
                  v-model="addQuestion.name"
                  maxlength="40"
                  show-word-limit
                ></el-input>
              </el-form-item>

              <el-form-item label="小题分值：" prop="score">
                <el-input
                  v-model="addQuestion.score"
                  placeholder=""
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                  @blur="addQuestion.score = $event.target.value"
                ></el-input>
              </el-form-item>

              <el-form-item label="字数间隔：" prop="score">
                <el-input
                  v-model="addQuestion.sizeInterval"
                  placeholder=""
                  oninput="value=value.replace(/[^0-9]/g,'')"
                  @blur="addQuestion.sizeInterval = $event.target.value"
                ></el-input>
              </el-form-item>
              <p style="margin-bottom: 18px; color: #9e9e9e">
                字数间隔为作文题‘小三角形字数’，设为0则不显示
              </p>
              <el-form-item label="题目行数：" class="is-required row-imput">
                <el-input v-model.number="rowNum"></el-input>
                （每行
                <el-input v-model.number="sizeRow"></el-input>
                字）共<span class="num">{{ rowNum * sizeRow }}</span
                >字
              </el-form-item>
              <div v-if="GET_PRODUCTION.markType == 1" class="form-list">
                <el-checkbox v-model="addQuestion.decimals">
                  使用0.5分
                </el-checkbox>
              </div>
              <el-form-item
                v-if="
                  GET_PRODUCTION.questionItem &&
                  GET_PRODUCTION.questionItem.length > 0
                "
                class="question-group"
              >
                在
                <el-select
                  v-model.number="addQuestion.questionIndex"
                  placeholder="请选择"
                  class="el-select-cnt"
                  clearable
                >
                  <el-option
                    v-for="(v, i) in GET_PRODUCTION.questionItem"
                    :key="i"
                    :label="v.name || i + 1"
                    :value="i"
                  >
                  </el-option>
                </el-select>
                题前插入
              </el-form-item>
              <el-form-item>
                <el-button
                  class="save-btn"
                  type="primary"
                  @click="save('addQuestion')"
                >
                  保存
                </el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <div class="btn-list" :class="{ 'header-on': showHeaderNum == 8 }">
        <el-button type="text" @click="showHeader(8)">非作答区域</el-button>
        <div class="btn-list-detail objective-detail">
          <div class="detail-list">
            <el-form
              ref="addQuestion"
              :model="addQuestion"
              :rules="rules"
              size="mini"
              label-width="100px"
            >
              <el-form-item
                v-if="
                  GET_PRODUCTION.questionItem &&
                  GET_PRODUCTION.questionItem.length > 0
                "
                class="question-group"
              >
                在
                <el-select
                  v-model.number="addQuestion.questionIndex"
                  placeholder="请选择"
                  class="el-select-cnt"
                  clearable
                >
                  <el-option
                    v-for="(v, i) in GET_PRODUCTION.questionItem"
                    :key="i"
                    :label="v.name || i + 1"
                    :value="i"
                  >
                  </el-option>
                </el-select>
                题前插入
              </el-form-item>
              <el-form-item>
                <el-button
                  class="save-btn"
                  type="primary"
                  @click="save('addQuestion')"
                >
                  保存
                </el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <div class="btn-list"><el-button type="text">|</el-button></div>
      <div class="btn-list" :class="{ 'header-on': showHeaderNum == 9 }">
        <el-button type="text" @click="showHeader(9)">查看分值</el-button>
        <div class="btn-list-detail objective-detail score-box">
          <!-- <template > -->
          <div
            v-for="(item, index) in GET_PRODUCTION.questionItem"
            :key="index"
            class="question-box"
          >
            <template v-if="item.type == 1 || item.type == 2">
              <div class="question-box-name">{{ item.name }}</div>
              <!-- {{ item.questionGroup }} -->
              <div
                v-for="(v, i) in item.questionGroup"
                :key="i"
                class="question-box-list"
              >
                <span>{{ v.groupName }}</span>
                共{{ v.score * v.questionNum }}分
              </div>
            </template>
            <template v-if="item.type == 3 || item.type == 4">
              <div class="question-box-name">{{ item.name }}</div>
              <div
                v-for="(v, i) in item.questionOrderList"
                :key="i"
                class="question-box-list"
              >
                <span>{{ v.questionNum }}</span>
                {{ v.score }}分
              </div>
            </template>
            <template v-if="item.type == 5">
              <div class="question-box-name">
                <span>{{ item.name }}</span> {{ item.score }}分
              </div>
            </template>
          </div>
          <!-- </template> -->
          <div class="question-box-name">
            <span>总分：</span> {{ getAddScore() }}分
          </div>
        </div>
      </div>
      <div class="btn-list" :class="{ 'header-on': showHeaderNum == 10 }">
        <el-button type="text" @click="showHeader(10)"
          >修改答题卡格式</el-button
        >
        <div class="btn-list-detail objective-detail score-box type-item-box">
          <div class="type-item">
            答题卡类型：
            <el-radio-group v-model="subSheetType.sheetType">
              <el-radio :label="1">A4</el-radio>
              <el-radio :label="2">A3两栏</el-radio>
              <el-radio :label="3">A3三栏</el-radio>
            </el-radio-group>
          </div>
          <div class="type-item">
            选择题格式：
            <el-radio-group v-model="subSheetType.objectiveType">
              <el-radio :label="2">竖向分组，横向排版</el-radio>
              <el-radio :label="3">横向分组，竖向排版</el-radio>
            </el-radio-group>
          </div>
          <div style="text-align: center">
            <el-button
              class="save-btn"
              type="primary"
              :loading="btnLoading"
              @click="save('addQuestion')"
            >
              保存
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { choiceQuestionTypeList, optionArray } from "@/core/util/sheetPaper";

import {
  getQuestionMax,
  capitalNumberFn,
  // unitConversion,
} from "@/core/util/util";
import {
  // exampapersheetPage,
  // delExampapersheet,
  // exampapersheetExport,
  // exampapersheetDuplicate,
  putExamPapersheet,
} from "@/core/api/exam/sheet";
import { getExamPaperSheet } from "@/core/api/exam/sheet";
import { mapGetters } from "vuex";
export default {
  name: "SheetHeader",
  data() {
    const valMax = (rule, value, callback) => {
      if (!Number.isInteger(value)) {
        callback(new Error("请输入数字值"));
      } else {
        if (value > 200) {
          callback(new Error("请输入小于两百的数字"));
        } else {
          callback();
        }
      }
    };
    const groupMax = (rule, value, callback) => {
      // console.log(value);
      value = Number(value);
      if (!Number.isInteger(value)) {
        callback(new Error("请输入正整数！"));
      } else {
        if (value > 20) {
          callback(new Error("请输入小于等于20的数字"));
        } else {
          callback();
        }
      }
    };
    return {
      subSheetType: {
        sheetType: 1,
        objectiveType: 1,
      },
      rowNum: 77,
      sizeRow: 13,
      topic: true,
      btnLoading: false,
      sheetHeader: [],
      studentIdNum: 12,
      showHeaderNum: 0,
      optionArray: optionArray(),
      choiceQuestionTypeList: choiceQuestionTypeList,
      questionNameList: [
        { questionNum: "" },
        { questionNum: "" },
        { questionNum: "" },
        { questionNum: "" },
        { questionNum: "" },
      ],

      // 添加的试题参数
      addQuestion: {
        // 题目
        name: "一",
        // 起始题号
        questionStartOrder: 1,
        // 试题数量
        questionNum: 5,
        // 分数
        score: 3,
        // 选项个数  注意  1 是 T-F  需要判断 其他就是 A-B A-C正常类型
        optionNum: 4,
        // 多选题或者单选题
        subType: 1,
        // 漏选得分  理论上只有多选题有
        scoreMiss: 0,
        // 附加题号
        additionalQid: false,
        // 选择题题号合并展示
        mergeQid: true,
        // 多少题为一组
        questionGroupNum: 5,
        // 每行题数量  填空题
        eachRowNumber: 2,
        // 每题数量 填空题
        itemNumber: 1,
        // 是否使用0.5分
        decimals: false,
        // 试题插入位置
        questionIndex: "",
        // 作文三角显示字数
        sizeInterval: 100,
      },
      rules: {
        name: [{ required: true, message: "请输入题目", trigger: "blur" }],
        questionStartOrder: [
          { required: true, message: "请输入题号", trigger: "blur" },
        ],
        questionNum: [
          { required: true, message: "请输入小题数量", trigger: "blur" },
          { validator: valMax, trigger: "blur" },
        ],
        questionGroupNum: [{ validator: groupMax, trigger: "change" }],
        score: [{ required: true, message: "请输入小题分值", trigger: "blur" }],
        scoreMiss: [
          { required: true, message: "请输入漏选得分", trigger: "blur" },
        ],
        optionNum: [
          { required: true, message: "请选择客观题数量", trigger: "change" },
          { type: "number", message: "小题数量必须为数字" },
        ],
      },
    };
  },
  computed: {
    ...mapGetters([
      "GET_SHEET_HEADER",
      "GET_EXAM_INFORMATION",
      "GET_PRODUCTION",
      "GET_LENGTH",
    ]),
  },
  watch: {
    GET_PRODUCTION: {
      deep: true,
      handler() {
        if (this.GET_PRODUCTION.sheetHead.studentId) {
          this.studentIdNum =
            this.GET_PRODUCTION.sheetHead.studentId.studentIdNum;
        }
      },
    },
    GET_SHEET_HEADER: {
      deep: true,
      handler() {
        this.sheetHeader = [...this.GET_SHEET_HEADER];
      },
    },
    GET_LENGTH: {
      deep: true,
      handler() {
        // console.log(this.showTab);
        if (this.GET_LENGTH && !this.showTab) {
          this.showTab = true;
          this.$alert("内容超长，请重新设置答题卡！", "提示", {
            confirmButtonText: "确定",
          })
            .then(() => {
              this.showTab = false;
            })
            .catch(() => {
              this.showTab = false;
            });
        }
      },
    },
  },
  created() {
    this.sheetHeader = [...this.GET_SHEET_HEADER];
    // 鼠标点击
    window.addEventListener("mousedown", this.handleonmousedown);
  },
  destroyed() {
    window.removeEventListener("mousedown", this.handleonmousedown);
  },
  methods: {
    showComposition() {
      let arr = [6239, 6247, 6248, 6249, 6250, 6251, 6314, 6448, 1];
      if (arr.indexOf(this.GET_EXAM_INFORMATION.subjectId) != -1) {
        return true;
      } else {
        return false;
      }
    },
    setQuestionGroupNum() {
      if (this.addQuestion.questionGroupNum > 20) {
        this.addQuestion.questionGroupNum =
          this.addQuestion.questionGroupNum.substr(
            0,
            this.addQuestion.questionGroupNum.length - 1
          );
        this.addQuestion.questionGroupNum = Number(
          this.addQuestion.questionGroupNum
        );
      }
    },
    handleonmousedown($event) {
      let str = "";
      const urlPath =
        $event.path || ($event.composedPath && $event.composedPath());
      urlPath.map((index) => {
        str = str + index.className;
      });
      // console.log(str);
      if (
        str.indexOf("btn-list-detail") == -1 &&
        str.indexOf("sheet-btn-list") == -1 &&
        str.indexOf("el-scrollbar") == -1 &&
        str.indexOf("el-button") == -1 &&
        str.indexOf("el-message") == -1 &&
        this.showHeaderNum
      ) {
        if (this.showHeaderNum == 1) {
          if (Number(this.studentIdNum) > 0) {
            this.showHeaderNum = 0;
          }
        } else {
          this.showHeaderNum = 0;
        }
      }
    },
    setChangeNum() {
      // console.log(this.studentIdNum);
      if (this.studentIdNum > 20) {
        this.studentIdNum = this.studentIdNum.substr(
          0,
          this.studentIdNum.length - 1
        );
      }
    },
    setNum() {
      if (Number(this.studentIdNum) > 0) {
        // this.showHeaderNum = 0;
      } else {
        this.$message({
          message: "请输入正常准考证号位数！",
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (this.GET_PRODUCTION.sheetHead.studentId) {
        this.GET_PRODUCTION.sheetHead.studentId.studentIdNum = parseInt(
          this.studentIdNum
        );
        this.GET_PRODUCTION.sheetHead.studentId.width =
          this.GET_PRODUCTION.sheetHead.studentId.studentIdNum * 6;
        this.$store.commit("SET_PRODUCTION", this.GET_PRODUCTION);
      }
    },
    getAddScore() {
      let score = 0;
      if (!this.GET_PRODUCTION.questionItem) {
        return;
      }
      this.GET_PRODUCTION.questionItem.map((item) => {
        if (item.type == 1 || item.type == 2) {
          item.questionGroup.map((index) => {
            score = score + index.score * index.questionNum;
          });
        }
        // console.log(score);
        if (item.type == 3 || item.type == 4) {
          item.questionOrderList.map((index) => {
            score = score + Number(index.score);
          });
        }
        // console.log(score);
        if (item.type == 5) {
          score = score + Number(item.score);
        }
      });
      return score;
    },
    linkTo() {
      this.$router.go(-1);
    },
    undataQuestion(data) {
      //  questionItem 试题的详情以及列表 如果不存在  就添加一个进去
      if (
        !this.GET_PRODUCTION.questionItem ||
        this.GET_PRODUCTION.questionItem.length == 0
      ) {
        this.GET_PRODUCTION.questionItem = [];
      }
      if (
        this.addQuestion.questionIndex > 0 ||
        this.addQuestion.questionIndex === 0
      ) {
        this.GET_PRODUCTION.questionItem.splice(
          this.addQuestion.questionIndex,
          0,
          data
        );
      } else {
        this.GET_PRODUCTION.questionItem.push(data);
      }
      // 根据试题组设置

      this.$store.commit("SET_PRODUCTION", this.GET_PRODUCTION);
      this.showHeaderNum = 0;
      setTimeout(() => {
        this.$store.commit("SET_SCROOL", data.id);
      }, 200);
    },
    // 新增客观题
    addObjective() {
      // console.log(this.addQuestion);
      // console.log(this.GET_PRODUCTION);
      // 里层的试题组
      let questionData = {
        questionStartOrder: "",
        questionVal: "",
        questionNum: "",
        score: "",
        optionNum: "",
        subType: "",
        scoreMiss: "",
      };
      for (let i in questionData) {
        questionData[i] = this.addQuestion[i];
      }
      questionData.questionStartOrder = Number(questionData.questionStartOrder);
      questionData.id = new Date().getTime();
      questionData.groupName =
        questionData.questionStartOrder +
        "-" +
        (questionData.questionNum + questionData.questionStartOrder - 1);
      if (this.addQuestion.additionalQid) {
        questionData.groupName =
          questionData.questionStartOrder +
          "(1) - " +
          questionData.questionStartOrder +
          `(${questionData.questionNum})`;
      }
      // 外层的大题组
      let data = {
        additionalQid: "",
        mergeQid: "",
        name: "",
        questionGroupNum: "",
        // 题目简介
        intro: "",
        only: "",
      };
      for (let i in data) {
        data[i] = this.addQuestion[i];
      }
      data.id = new Date().getTime();
      data.type = 1;
      // type 1 客观题 2 判断题 3主观题
      // 1 题目  2  分值  3 边框
      data.showQuestionTitleArr = [1, 2, 3];
      data.questionTitleArr = [1, 2, 3];
      // 内部的试题组
      data.questionGroup = [];
      data.questionGroup.push(questionData);
      //添加试题
      this.undataQuestion(data);
    },
    // 新增填空题
    addCompletion() {
      // console.log(this.addQuestion);
      // 里层的试题组
      let questionData = {
        questionStartOrder: "",
        questionVal: "",
        questionNum: "",
        score: "",
        eachRowNumber: "",
        itemNumber: "",
        additionalQid: "",
      };

      for (let i in questionData) {
        questionData[i] = this.addQuestion[i];
      }
      questionData.groupName =
        questionData.questionStartOrder +
        "-" +
        (questionData.questionNum + questionData.questionStartOrder - 1);
      if (this.addQuestion.additionalQid) {
        questionData.groupName =
          questionData.questionStartOrder +
          "(1) - " +
          questionData.questionStartOrder +
          `(${questionData.questionNum})`;
      }
      questionData.id = new Date().getTime();
      // 外层的大题组
      let data = {
        additionalQid: "",
        mergeQid: "",
        name: "",
        questionGroupNum: "",
        // 题目简介
        intro: "",
        only: "",
      };
      for (let i in data) {
        data[i] = this.addQuestion[i];
      }
      data.id = new Date().getTime();
      data.type = 2;
      // type 1 客观题 2 判断题 3主观题
      // 1 题目  2  分值  3 边框
      data.questionTitleArr = [1, 2, 3];
      data.showQuestionTitleArr = [1, 2, 3];
      // 内部的试题组
      data.questionGroup = [];
      data.questionGroup.push(questionData);

      //添加试题
      this.undataQuestion(data);
    },
    // 新增解答题
    addSubjective() {
      // console.log(this.addQuestion);
      if (!this.addQuestion.score) {
        this.$message({
          message: "请输入分数",
          type: "warning",
          showClose: true,
        });
        return;
      }
      this.addQuestion.score = this.addQuestion.score
        .toString()
        .replace(/，/g, ",");
      let score = this.addQuestion.score.split(",");
      if (score.length != this.addQuestion.questionNum) {
        this.$message({
          message: "请输入和试题数量相同的分数数量",
          type: "warning",
          showClose: true,
        });
        return;
      }
      // console.log(score);
      let questionData = {
        questionStartOrder: "",
        questionNum: "",
        score: "",
        questionVal: "",
        decimals: "",
      };
      for (let i in questionData) {
        questionData[i] = this.addQuestion[i];
      }
      questionData.id = new Date().getTime();
      // 外层的大题组
      let data = {
        name: "",
        // 题目简介
        intro: "",
        only: "",
      };
      for (let i in data) {
        data[i] = this.addQuestion[i];
      }
      data.questionTitleArr = [1, 2, 3];
      data.showQuestionTitleArr = [1, 2];
      data.id = new Date().getTime();
      data.type = 3;
      // type 1 客观题 2 判断题 3主观题

      // 内部的试题组
      data.questionGroup = [];
      data.questionGroup.push(questionData);
      let questionOrderList = [];
      data.questionGroup.map((index) => {
        // let score = index.score.split(",");
        for (let i = 0; i < index.questionNum; i++) {
          let questionArr = {
            width: "",
            height: 44,
            extendArr: [],
            editListType: false,
            type: 3,
            id: new Date().getTime() + i,
          };
          for (let i = 0; i < 5; i++) {
            questionArr.extendArr.push({
              content: "",
            });
          }
          if (this.GET_PRODUCTION.markType == 1) {
            questionArr.height = questionArr.height + 5.5;
          }
          let ArrayData = {
            score: score[i],
            questionNum: Number(index.questionStartOrder) + i,
            value: Number(index.questionVal) + i,
            content: "",
          };
          // 1 题目  2  分值  3 0.5分
          if (index.decimals) {
            questionArr.showQuestionTitleArr = [1, 2, 3];
          } else {
            questionArr.showQuestionTitleArr = [1, 2];
          }

          Object.assign(questionArr, ArrayData);
          questionArr.width = this.GET_PRODUCTION.paperContent.width - 5;
          questionOrderList.push(Object.assign({}, questionArr));
        }
      });
      data.questionOrderList = questionOrderList;
      //添加试题
      this.undataQuestion(data);
    },
    // 新增非作答区域
    addNotQuestion() {
      let data = {
        height: 44,
        type: 6,
      };
      if (
        !this.GET_PRODUCTION.questionItem ||
        this.GET_PRODUCTION.questionItem.length == 0
      ) {
        this.GET_PRODUCTION.questionItem = [];
      }

      this.GET_PRODUCTION.questionItem.push(data);
      // 根据试题组设置

      this.$store.commit("SET_PRODUCTION", this.GET_PRODUCTION);
      this.showHeaderNum = 0;
      setTimeout(() => {
        this.$store.commit("SET_SCROOL", data.id);
      }, 200);
    },
    // 新增选做题
    chooseQuestion() {
      let namelist = this.questionNameList.filter(
        (item) => item.questionNum != ""
      );
      if (namelist.length < 2) {
        this.$message({
          message: "至少输入两个题号",
          type: "warning",
          showClose: true,
        });
        return;
      }

      let str = 0;
      let arr = [];

      namelist.map((item) => {
        if (this.strlen(item.questionNum) > str) {
          str = this.strlen(item.questionNum);
        }
        let itemstr = item.questionNum.toString();
        if (itemstr.indexOf("  ") != -1) {
          arr.push(itemstr);
        } else {
          let newStr = itemstr.trim();
          if (newStr != itemstr) {
            arr.push(itemstr);
          }
        }
      });
      namelist.map((item) => {
        item.width = 12.1 + 1.6 * str;
      });
      if (arr.length > 0) {
        this.$message({
          message: `试题 ${arr.join(
            ","
          )} 题号包含首尾空格或连续空格，请修改后提交！`,
          type: "warning",
          showClose: true,
        });
        return;
      }
      // console.log(this.addQuestion);
      if (!this.addQuestion.score) {
        this.$message({
          message: "请输入分数",
          type: "warning",
          showClose: true,
        });
        return;
      }
      this.addQuestion.score = this.addQuestion.score
        .toString()
        .replace(/，/g, ",");
      let score = this.addQuestion.score.split(",");
      if (score.length != this.addQuestion.questionNum) {
        this.$message({
          message: "请输入和试题数量相同的分数数量",
          type: "warning",
          showClose: true,
        });
        return;
      }
      // console.log(score);
      let questionData = {
        questionStartOrder: "",
        questionNum: "",
        score: "",
        questionVal: "",
        decimals: "",
      };
      for (let i in questionData) {
        questionData[i] = this.addQuestion[i];
      }
      questionData.id = new Date().getTime();
      // 外层的大题组
      let data = {
        name: "",
        // 题目简介
        only: "",
        intro: "",
      };
      for (let i in data) {
        data[i] = this.addQuestion[i];
      }
      data.questionTitleArr = [1, 2, 3];
      data.showQuestionTitleArr = [1, 2];
      data.id = new Date().getTime();
      data.type = 4;
      // type 1 客观题 2 判断题 3主观题 4选做题

      // 内部的试题组
      data.questionGroup = [];
      data.questionGroup.push(questionData);
      let questionOrderList = [];
      let indexNamelist = namelist.map((index) => index.questionNum);
      data.questionGroup.map((index) => {
        // let score = index.score.split(",");
        for (let i = 0; i < index.questionNum; i++) {
          let questionArr = {
            questionName: i + 1,
            width: "",
            height: 48,
            extendArr: [],
            editListType: false,
            type: 4,
            id: new Date().getTime() + i,
          };
          for (let i = 0; i < 5; i++) {
            questionArr.extendArr.push({
              content: "",
            });
          }
          if (this.GET_PRODUCTION.markType == 1) {
            questionArr.height = questionArr.height + 5.5;
          }
          let questionNameList = JSON.parse(JSON.stringify(namelist));
          if (index.questionNum > 1) {
            questionNameList.map((key) => {
              key.Qname = key.questionNum;
              key.questionNum = key.questionNum + "（" + (i + 1) + "）";
            });
          } else {
            questionNameList.map((key) => {
              key.Qname = key.questionNum;
            });
          }

          let ArrayData = {
            score: score[i],
            questionNum:
              indexNamelist.join("/") +
              (index.questionNum > 1 ? "（" + (i + 1) + "）" : ""),
            value: Number(index.questionVal) + i,
            nameList: questionNameList,
            content: "",
          };
          // 1 题目  2  分值  3 0.5分
          if (index.decimals) {
            questionArr.showQuestionTitleArr = [1, 2, 3];
          } else {
            questionArr.showQuestionTitleArr = [1, 2];
          }

          Object.assign(questionArr, ArrayData);
          questionArr.width = this.GET_PRODUCTION.paperContent.width - 5;
          questionOrderList.push(Object.assign({}, questionArr));
        }
      });

      // console.log(str);
      data.namelist = namelist;
      data.questionOrderList = questionOrderList;
      data.width = this.GET_PRODUCTION.paperContent.width - 5;
      data = JSON.parse(JSON.stringify(data));

      //添加试题
      this.undataQuestion(data);
    },
    // 作文题
    composition() {
      // 外层的大题组
      let data = {
        name: "",
        // 题目简介
        intro: "",
        value: "",
        score: "",
        questionNum: "",
        decimals: "",
        only: "",
        sizeInterval: "",
      };

      if (this.sizeRow < 6) {
        this.$message({
          message: "每行至少6字！",
          type: "warning",
          showClose: true,
        });
        return;
      }
      for (let i in data) {
        data[i] = this.addQuestion[i];
      }
      let strName = data.name.toString();
      let newStr = strName.trim();
      if (strName.indexOf("  ") != -1 || newStr != strName) {
        this.$message({
          message: `试题题号包含首尾空格或连续空格，请修改后提交！`,
          type: "warning",
          showClose: true,
        });
        return;
      }
      data.showQuestionTitleArr = [1, 2];
      data.questionTitleArr = [1, 2, 3];
      data.id = new Date().getTime();
      data.type = 5;
      data.rowNum = this.rowNum;
      data.sizeRow = this.sizeRow;
      data.editTitleType = false;
      data.editListType = false;
      data.questionNum = this.addQuestion.questionStartOrder;
      data.value = this.addQuestion.questionStartOrder;

      //添加试题
      this.undataQuestion(data);
    },

    // 非作答区域
    addNotQ() {
      let data = {
        type: 6,
        height: 38,
        editTitleType: false,
        editListType: false,
        questionTitleArr: [3],
        content:
          "<p>　</p><p>　</p><p style='text-align:center;'><em><span style='font-size:24px;'>请勿在此区域作答</span></em></p>",
      };
      data.id = new Date().getTime();

      //添加试题
      this.undataQuestion(data);
    },
    // 新增试题 判断试题参数是否已填上
    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert("submit!");
          // 如果是客观题
          if (this.showHeaderNum == 3) {
            this.addObjective();
          }
          // 新增填空题
          if (this.showHeaderNum == 4) {
            this.addCompletion();
          }
          // 新增解答题
          if (this.showHeaderNum == 5) {
            this.addSubjective();
          }
          // 新增选做题
          if (this.showHeaderNum == 6) {
            this.chooseQuestion();
          }
          // 新增选作文
          if (this.showHeaderNum == 7) {
            this.composition();
          }
          // 新增非作答区域
          if (this.showHeaderNum == 8) {
            this.addNotQ();
          }
          // 修改答题卡基础信息
          if (this.showHeaderNum == 10) {
            this.editSheet();
          }
        } else {
          return false;
        }
      });
    },

    strlen(str) {
      var len = 0;
      str = str.toString();
      for (var i = 0; i < str.length; i++) {
        var c = str.charCodeAt(i);
        //单字节加1
        if ((c >= 0x0001 && c <= 0x007e) || (0xff60 <= c && c <= 0xff9f)) {
          len++;
        } else {
          len += 2;
        }
      }
      return len;
    },
    getQuestionBlank(namelist) {
      let arr = [];
      namelist.map((item) => {
        let str = item.questionNum.toString();
        if (str.indexOf("  ") != -1) {
          arr.push(str);
        } else {
          let newStr = str.trim();
          if (newStr != str) {
            arr.push(str);
          }
        }
      });
      // console.log(arr);
      if (arr.length > 0) {
        const h = this.$createElement;
        this.$msgbox({
          title: "数据异常",
          message: h("p", null, [
            h("span", null, "试题"),
            h("span", { style: "color: red" }, arr.join(",")),
            h("span", null, "题号包含首尾空格或连续空格，请修改后提交！ "),
          ]),
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(() => {})
          .catch(() => {});
        return true;
      } else {
        return false;
      }
      // console.log();
    },
    // 切换选项后  通知父组件更新json
    checkSheetContent() {
      this.$store.commit("SET_SHEET_HEADER", this.sheetHeader);
      this.$emit("uploadHeader");
    },
    saveExampapersheetcontent() {
      this.$emit("saveExampapersheetcontent");
    },
    download() {
      this.$emit("download");
    },
    clear() {
      this.$emit("clear");
      // this.GET_PRODUCTION.questionItem = [];
      // this.$store.commit("SET_PRODUCTION", this.GET_PRODUCTION);
    },
    editSheet() {
      this.$confirm(
        "修改答题卡信息会修改重新排版当前答题卡, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(async () => {
          await this.editFile();
          await this.getExamPaperSheet();
          this.$parent.hearSetJson();
          this.showHeaderNum = 0;
        })
        .catch(() => {
          this.showHeaderNum = 0;
        });
    },
    async editFile() {
      let data = this.subSheetType;
      data.uuid = this.GET_EXAM_INFORMATION.uuid;
      if (this.subSheetType.sheetType == 1) {
        data.paperType = 1;
        data.columnCount = 1;
      }
      if (this.subSheetType.sheetType == 2) {
        data.paperType = 2;
        data.columnCount = 2;
      }
      if (this.subSheetType.sheetType == 3) {
        data.paperType = 2;
        data.columnCount = 3;
      }

      this.btnLoading = true;
      await putExamPapersheet(data)
        .then(() => {
          this.$message({
            type: "success",
            message: "修改成功!",
            showClose: true,
          });
          this.btnLoading = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },

    // 获取答题卡基本信息
    async getExamPaperSheet() {
      await getExamPaperSheet(this.$route.query.sheetId).then((res) => {
        this.$parent.examInformation = res.data.data;
        this.$store.commit("SET_EXAM_INFORMATION", res.data.data);
      });
    },
    setRed() {
      // console.log(this.sheetHeader);
      if (this.sheetHeader.indexOf("10") == -1) {
        this.sheetHeader.push("10");
      } else {
        this.sheetHeader = this.sheetHeader.filter((item) => item != "10");
      }
      // console.log(this.sheetHeader);
      this.$store.commit("SET_SHEET_HEADER", this.sheetHeader);
      this.$emit("uploadHeader");
      // if (this.GET_PRODUCTION.isRed) {
      //   this.GET_PRODUCTION.isRed = false;
      // } else {
      //   this.GET_PRODUCTION.isRed = true;
      // }
      // this.$store.commit("SET_PRODUCTION", this.GET_PRODUCTION);
    },
    showHeader(val) {
      if (val == 1) {
        if (this.GET_PRODUCTION.sheetHead.studentId) {
          this.studentIdNum =
            this.GET_PRODUCTION.sheetHead.studentId.studentIdNum;
        }
      }
      if (val == 2) {
        this.setRed();
        return;
      }
      this.addQuestion.questionIndex = "";
      this.addQuestion.questionGroupNum =
        this.GET_EXAM_INFORMATION.objectiveCount;
      // console.log();
      if (val == 5 || val == 6) {
        this.addQuestion.score = 10;
        this.addQuestion.questionNum = 1;
      }
      if (val == 3 || val == 4) {
        this.addQuestion.score = 3;
        this.addQuestion.questionNum = 5;
      }

      if (this.showHeaderNum == val) {
        this.showHeaderNum = 0;
      } else {
        this.showHeaderNum = val;
        this.addQuestion.questionVal = getQuestionMax();
        this.addQuestion.questionStartOrder = getQuestionMax();
        let num = 1;
        if (
          this.GET_PRODUCTION.questionItem &&
          this.GET_PRODUCTION.questionItem.length > 0
        ) {
          num = this.GET_PRODUCTION.questionItem.length + 1;
        }
        this.addQuestion.name = capitalNumberFn(num) + ".";
      }
      if (val == 7) {
        let { columnCount, paperType } = this.GET_PRODUCTION;
        if (paperType == 1) {
          this.rowNum = 41;
          this.sizeRow = 25;
        }
        if (paperType == 2) {
          if (columnCount == 2) {
            this.rowNum = 39;
            this.sizeRow = 26;
          }
          if (columnCount == 3) {
            this.rowNum = 63;
            this.sizeRow = 16;
          }
        }
        // console.log(paperType);
        // console.log(columnCount);
        this.addQuestion.name = "作文";
      }
      if (val == 8) {
        if (
          this.GET_PRODUCTION.questionItem &&
          this.GET_PRODUCTION.questionItem.length > 0
        ) {
          this.showHeaderNum = val;
        } else {
          this.addNotQ();
        }
        //
      }
      if (val == 10) {
        this.subSheetType.objectiveType =
          this.GET_EXAM_INFORMATION.objectiveType;
        if (
          this.GET_EXAM_INFORMATION.paperType == 1 &&
          this.GET_EXAM_INFORMATION.columnCount == 1
        ) {
          this.subSheetType.sheetType = 1;
        }
        if (
          this.GET_EXAM_INFORMATION.paperType == 2 &&
          this.GET_EXAM_INFORMATION.columnCount == 2
        ) {
          this.subSheetType.sheetType = 2;
        }
        if (
          this.GET_EXAM_INFORMATION.paperType == 2 &&
          this.GET_EXAM_INFORMATION.columnCount == 3
        ) {
          this.subSheetType.sheetType = 3;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.sheetHeader {
  // height: 68px;
  background: #10131b;
  padding: 13px 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100vw;
  line-height: 14px;
  top: 0;
  z-index: 777;
  ::v-deep {
    .el-select__caret {
      line-height: 24px !important;
      height: 24px !important;
    }
  }
  > img {
    cursor: pointer;
  }
  .sheet-btn-list {
    display: flex;
    align-items: center;
    .btn-list {
      position: relative;
      margin-right: 40px;
      > .el-button {
        color: #ffffff;
        // cursor: pointer;
      }
      .type-item-box {
        padding: 12px !important;
        width: 520px !important;
      }
      .type-item {
        display: flex;
        margin-bottom: 14px;
        &:first-child {
          margin-top: 14px;
        }
      }
      .score-box.btn-list-detail {
        padding: 0;
        .question-box-name {
          padding: 4px;
          span {
            color: #000;
            font-weight: bold;
          }
        }
        .question-box-list {
          margin-bottom: 4px;
          span {
            color: #000;
            font-weight: bold;
          }
        }
      }
      .btn-list-detail {
        top: 38px;
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        -moz-transform: translate(-50%); /* Firefox */
        -webkit-transform: translate(-50%); /* Safari 和 Chrome */
        -o-transform: translate(-50%); /* Opera */
        width: 140px;
        padding: 24px;
        background-color: #ffffff;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
        border: 1px solid #e9e9e9;
        opacity: 0;
        visibility: hidden;
        .question-box {
          .question-box-list {
            background: #f9f9f9;
            padding: 4px;
          }
        }
        .row-imput {
          .el-input {
            width: 50px;
          }
        }
        .student-id-num {
          margin-bottom: 24px;
        }
        .el-checkbox {
          margin-bottom: 24px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .objective-detail {
        width: 288px;
        .question-group {
          ::v-deep .el-form-item__content {
            margin-left: 0 !important;
            span {
              color: #f56c6c;
              margin-right: 4px;
            }
            .el-input {
              width: 66px;
              margin-right: 8px;
            }
            .el-select-cnt {
              .el-input {
                width: 100px;
                .el-input__suffix {
                  margin-right: 0;
                }
              }
            }
          }
        }
        .question-list-box {
          display: flex;
          align-items: center;
          .question-item {
            margin-bottom: 12px;
            > span {
              padding: 0px 10px;
              border: 1px solid #10131b;
              font-size: 12px;
              height: 11px;
              display: inline-block;
              margin-left: 16px;
            }
            .el-input {
              width: 65px;
              margin-left: 25px;
              ::v-deep .el-input__inner {
                line-height: 24px;
                height: 24px;
              }
            }
          }
        }
        .form-list {
          line-height: 40px;
        }
        .el-form-item {
          margin-bottom: 20px;
          ::v-deep .el-form-item__label {
            margin-bottom: 0;
            line-height: 24px;
          }
          ::v-deep .el-input__inner {
            height: 24px;
            line-height: 24px;
          }
          ::v-deep .el-form-item__content {
            line-height: 24px;
          }
          ::v-deep .el-form-item__error {
            padding-top: 0;
          }
          ::v-deep .el-radio {
            margin-right: 5px;
            font-size: 12px;
            margin-bottom: 0;
            &:last-child {
              margin-right: 0;
            }
            .el-radio__label {
              padding-left: 5px;
            }
          }
        }
      }
      .composition {
        width: 440px;
        .num {
          color: $primary-color;
        }
      }
    }
    .header-on {
      > .el-button {
        color: $primary-color;
      }
      .btn-list-detail {
        opacity: 1;
        visibility: visible;
        // .el-button {

        // }
      }
    }
    .save-btn {
      padding: 6px 12px;
      font-size: 12px;
    }
    span {
      color: #ffffff;
    }
  }
}
</style>
