<template>
  <div class="questionsItem">
    <!-- 试题列表的盒子 -->
    <div
      v-if="paperContent.height"
      class="question-list-box"
      :residueHeight="paperContent.residueHeight"
      :defaultHeight="paperContent.defaultHeight"
      :style="{
        height: paperContent.height + 'mm',
      }"
      :class="{
        'red-border': productionData.isRed,
      }"
    >
      <!-- <div
        style="
          position: absolute;
          width: 1px;
          height: 1px;
          background-color: aquamarine;
        "
      ></div> -->
      <slot></slot>
      <template v-if="paperContent.questionItem">
        <!-- 循环试题列表 -->
        <template v-for="(v, i) in paperContent.questionItem">
          <!-- 客观题  -->
          <div v-if="v.type == 1" :key="i" class="objective-item-box">
            <div
              v-if="!v.questionType"
              id="edit-question-box"
              class="edit-question-box"
            >
              <el-popover
                v-model="v.editTitleType"
                placement="right"
                width="400"
                trigger="manual"
                @hide="enterQuestion"
              >
                <div v-if="indexQuestion.id">
                  <div class="edit-question-item edit-question-input">
                    题目：
                    <el-input
                      v-model="indexQuestion.name"
                      size="mini"
                      placeholder="请输入内容"
                      maxlength="40"
                      show-word-limit
                    >
                    </el-input>
                  </div>
                  <div class="edit-question-item edit-question-input">
                    描述：
                    <el-input
                      v-model="indexQuestion.intro"
                      size="mini"
                      placeholder="请输入内容"
                      maxlength="40"
                      show-word-limit
                    >
                    </el-input>
                  </div>
                  <div class="edit-question-item edit-question-input">
                    编号：
                    <el-input
                      v-model="indexQuestion.only"
                      size="mini"
                      placeholder="试题题号重复请添加此项"
                      maxlength="5"
                      show-word-limit
                    >
                    </el-input>
                  </div>
                  <div class="edit-question-item">
                    显示：
                    <el-checkbox-group v-model="indexQuestion.questionTitleArr">
                      <el-checkbox :label="1">题目</el-checkbox>
                      <el-checkbox :label="2">分值</el-checkbox>
                      <el-checkbox :label="3">边框</el-checkbox>
                    </el-checkbox-group>
                  </div>
                  <div class="add-btn-list">
                    <el-button type="primary" size="mini" @click="downTitile()">
                      确定
                    </el-button>
                  </div>
                </div>
                <el-button
                  slot="reference"
                  type="primary"
                  icon="el-icon-edit"
                  @click="editItem(v, 'editTitleType')"
                >
                </el-button>
              </el-popover>
              <!--  -->
              <el-popover
                v-model="v.editListType"
                placement="right"
                trigger="manual"
                @hide="enterQuestion"
              >
                <el-table
                  v-if="indexQuestion.id"
                  class="edit-question-list"
                  :data="indexQuestion.questionGroup"
                  max-height="250"
                  border
                  style="width: 100%"
                >
                  <el-table-column
                    v-if="v.mergeQid"
                    prop="date"
                    label="题目"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <el-input
                        v-model="scope.row.groupName"
                        size="mini"
                        placeholder="请输入内容"
                      >
                      </el-input>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="name"
                    label="起始题号"
                    align="center"
                    width="100px"
                  >
                    <template slot-scope="scope">
                      <el-input
                        v-model="scope.row.questionStartOrder"
                        size="mini"
                        placeholder="请输入内容"
                        oninput="value=value.replace(/[^0-9]/g,'')"
                        @blur="
                          scope.row.questionStartOrder = $event.target.value
                        "
                      >
                      </el-input>
                    </template>
                  </el-table-column>
                  <el-table-column prop="address" label="个数" align="center">
                    <template slot-scope="scope">
                      <el-input
                        v-model="scope.row.questionNum"
                        size="mini"
                        placeholder="请输入内容"
                        oninput="value=value.replace(/[^0-9]/g,'')"
                        @blur="scope.row.questionNum = $event.target.value"
                      >
                      </el-input>
                    </template>
                  </el-table-column>
                  <el-table-column prop="address" label="分值" align="center">
                    <template slot-scope="scope">
                      <el-input
                        v-model="scope.row.score"
                        size="mini"
                        placeholder="请输入内容"
                        oninput="value=value.replace(/[^0-9.]/g,'')"
                        @blur="scope.row.score = $event.target.value"
                      >
                      </el-input>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="address"
                    label="题目类型"
                    align="center"
                    width="100px"
                  >
                    <template slot-scope="scope">
                      <el-select
                        v-model.number="scope.row.optionNum"
                        placeholder="选项类型"
                        size="mini"
                      >
                        <el-option
                          v-for="(item, index) in optionArray"
                          :key="index"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="address"
                    label="题目类型"
                    align="center"
                    width="100px"
                  >
                    <template slot-scope="scope">
                      <el-radio v-model="scope.row.subType" :label="1">
                        单选题
                      </el-radio>
                      <el-radio v-model="scope.row.subType" :label="2">
                        多选题
                      </el-radio>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="address"
                    label="少选得分"
                    align="center"
                    width="100px"
                  >
                    <template slot-scope="scope">
                      <el-input
                        v-model="scope.row.scoreMiss"
                        size="mini"
                        placeholder="请输入内容"
                        :disabled="scope.row.subType == 1"
                        oninput="value=value.replace(/[^0-9.]/g,'')"
                        @blur="scope.row.scoreMiss = $event.target.value"
                        @change="setChangeNum(scope.row)"
                      >
                      </el-input>
                    </template>
                  </el-table-column>
                  <el-table-column prop="address" label="操作" align="center">
                    <template slot-scope="scope">
                      <el-button
                        type="primary"
                        size="mini"
                        @click="delItemGroup(scope.row)"
                      >
                        删除
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="add-btn-list">
                  <el-button size="mini" type="primary" @click="addQuestion(v)"
                    >追加</el-button
                  >
                  <el-button type="primary" size="mini" @click="downList()"
                    >确定</el-button
                  >
                </div>
                <el-button
                  slot="reference"
                  type="primary"
                  icon="el-icon-setting"
                  @click="editItem(v, 'editListType')"
                ></el-button>
              </el-popover>
            </div>
            <div
              v-if="!v.questionType"
              class="question-title"
              :name="v.questionType ? '' : v.id"
            >
              <template
                v-if="v.questionTitleArr && v.questionTitleArr.indexOf(1) != -1"
              >
                {{ v.name }}
              </template>
              <template
                v-if="v.questionTitleArr && v.questionTitleArr.indexOf(2) != -1"
              >
                {{ v | getItemScore() }}
              </template>
              {{ v.intro }}
            </div>
            <!--         'padding-bottom': v.padding + 'mm', -->
            <!--         height: getObjHeiht(v), -->
            <div
              class="objective-question-content"
              style="position: relative"
              :padding="v.padding"
              :height="v.height"
              :style="{
                height: v.height + (v.padding != 2 ? v.padding : 0) + 'mm',
              }"
              :class="{
                'red-border': productionData.isRed,
                'del-border': v.questionOrderItem.length == 0,
                'objective-question-content-w':
                  v.questionTitleArr && v.questionTitleArr.indexOf(3) == -1,
              }"
            >
              <div
                v-for="(item, index) in v.questionOrderItem"
                :key="index"
                class="objective-content"
                :class="{
                  'objective-content-two': v.objectiveType == 3,
                }"
                :top="item.top"
                :v-top="v.top"
                :left="item.left"
                :style="{
                  position: item.width ? 'absolute' : '',
                  width: item.width + 'mm',
                  height: item.height + 'mm',
                  top: getObjTop(v, item),
                  left: item.left + 'mm',
                }"
              >
                <!--  -->
                <span
                  v-if="item.left == 0"
                  class="right-line-box"
                  :style="getRightLine(item, v)"
                ></span>
                <span
                  v-if="v.mergeQid && item.questionArray[0].groupName"
                  class="question-group-name"
                >
                  [{{ item.questionArray[0].groupName }}]
                </span>
                <template v-if="v.objectiveType == 2">
                  <table>
                    <tr
                      v-for="(m, n) in item.questionArray"
                      :key="n"
                      class="option-item-box"
                    >
                      <td>
                        <span
                          class="objective-num"
                          :class="{ 'objective-num-A': m.additionalQid }"
                          style="width: 8mm"
                        >
                          {{ m.questionNum }}
                        </span>
                      </td>
                      <template v-if="m.optionNum > 1">
                        <td
                          v-for="(j, k) in m.optionNum"
                          :key="k"
                          style="width: 6.4mm"
                        >
                          <span
                            class="option-item"
                            :class="{
                              'red-color': productionData.isRed,
                              'red-border': productionData.isRed,
                            }"
                          >
                            {{ optionListA[k] }}
                          </span>
                        </td>
                      </template>
                      <template v-else>
                        <td>
                          <span
                            class="option-item"
                            :class="{
                              'red-color': productionData.isRed,
                              'red-border': productionData.isRed,
                            }"
                          >
                            {{ optionListT[0] }}
                          </span>
                        </td>
                        <td>
                          <span
                            class="option-item"
                            :class="{
                              'red-color': productionData.isRed,
                              'red-border': productionData.isRed,
                            }"
                          >
                            {{ optionListT[1] }}
                          </span>
                        </td>
                      </template>
                    </tr>
                  </table>
                </template>

                <template v-if="v.objectiveType == 3">
                  <!-- {{ item }} -->
                  <table>
                    <tr class="option-item-box-two">
                      <td
                        v-for="(m, n) in item.questionArray"
                        :key="n"
                        style="height: auto"
                        :style="{
                          width: m.additionalQid ? '6.4mm' : '6.4mm',
                        }"
                      >
                        <span
                          class="objective-num"
                          :class="{ 'objective-num-A': m.additionalQid }"
                        >
                          {{ m.questionNum }}
                          <!-- 1.2255asd;a -->
                        </span>
                      </td>
                    </tr>
                    <template v-if="item.questionArray[0].optionNum > 1">
                      <tr
                        v-for="(y, z) in item.questionArray[0].optionNum"
                        :key="z"
                        class="option-item-box-two"
                      >
                        <td v-for="(j, k) in item.questionArray" :key="k">
                          <span class="option-item"> {{ optionListA[z] }}</span>
                        </td>
                      </tr>
                    </template>
                    <template v-else>
                      <tr class="option-item-box-two">
                        <td v-for="(j, k) in item.questionArray" :key="k">
                          <span class="option-item"> {{ optionListT[0] }}</span>
                        </td>
                      </tr>
                      <tr class="option-item-box-two">
                        <td v-for="(j, k) in item.questionArray" :key="k">
                          <span class="option-item"> {{ optionListT[1] }}</span>
                        </td>
                      </tr>
                    </template>
                  </table>
                </template>
              </div>
            </div>
          </div>
          <!-- 判断题 -->
          <div
            v-if="v.type == 2"
            :key="i"
            class="objective-item-box completion-item-box"
            :class="{
              'objective-question-content-w':
                v.questionTitleArr && v.questionTitleArr.indexOf(3) == -1,
            }"
          >
            <div
              v-if="!v.questionType"
              id="edit-question-box"
              class="edit-question-box"
            >
              <el-popover
                v-model="v.editTitleType"
                placement="right"
                width="400"
                trigger="manual"
                @hide="enterQuestion"
              >
                <div v-if="indexQuestion.id">
                  <div class="edit-question-item edit-question-input">
                    题目：
                    <el-input
                      v-model="indexQuestion.name"
                      size="mini"
                      placeholder="请输入内容"
                      maxlength="40"
                      show-word-limit
                    >
                    </el-input>
                  </div>
                  <div class="edit-question-item edit-question-input">
                    描述：
                    <el-input
                      v-model="indexQuestion.intro"
                      size="mini"
                      placeholder="请输入内容"
                      maxlength="40"
                      show-word-limit
                    >
                    </el-input>
                  </div>
                  <div class="edit-question-item edit-question-input">
                    编号：
                    <el-input
                      v-model="indexQuestion.only"
                      size="mini"
                      placeholder="试题题号重复请添加此项"
                      maxlength="5"
                      show-word-limit
                    >
                    </el-input>
                  </div>
                  <div class="edit-question-item">
                    显示：
                    <el-checkbox-group v-model="indexQuestion.questionTitleArr">
                      <el-checkbox :label="1">题目</el-checkbox>
                      <el-checkbox :label="2">分值</el-checkbox>
                      <el-checkbox :label="3">边框</el-checkbox>
                    </el-checkbox-group>
                  </div>
                  <div class="add-btn-list">
                    <el-button type="primary" size="mini" @click="downTitile()">
                      确定
                    </el-button>
                  </div>
                </div>
                <el-button
                  slot="reference"
                  type="primary"
                  icon="el-icon-edit"
                  @click="editItem(v, 'editTitleType')"
                >
                </el-button>
              </el-popover>
              <!--  -->
              <el-popover
                v-model="v.editListType"
                placement="right"
                trigger="manual"
                @hide="enterQuestion"
              >
                <el-table
                  v-if="indexQuestion.id"
                  class="edit-question-list"
                  :data="indexQuestion.questionGroup"
                  max-height="250"
                  border
                  style="width: 100%"
                >
                  <el-table-column prop="date" label="题目" align="center">
                    <template slot-scope="scope">
                      <el-input
                        v-model="scope.row.groupName"
                        size="mini"
                        placeholder="请输入内容"
                      >
                      </el-input>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="name"
                    label="起始题号"
                    align="center"
                    width="100px"
                  >
                    <template slot-scope="scope">
                      <el-input
                        v-model="scope.row.questionStartOrder"
                        size="mini"
                        placeholder="请输入内容"
                        oninput="value=value.replace(/[^0-9]/g,'')"
                        @blur="
                          scope.row.questionStartOrder = $event.target.value
                        "
                      >
                      </el-input>
                    </template>
                  </el-table-column>
                  <el-table-column prop="address" label="个数" align="center">
                    <template slot-scope="scope">
                      <el-input
                        v-model="scope.row.questionNum"
                        size="mini"
                        placeholder="请输入内容"
                        oninput="value=value.replace(/[^0-9]/g,'')"
                        @blur="scope.row.questionNum = $event.target.value"
                      >
                      </el-input>
                    </template>
                  </el-table-column>
                  <el-table-column prop="address" label="分值" align="center">
                    <template slot-scope="scope">
                      <el-input
                        v-model="scope.row.score"
                        size="mini"
                        placeholder="请输入内容"
                        oninput="value=value.replace(/[^0-9.]/g,'')"
                        @blur="scope.row.score = $event.target.value"
                      >
                      </el-input>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="address"
                    label="每行题数"
                    align="center"
                    width="100px"
                  >
                    <template slot-scope="scope">
                      <el-select
                        v-model.number="scope.row.eachRowNumber"
                        placeholder="每行题数"
                        size="mini"
                      >
                        <el-option
                          v-for="(opv, opi) in 3"
                          :key="opi"
                          :label="opv"
                          :value="opv"
                        >
                        </el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column
                    prop="address"
                    label="每题空数"
                    align="center"
                    width="100px"
                  >

                    <template slot-scope="scope">
                      <el-select
                        v-model.number="scope.row.itemNumber"
                        placeholder="每题空数"
                        size="mini"
                      >
                        <el-option
                          v-for="(opv, opi) in 4"
                          :key="opi"
                          :label="opv"
                          :value="opv"
                        >
                        </el-option>
                      </el-select>
                    </template>
                  </el-table-column> -->
                  <el-table-column
                    prop="address"
                    label="小题号"
                    align="center"
                    width="80px"
                  >
                    <!-- itemNumber -->
                    <template slot-scope="{ row }">
                      <!-- {{ scope.row }} -->
                      <el-checkbox
                        v-model="row.additionalQid"
                        style="margin-bottom: 0"
                      ></el-checkbox>
                    </template>
                  </el-table-column>

                  <el-table-column prop="address" label="操作" align="center">
                    <template slot-scope="scope">
                      <el-button
                        type="primary"
                        size="mini"
                        @click="delItemGroup(scope.row)"
                      >
                        删除
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="add-btn-list">
                  <el-button size="mini" type="primary" @click="addQuestion(v)"
                    >追加</el-button
                  >
                  <el-button type="primary" size="mini" @click="downList()"
                    >确定</el-button
                  >
                </div>
                <el-button
                  slot="reference"
                  type="primary"
                  icon="el-icon-setting"
                  @click="editItem(v, 'editListType')"
                ></el-button>
              </el-popover>
            </div>
            <div
              v-if="!v.questionType"
              class="question-title"
              :name="v.questionType ? '' : v.id"
            >
              <template
                v-if="v.questionTitleArr && v.questionTitleArr.indexOf(1) != -1"
              >
                {{ v.name }}
              </template>
              <template
                v-if="v.questionTitleArr && v.questionTitleArr.indexOf(2) != -1"
              >
                {{ v | getItemScore() }}
              </template>
              {{ v.intro }}
            </div>
            <div
              class="completion-question-content"
              :top="v.top"
              :padding="v.padding"
              :style="{
                height: v.height + (v.padding != 2 ? v.padding : 0) + 'mm',
              }"
            >
              <div
                v-for="(item, index) in v.questionOrderItem"
                :key="index"
                class="completion-content"
                :top="item.top"
                :left="item.left"
                style="position: absolute"
                :style="{
                  width: item.width + 'mm',
                  height: item.height + 'mm',
                  left: item.left + 'mm',
                  top: getEstimateTop(v, item),
                }"
              >
                <!-- {{ item.left }} -->
                <span
                  v-if="item.left == 0"
                  class="right-line-box"
                  :style="getRightLine(item, v)"
                ></span>
                <div v-for="(m, n) in item.questionArray" :key="n">
                  <div
                    class="completion-num"
                    :class="{ 'completion-num-A': m.additionalQid }"
                  >
                    {{ m.questionNum }}
                  </div>
                  <!-- {{ m }} -->
                  <div
                    class="completion-border"
                    :style="{
                      width: item.width - m.additionalQid ? 28 : 26 + 'mm',
                    }"
                  >
                    <template v-for="bItem in m.itemNumber">
                      <div
                        :key="bItem"
                        class="blank-line"
                        :style="{
                          width:
                            (item.width -
                              (m.additionalQid ? 28 : 26) -
                              (m.itemNumber - 1) * 3) /
                              m.itemNumber +
                            'mm',
                        }"
                      ></div>
                      <div
                        v-if="bItem > 0 && m.itemNumber > bItem"
                        :key="bItem + '111111'"
                        class="flex-line"
                      ></div>
                    </template>
                  </div>
                  <div class="blank-mark blankMark">
                    <div class="blank-wrong">×</div>
                    <div class="blank-right">√</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 解答题  选做题-->

          <div
            v-if="v.type == 3 || v.type == 4"
            :key="i"
            class="objective-item-box subjective-item-box"
            :class="{
              'objective-question-content-w':
                v.questionTitleArr && v.questionTitleArr.indexOf(3) == -1,
            }"
          >
            <!-- {{ v.questionType }} -->
            <div
              v-if="!v.questionType"
              id="edit-question-box"
              class="edit-question-box"
            >
              <el-popover
                v-model="v.editTitleType"
                placement="right"
                width="400"
                trigger="manual"
                @hide="enterQuestion"
              >
                <div v-if="indexQuestion.id == v.id">
                  <div class="edit-question-item edit-question-input">
                    题目：
                    <el-input
                      v-model="indexQuestion.name"
                      size="mini"
                      placeholder="请输入内容"
                      maxlength="40"
                      show-word-limit
                    >
                    </el-input>
                  </div>
                  <div class="edit-question-item edit-question-input">
                    描述：
                    <el-input
                      v-model="indexQuestion.intro"
                      size="mini"
                      placeholder="请输入内容"
                      maxlength="40"
                      show-word-limit
                    >
                    </el-input>
                  </div>
                  <div class="edit-question-item edit-question-input">
                    编号：
                    <el-input
                      v-model="indexQuestion.only"
                      size="mini"
                      placeholder="试题题号重复请添加此项"
                      maxlength="5"
                      show-word-limit
                    >
                    </el-input>
                  </div>
                  <div class="edit-question-item">
                    显示：
                    <el-checkbox-group v-model="indexQuestion.questionTitleArr">
                      <el-checkbox :label="1">题目</el-checkbox>
                      <el-checkbox :label="2">分值</el-checkbox>
                      <el-checkbox v-if="!productionData.markType" :label="3"
                        >边框</el-checkbox
                      >
                    </el-checkbox-group>
                  </div>
                  <div v-if="v.type == 4" class="edit-question-item">
                    涂抹项名称：
                    <template>
                      <el-input
                        v-for="(mitem, mkey) in indexQuestion.namelist"
                        :key="mkey"
                        v-model="mitem.questionNum"
                        size="mini"
                        placeholder="请输入内容"
                        style="width: 80px; padding: 0 3px; margin-left: 5px"
                      >
                      </el-input>
                    </template>
                  </div>
                  <div class="add-btn-list">
                    <el-button type="primary" size="mini" @click="downTitile()">
                      确定
                    </el-button>
                  </div>
                </div>
                <el-button
                  slot="reference"
                  type="primary"
                  icon="el-icon-edit"
                  @click="editItem(v, 'editTitleType')"
                >
                </el-button>
              </el-popover>
            </div>
            <div
              v-if="!v.questionType"
              class="question-title"
              :name="v.questionType ? '' : v.id"
            >
              <template
                v-if="v.questionTitleArr && v.questionTitleArr.indexOf(1) != -1"
              >
                {{ v.name }}
              </template>
              <template
                v-if="v.questionTitleArr && v.questionTitleArr.indexOf(2) != -1"
              >
                {{ v | getSubScore() }}
              </template>
              {{ v.intro }}
            </div>
            <div
              v-if="v.type == 4 && !v.questionType"
              class="question-mun"
              :top="v.top"
            >
              <div
                v-for="(vItem, vIndex) in v.namelist"
                :key="vIndex"
                :style="{ width: vItem.width + 'mm' }"
              >
                <span>{{ vItem.questionNum }}</span>
                <span class="question-border"></span>
              </div>
            </div>
            <!--     :class="{
              'red-color': productionData.isRed,
              'red-border': productionData.isRed,
            }" -->
            <!-- 循序当前大题的试题组 -->
            <!--   -->
            <div
              v-for="(item, itemkey) in v.questionOrderItem"
              :key="itemkey"
              :ref="'questionList' + item.id"
              :style="{
                width: item.width + 'mm',
                height: item.height + 'mm',
              }"
              :top="item.top"
              :name="item.questionType ? '' : item.id"
              class="question-border"
              :class="{
                'question-extend': item.questionType,
                'red-border': productionData.isRed,
              }"
            >
              <div
                class="question-box-item"
                :style="{ width: item.width + 'mm' }"
              >
                <div
                  v-if="!item.questionType"
                  id="edit-question-box"
                  class="edit-question-box"
                >
                  <el-popover
                    v-model="item.editListType"
                    placement="right"
                    trigger="manual"
                    @hide="enterQuestion"
                  >
                    <div class="question-box-item">
                      <el-form
                        v-if="indexQuestion.id == item.id"
                        ref="addQuestion"
                        size="mini"
                        label-width="100px"
                      >
                        <el-form-item label="小题题目：" prop="name">
                          <el-input
                            v-model="indexQuestion.questionNum"
                          ></el-input>
                        </el-form-item>
                        <el-form-item
                          label="小题分值："
                          prop="questionStartOrder"
                        >
                          <el-input
                            v-model="indexQuestion.score"
                            oninput="value=value.replace(/[^0-9.]/g,'')"
                            @blur="indexQuestion.score = $event.target.value"
                          ></el-input>
                        </el-form-item>
                        <el-form-item label="显 示：" prop="questionStartOrder">
                          <el-checkbox-group
                            v-model="indexQuestion.showQuestionTitleArr"
                          >
                            <el-checkbox :label="1">题目</el-checkbox>
                            <el-checkbox :label="2">分值</el-checkbox>
                            <el-checkbox
                              v-if="productionData.markType"
                              :label="3"
                              >0.5分</el-checkbox
                            >
                          </el-checkbox-group>
                        </el-form-item>
                        <template v-if="indexQuestion.type == 4">
                          <div style="text-align: center">
                            各涂抹项在该答题区代表的题目：
                          </div>
                          <!-- {{ indexQuestion }} -->
                          <div class="q-item">
                            <div
                              v-for="(qOitem, qOkey) in indexQuestion.nameList"
                              :key="qOkey"
                              class="q-item-box"
                            >
                              <div class="lab-title">
                                {{ qOitem.Qname }}
                                <span></span>
                              </div>
                              <el-input v-model="qOitem.questionNum"></el-input>
                            </div>
                          </div>
                        </template>
                        <div class="add-btn-list">
                          <el-button
                            size="mini"
                            type="primary"
                            @click="addSubjective(v)"
                            >追加</el-button
                          >
                          <el-button
                            type="success"
                            size="mini"
                            @click="editSubjective(v)"
                          >
                            确定
                          </el-button>
                          <el-button size="mini" @click="delSubjective(v)">
                            删除
                          </el-button>
                        </div>
                      </el-form>
                    </div>

                    <el-button
                      slot="reference"
                      type="primary"
                      icon="el-icon-setting"
                      @click="editItem(item, 'editListType', v)"
                    ></el-button>
                  </el-popover>
                </div>

                <span
                  v-if="!item.questionType && productionData.markType"
                  class="right-line-box"
                  :style="getRightLine(item, v)"
                ></span>
                <table
                  v-if="!item.questionType && productionData.markType"
                  class="score-box-table"
                >
                  <tbody>
                    <tr>
                      <td v-for="nItem in 20" :key="nItem" :style="getWidth()">
                        {{ nItem | setValue(item) }}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div
                  class="question-box"
                  :class="{
                    'edit-false': editType,
                    'header-border': !productionData.markType,
                    'red-border': productionData.isRed,
                  }"
                >
                  <div v-if="!item.questionType" class="question-title-score">
                    <template
                      v-if="
                        item.showQuestionTitleArr &&
                        item.showQuestionTitleArr.indexOf(1) != -1
                      "
                    >
                      {{ item.questionNum }}
                    </template>
                    <template
                      v-if="
                        item.showQuestionTitleArr &&
                        item.showQuestionTitleArr.indexOf(2) != -1
                      "
                    >
                      （{{ item.score }}分）
                    </template>
                  </div>
                  <template v-if="item.questionType">
                    <!-- 循环试题 -->
                    <template v-for="edItem in productionData.questionItem">
                      <template v-if="v.id == edItem.id">
                        <template v-for="quItem in edItem.questionOrderList">
                          <template v-if="quItem.id == item.id">
                            <ckeditor
                              :id="item.id + item.contentKey.toString()"
                              :ref="quItem.id"
                              :key="quItem.id"
                              v-model="
                                quItem.extendArr[item.contentKey].content
                              "
                              :id-name="item.questionNum"
                              :style="{
                                height: getEditHeader(item),
                              }"
                              class="CKEditor-style"
                              type="inline"
                              :config="getConfig(item.height, quItem.id)"
                              :editor-url="editorUrl"
                            >
                            </ckeditor>
                          </template>
                        </template>
                      </template>
                    </template>
                  </template>
                  <template v-else>
                    <!-- 循环试题 -->
                    <template v-for="edItem in productionData.questionItem">
                      <template v-if="v.id == edItem.id">
                        <template v-for="quItem in edItem.questionOrderList">
                          <template v-if="quItem.id == item.id">
                            <ckeditor
                              :id="quItem.id"
                              :ref="quItem.id"
                              :key="quItem.id"
                              v-model="quItem.content"
                              :id-name="item.questionNum"
                              :style="{
                                height: getEditHeader(item),
                              }"
                              class="CKEditor-style"
                              type="inline"
                              :config="getConfig(item.height, quItem.id)"
                              :editor-url="editorUrl"
                            ></ckeditor>
                          </template>
                        </template>
                      </template>
                    </template>
                  </template>
                </div>
                <!-- 判断下  如果存在追加情况  不能拖拽 -->
                <div
                  v-if="!item.isAdd"
                  class="ui-resizable-s"
                  @mousedown.stop="
                    arrowHeight($event, 'questionList' + item.id, item, v)
                  "
                ></div>
              </div>
            </div>
          </div>
          <!-- 语文作文 -->

          <div
            v-if="v.type == 5"
            :key="i"
            class="objective-item-box subjective-item-box"
            :startRow="v.startRow"
            :top="v.top"
            :class="{
              'red-color': productionData.isRed,
              'red-border': productionData.isRed,
              'objective-question-content-w':
                v.questionTitleArr && v.questionTitleArr.indexOf(3) == -1,
            }"
          >
            <div
              v-if="!v.questionType"
              id="edit-question-box"
              class="edit-question-box"
            >
              <el-popover
                v-model="v.editTitleType"
                placement="right"
                width="400"
                trigger="manual"
                @hide="enterQuestion"
              >
                <div v-if="indexQuestion.id">
                  <div class="edit-question-item edit-question-input">
                    题目：
                    <el-input
                      v-model="indexQuestion.name"
                      size="mini"
                      placeholder="请输入内容"
                      maxlength="40"
                      show-word-limit
                    >
                    </el-input>
                  </div>
                  <div class="edit-question-item edit-question-input">
                    描述：
                    <el-input
                      v-model="indexQuestion.intro"
                      size="mini"
                      placeholder="请输入内容"
                      maxlength="40"
                      show-word-limit
                    >
                    </el-input>
                  </div>
                  <div class="edit-question-item edit-question-input">
                    编号：
                    <el-input
                      v-model="indexQuestion.only"
                      size="mini"
                      placeholder="试题题号重复请添加此项"
                      maxlength="5"
                      show-word-limit
                    >
                    </el-input>
                  </div>

                  <div class="edit-question-item">
                    显示：
                    <el-checkbox-group v-model="indexQuestion.questionTitleArr">
                      <el-checkbox :label="1">题目</el-checkbox>
                      <el-checkbox :label="2">分值</el-checkbox>
                      <el-checkbox v-if="!productionData.markType" :label="3"
                        >边框</el-checkbox
                      >
                    </el-checkbox-group>
                  </div>
                  <div class="add-btn-list">
                    <el-button type="primary" size="mini" @click="downTitile()">
                      确定
                    </el-button>
                  </div>
                </div>
                <el-button
                  slot="reference"
                  type="primary"
                  icon="el-icon-edit"
                  @click="editItem(v, 'editTitleType')"
                >
                </el-button>
              </el-popover>
              <!--  -->
              <el-popover
                v-model="v.editListType"
                placement="right"
                trigger="manual"
                @hide="enterQuestion"
              >
                <div v-if="indexQuestion.id == v.id" class="question-box-item">
                  <el-form ref="addQuestion" size="mini" label-width="100px">
                    <el-form-item label="小题分值：" prop="questionStartOrder">
                      <el-input
                        v-model="indexQuestion.score"
                        oninput="value=value.replace(/[^0-9.]/g,'')"
                        @blur="indexQuestion.score = $event.target.value"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="行数：" prop="name">
                      <el-input
                        v-model="indexQuestion.rowNum"
                        oninput="value=value.replace(/[^0-9]/g,'')"
                        @blur="indexQuestion.rowNum = $event.target.value"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="每行字数：" prop="name">
                      <el-input
                        v-model="indexQuestion.sizeRow"
                        oninput="value=value.replace(/[^0-9]/g,'')"
                        @blur="indexQuestion.sizeRow = $event.target.value"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="字数间隔：" prop="name">
                      <el-input
                        v-model="indexQuestion.sizeInterval"
                        size="mini"
                        oninput="value=value.replace(/[^0-9]/g,'')"
                        @blur="indexQuestion.sizeInterval = $event.target.value"
                      >
                      </el-input>
                    </el-form-item>

                    <el-form-item
                      v-if="productionData.markType"
                      label="0.5分："
                      prop="name"
                    >
                      <el-checkbox-group
                        v-model="indexQuestion.showQuestionTitleArr"
                      >
                        <!-- <el-checkbox :label="1">题目</el-checkbox>
                      <el-checkbox :label="2">分值</el-checkbox> -->
                        <el-checkbox v-if="productionData.markType" :label="3"
                          >0.5分</el-checkbox
                        >
                      </el-checkbox-group>
                    </el-form-item>

                    <div class="add-btn-list">
                      <el-button
                        type="success"
                        size="mini"
                        @click="editComposition(v)"
                      >
                        确定
                      </el-button>
                      <el-button size="mini" @click="delComposition(v)">
                        删除
                      </el-button>
                    </div>
                  </el-form>
                </div>

                <el-button
                  slot="reference"
                  type="primary"
                  icon="el-icon-setting"
                  @click="editItem(v, 'editListType')"
                ></el-button>
              </el-popover>
            </div>
            <div
              v-if="!v.questionType"
              class="question-title"
              :name="v.questionType ? '' : v.id"
            >
              <template
                v-if="v.questionTitleArr && v.questionTitleArr.indexOf(1) != -1"
              >
                {{ v.name }}
              </template>
              <template
                v-if="v.questionTitleArr && v.questionTitleArr.indexOf(2) != -1"
              >
                （{{ v.score }}分）
              </template>
              {{ v.intro }}
            </div>
            <div class="question-border" :style="{ width: v.width + 'mm' }">
              <span
                v-if="!v.questionType && productionData.markType"
                class="right-line-box"
                :style="getRightLine(v, v)"
              ></span>
              <table
                v-if="!v.questionType && productionData.markType"
                class="score-box-table"
              >
                <tbody>
                  <tr>
                    <td v-for="nItem in 20" :key="nItem" :style="getWidth()">
                      {{ nItem | setValue(v) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              class="tab-box"
              :class="{
                'red-color': productionData.isRed,
                'red-border': productionData.isRed,
              }"
              :style="{
                width: v.width + 'mm',
                height:
                  v.height -
                  (!v.questionType && productionData.markType == 1 ? 5.5 : 0) +
                  'mm',
              }"
            >
              <div
                v-for="item in v.rowNumber"
                :key="item"
                class="tab-item"
                :style="{ height: v.tabHeight + 'mm' }"
              >
                <div
                  v-for="index in v.sizeRow"
                  :key="index"
                  :style="{
                    width: v.tabWidth + 'mm',
                    height: v.tabHeight + 'mm',
                  }"
                  :class="{
                    'red-color': productionData.isRed,
                    'red-border': productionData.isRed,
                  }"
                >
                  <div v-if="getShow(v, item, index)" class="show-num">
                    {{ getShowNum(v, item, index) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 非作答区域 -->
          <div
            v-if="v.type == 6"
            :key="i"
            :ref="'questionList' + v.id"
            class="objective-item-box not-question"
            :style="{
              height: v.height + 'mm',
              marginTop: v.questionType ? '0' : '2mm',
            }"
            :name="v.questionType ? '' : v.id"
            :top="v.top"
            :class="{
              'red-color': productionData.isRed,
              'red-border': productionData.isRed,
              'objective-question-content-w':
                v.questionTitleArr && v.questionTitleArr.indexOf(3) == -1,
            }"
          >
            <div class="no-question-tips">
              非作答区域 {{ v.order }}{{ v.questionType ? "续" : "" }}
            </div>
            <div
              v-if="!v.questionType"
              id="edit-question-box"
              class="edit-question-box"
            >
              <el-popover
                v-model="v.editTitleType"
                placement="right"
                width="400"
                trigger="manual"
                @hide="enterQuestion"
              >
                <div v-if="indexQuestion.id">
                  <div class="edit-question-item">
                    显示：
                    <el-checkbox-group v-model="indexQuestion.questionTitleArr">
                      <el-checkbox :label="3">边框</el-checkbox>
                    </el-checkbox-group>
                  </div>
                  <div class="edit-question-item" style="font-size: 12px">
                    右上角红色小字仅做显示使用,不会影响预览、下载答题卡!
                  </div>

                  <div class="add-btn-list">
                    <el-button type="primary" size="mini" @click="downTitile()">
                      确定
                    </el-button>
                    <el-button
                      slot="reference"
                      size="mini"
                      type="danger"
                      @click="delNotQuestion(v)"
                    >
                      删除
                    </el-button>
                  </div>
                </div>
                <el-button
                  slot="reference"
                  type="primary"
                  icon="el-icon-edit"
                  @click="editItem(v, 'editTitleType')"
                >
                </el-button>
              </el-popover>
            </div>
            <template v-if="v.questionType">
              <!-- 循环试题 -->
              <!-- {{ v.id == edItem.id }} -->
              <template v-for="edItem in productionData.questionItem">
                <template v-if="edItem.id == v.id">
                  <ckeditor
                    :id="edItem.id + '0'"
                    :ref="edItem.id"
                    :key="edItem.id"
                    v-model="edItem.extendArr[0].content"
                    :style="{
                      height: getEditHeader(v),
                      width: v.width - 5 + 'mm',
                    }"
                    class="CKEditor-style"
                    type="inline"
                    :config="getConfig(v.height, edItem.id)"
                    :editor-url="editorUrl"
                  ></ckeditor>
                </template>
              </template>
            </template>
            <template v-else>
              <!-- 循环试题 -->
              <template v-for="edItem in productionData.questionItem">
                <template v-if="v.id == edItem.id">
                  <ckeditor
                    :id="edItem.id"
                    :ref="edItem.id"
                    :key="edItem.id"
                    v-model="edItem.content"
                    :style="{
                      height: v.height - 4 + 'mm',
                      width: v.width - 5 + 'mm',
                    }"
                    class="CKEditor-style"
                    type="inline"
                    :config="getConfig(v.height, edItem.id)"
                    :editor-url="editorUrl"
                  ></ckeditor>
                </template>
              </template>
            </template>
            <!-- 判断下  如果存在追加情况  不能拖拽 -->
            <div
              v-if="!v.isAdd"
              class="ui-resizable-s"
              @mousedown.stop="
                notQuestionHeight($event, 'questionList' + v.id, v)
              "
            ></div>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
const optionListT = ["T", "F"];
import { choiceQuestionTypeList, optionArray } from "@/core/util/sheetPaper";
import { getQuestionMax, unitConversion } from "@/core/util/util";
// import {  } from "@/core/util/util";
import CKEditor from "ckeditor4-vue";
import { optionItem } from "@/core/util/constdata";
// import CKEditor1 from "@/components/Editor";
import { mapGetters } from "vuex";
export default {
  name: "QuestionsItem",
  components: { ckeditor: CKEditor.component },
  filters: {
    setValue(num, item) {
      // console.log(num);
      // console.log(item);
      let returnNum = "";
      // 存在0.5分的情况
      if (item.showQuestionTitleArr.indexOf(3) != -1) {
        if (item.score < 18) {
          returnNum = 19 - num - 1;
          if (returnNum > item.score) {
            returnNum = "";
          }
        } else {
          if (num < 8) {
            returnNum = 8 - num;
            let qNum = item.score.slice(0, 1);
            //
            if (returnNum > qNum || returnNum == 0) {
              // console.log("sadjaihdjaksdhjk");
              returnNum = "";
            }
          }
          if (num > 8) {
            returnNum = 18 - num;
          }
        }
        if (num == 19) {
          returnNum = "";
        }
        if (num == 20) {
          returnNum = 0.5;
        }
      } else {
        if (item.score < 20) {
          returnNum = 19 - num + 1;
          if (returnNum > item.score) {
            returnNum = "";
          }
        } else {
          if (num < 10) {
            returnNum = 10 - num;
            let qNum = item.score.slice(0, 1);
            if (returnNum > qNum || returnNum == 0) {
              // console.log("sadjaihdjaksdhjk");
              returnNum = "";
            }
          }
          if (num > 10) {
            returnNum = 20 - num;
          }
        }
      }
      return returnNum;
    },
    getItemScore(item) {
      let score = 0;
      item.questionOrderList.map((index) => {
        index.questionArray.map((i) => {
          score = Number(i.score) + score;
        });
      });
      return `（${score}分）`;
    },
    getSubScore(item) {
      let score = 0;
      item.questionOrderList.map((index) => {
        score = Number(index.score) + score;
      });
      return `（${score}分）`;
    },
  },
  props: {
    question: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      editorUrl: "./ckeditor/ckeditor.js" + new Date().getTime(),
      editorConfigWarning: {
        title: false,
        language: "zh-cn",
        allowedContent: true,
        removeButtons: "Undo",
        // extraPlugins: ["ckeditor_wiris"],
        extraPlugins:
          "font,myImage,longLine,shortline,shortMatts,grid,lineheight",
        font_names:
          "Arial/Arial,Helvetica,sans-serif;" +
          "Comic Sans MS/Comic Sans MS,cursive;" +
          "Courier New/Courier New,Courier,monospace;" +
          "Georgia/Georgia,serif;" +
          "Lucida Sans Unicode/Lucida Sans Unicode,Lucida Grande,sans-serif;" +
          "Tahoma/Tahoma,Geneva,sans-serif;" +
          "Times New Roman/Times New Roman,Times,serif;" +
          "Trebuchet MS/Trebuchet MS,Helvetica,sans-serif;" +
          "宋体;" +
          "Verdana/Verdana,Geneva,sans-serif;" +
          "幼圆",
        toolbar: [
          {
            name: "basicstyles",
            items: [
              "basicstyles",
              "Bold",
              "Underline",
              "Italic",
              "Strike",
              "TextColor",
              "BGColor",
              "fontFamily",
              "fontSize",
              "MyImage",
              "quicktable",
              "LongLine",
              "Shortline",
              "lineheight",
              "ShortMatts",
              "Grid",
              "RemoveFormat",
              // "Styles",
            ],
          },
          {
            name: "align",
            items: ["JustifyLeft", "JustifyCenter", "JustifyRight"],
          },
          { name: "styles", items: ["Font", "FontSize"] },
        ],
      },
      choiceQuestionTypeList: choiceQuestionTypeList,
      optionArray: optionArray(),
      optionListA: optionItem,
      optionListT: optionListT,
      paperContent: {},
      // 弹出编辑的当前试题
      indexQuestion: {},
      productionData: {},
      editType: false,
    };
  },
  computed: {
    ...mapGetters([
      "GET_EXAM_INFORMATION",
      "GET_PRODUCTION",
      "GET_INDEX_QUESTION",
    ]),
  },

  watch: {
    GET_INDEX_QUESTION: {
      deep: true,
      handler() {
        setTimeout(() => {
          this.paperContent.questionItem.map((item) => {
            if (!item.questionOrderItem) {
              return;
            }
            item.questionOrderItem.map((index) => {
              if (this.GET_INDEX_QUESTION.id == index.id) {
                this.editItem(index, "editListType");
                this.$store.commit("SET_SCROOL", index.id);
              }
            });
          });
        }, 100);
      },
    },
    question: {
      deep: true,
      handler() {
        // console.log(this.question);
        this.paperContent = JSON.parse(JSON.stringify(this.question));
        // console.log(this.paperContent);
      },
    },
    // 获取答题卡json数据组装基本数据
    GET_PRODUCTION: {
      deep: true,
      handler() {
        this.productionData = Object.assign({}, this.GET_PRODUCTION);
        // this.ckeditorKey = new Date().getTime();
        // console.log(this.productionData);
      },
    },
  },
  created() {
    this.paperContent = JSON.parse(JSON.stringify(this.question));
    this.loadList();
    // 鼠标点击页面事件
    window.addEventListener("mousedown", this.handleonmousedown);
  },
  mounted() {},
  updated() {
    // this.onNamespaceLoaded();
  },
  destroyed() {
    window.removeEventListener("mousedown", this.handleonmousedown);
  },
  methods: {
    getWidth() {
      // console.log(.);
      if (this.productionData) {
        let width = this.productionData.paperContent.width;
        // console.log(width);
        width = (width - 5) / 20;
        return `width:${width}px`;
      }
    },
    handleonmousedown($event) {
      let str = "";
      const urlPath =
        $event.path || ($event.composedPath && $event.composedPath());
      urlPath.map((index) => {
        str = str + index.className;
      });
      // console.log(str);
      if (str.indexOf("el-popper") == -1 && this.indexQuestion.id) {
        this.paperContent.questionItem.map((item) => {
          if (item.type == 3 || item.type == 4) {
            item.questionOrderItem.map((index) => {
              index.editListType = false;
              index.editTitleType = false;
            });
          }
          item.editListType = false;
          item.editTitleType = false;
        });
        this.indexQuestion = {};
      }
    },
    onNamespaceLoaded() {},
    onEditorReady(editor) {
      this.ready(editor);
    },
    getShow(item, row, size) {
      let { sizeInterval } = item;
      if (sizeInterval === "" || sizeInterval === undefined) {
        sizeInterval = 100;
      }
      let sizeNum = 0;
      let type = false;
      if (item.startRow > 0) {
        sizeNum = item.startRow * item.sizeRow;
      }
      let allNum = (row - 1) * item.sizeRow + size + sizeNum;
      if (allNum % sizeInterval == 0) {
        type = true;
      }
      return type;
    },
    getShowNum(item, row, size) {
      let sizeNum = 0;
      // let number = 0;
      if (item.startRow > 0) {
        sizeNum = item.startRow * item.sizeRow;
      }
      let allNum = (row - 1) * item.sizeRow + size + sizeNum;

      return allNum;
    },
    arrowHeight(e, key, qitem, v) {
      let copyItem = JSON.parse(JSON.stringify(qitem));
      let _this = this;
      let elH = this.$refs[key][0].offsetHeight;
      let y = e.clientY;
      let height;
      this.inline(1);
      document.onmousemove = function (e) {
        // 根据鼠标位置计算出移动的距离
        // let width = new unitConversion().pxConversionMm(elW - (x - e.clientX));
        height = new unitConversion().pxConversionMm(elH - (y - e.clientY));

        // 禁止越界 start
        if (height < 8) {
          height = 8;
        }
        if (height > 400) {
          height = 400;
        }
        // if (_this.productionData.markType) {
        //   if (height < 15) {
        //     height = 15;
        //   }
        // }
        _this.paperContent.questionItem.map((item) => {
          if (!item.questionOrderList) {
            return;
          }
          item.questionOrderItem.map((index) => {
            if (index.id == qitem.id) {
              if (qitem.questionType) {
                index.height = height;
              } else {
                index.height = height;
                index.allHeight = height;
              }
            }
          });
        });
      };
      document.onmouseup = function () {
        if (!height) {
          document.onmousemove = null;
          document.onmouseup = null;
          _this.inline();
          return;
        }
        // 有空来修改一下  可能会有bug 异步问题
        _this.question.questionItem.map((item) => {
          if (!item.questionOrderList) {
            return;
          }
          item.questionOrderItem.map((index) => {
            if (index.id == qitem.id) {
              if (qitem.questionType) {
                index.height = height;
              } else {
                index.height = height;
                index.allHeight = height;
              }
            }
          });
        });
        _this.productionData.questionItem.map((item) => {
          if (item.id == v.id) {
            if (!item.questionOrderList) {
              return;
            }
            item.questionOrderList.map((index) => {
              if (index.id == qitem.id) {
                if (qitem.questionType) {
                  if (height == 8) {
                    index.allHeight = index.allHeight - copyItem.height;
                    return;
                  }
                  index.allHeight = index.allHeight - copyItem.height + height;
                } else {
                  index.height = height;
                  index.allHeight = height;
                }
                // console.log(index.allHeight);
              }
            });
          }
        });
        // console.log(_this.productionData);
        _this.$store.commit("SEVA_PRODUCTION", _this.productionData);
        _this.$store.commit("SET_PRODUCTION", _this.productionData);
        document.onmousemove = null;
        document.onmouseup = null;
        _this.inline();
      };
    },
    notQuestionHeight(e, key, v) {
      let _this = this;
      let elH = _this.$refs[key][0].offsetHeight;
      let y = e.clientY;
      let height;
      this.inline(1);
      document.onmousemove = function (e) {
        // 根据鼠标位置计算出移动的距离

        height = new unitConversion().pxConversionMm(elH - (y - e.clientY));
        // 禁止越界 start
        // console.log(height);
        if (height <= 0) {
          height = 1;
        }
        if (height > 400) {
          height = 400;
        }
        _this.$refs[key][0].style.height = height + "mm";
      };
      document.onmouseup = function () {
        // console.log(height);
        if (!height) {
          document.onmousemove = null;
          document.onmouseup = null;
          _this.inline();
          return;
        }
        // console.log(height);
        _this.productionData.questionItem.map((item) => {
          if (item.id == v.id) {
            if (v.questionType) {
              item.allHeight = height ? height + item.height : item.allHeight;
              if (height <= 0) {
                item.allHeight = item.allHeight - item.height;
              }
            } else {
              item.height = height || item.height;
              item.allHeight = height || item.allHeight;
            }
          }
        });
        _this.$store.commit("SEVA_PRODUCTION", _this.productionData);
        _this.$store.commit("SET_PRODUCTION", _this.productionData);
        document.onmousemove = null;
        document.onmouseup = null;
        _this.inline();
      };
    },
    inline(type) {
      let practice_item = document.getElementsByClassName("cke_editable");
      let top_item = document.getElementsByClassName("top-msg-box");
      let score_item = document.getElementsByClassName("question-title-score");
      let question_title_item =
        document.getElementsByClassName("question-title");
      let practice_item_length = practice_item.length;
      let top_item_length = top_item.length;
      let question_title_item_length = question_title_item.length;
      let score_item_length = score_item.length;
      for (let i = 0; i < score_item_length; i++) {
        if (type) {
          score_item[i].style.pointerEvents = "none";
          score_item[i].style.userSelect = "none";
        } else {
          score_item[i].style.pointerEvents = "";
          score_item[i].style.userSelect = "";
        }
      }
      for (let i = 0; i < practice_item_length; i++) {
        if (type) {
          practice_item[i].style.pointerEvents = "none";
          practice_item[i].style.userSelect = "none";
        } else {
          practice_item[i].style.pointerEvents = "";
          practice_item[i].style.userSelect = "";
        }
      }
      for (let i = 0; i < top_item_length; i++) {
        if (type) {
          top_item[i].style.pointerEvents = "none";
          top_item[i].style.userSelect = "none";
        } else {
          top_item[i].style.pointerEvents = "";
          top_item[i].style.userSelect = "";
        }
      }
      for (let i = 0; i < question_title_item_length; i++) {
        if (type) {
          question_title_item[i].style.pointerEvents = "none";
          question_title_item[i].style.userSelect = "none";
        } else {
          question_title_item[i].style.pointerEvents = "";
          question_title_item[i].style.userSelect = "";
        }
      }
    },
    loadList() {
      this.paperContent.questionItem.map((item) => {
        if (item.type == 3 || item.type == 4) {
          item.questionOrderItem.map((index) => {
            index.editListType = false;
            index.editTitleType = false;
          });
        }
        item.editListType = false;
        item.editTitleType = false;
      });
      this.paperContent = Object.assign({}, this.paperContent);
      this.productionData = Object.assign({}, this.GET_PRODUCTION);
    },
    // 新增选做题
    // addChooseQuestion(item) {
    //   console.log(item);
    // },
    // 新增大题中的主观题
    addSubjective(item) {
      // 克隆一个当前试题

      let data = JSON.parse(JSON.stringify(this.indexQuestion));
      // console.log(item);
      let questionIndex;
      item.questionOrderList.map((itemK, index) => {
        if (itemK.id == data.id) {
          questionIndex = index;
        }
      });
      // console.log(data);
      data.height = 48;
      if (this.GET_PRODUCTION.markType == 1) {
        data.height = data.height + 5.5;
        data.allHeight = data.height;
      }
      data.id = new Date().getTime();
      data.editListType = false;
      data.questionNum = getQuestionMax();
      data.value = getQuestionMax();
      data.showQuestionTitleArr = [1, 2];
      data.content = "";
      data.extendArr = [];
      for (let i = 0; i < 5; i++) {
        data.extendArr.push({
          content: "",
        });
      }
      if (item.type == 4) {
        let indexNamelist = item.namelist.map((index) => index.questionNum);
        data.questionNum =
          indexNamelist.join("/") +
          "（" +
          (item.questionOrderList.length + 1) +
          "）";
        let questionNameList = JSON.parse(JSON.stringify(item.namelist));
        // if (data.questionNum > 1) {
        questionNameList.map((key) => {
          key.Qname = key.questionNum;
          key.questionNum =
            key.questionNum + "（" + (item.questionOrderList.length + 1) + "）";
        });
        // }
        data.nameList = questionNameList;
      }
      // 添加到试题列表中
      item.questionOrderList.splice(questionIndex + 1, 0, data);
      item.questionOrderItem.splice(questionIndex + 1, 0, data);
      // item.questionOrderItem.push(data);
      this.indexQuestion.editListType = false;
      this.indexQuestion = {};
      this.productionData.questionItem.map((index) => {
        if (item.id == index.id) {
          Object.assign(index, item);
          index.questionOrderList.map((i) => {
            i.editListType = false;
          });
        }
      });
      this.$store.commit("SEVA_PRODUCTION", this.productionData);
      this.$store.commit("SET_PRODUCTION", this.productionData);
      this.$nextTick(() => {
        this.$store.commit("SET_INDEX_QUESTION", data);
      });
    },
    getRightLine(item, v) {
      let height = item.height;
      let top;
      if (v.type == 1) {
        top = `top:${height / 2 - 1.2}mm`;
      }
      if (v.type == 2) {
        top = `top:${6 / 2 - 1.2}mm`;
      }
      if (v.type == 3 || v.type == 4 || v.type == 5) {
        top = `top:${5.5 / 2 - 1.2}mm`;
      }
      // if () {
      //   top = `top:${5.5 / 2 - 1.2}mm`;
      // }
      return `left:${this.productionData.paperContent.width - 2}mm;${top}`;
    },
    delNotQuestion(item) {
      this.productionData.questionItem =
        this.productionData.questionItem.filter((index) => index.id != item.id);
      this.$store.commit("SEVA_PRODUCTION", this.productionData);
      this.$store.commit("SET_PRODUCTION", this.productionData);
    },
    // 删除主观题
    delSubjective(item) {
      item.questionOrderList = item.questionOrderList.filter(
        (index) => index.id != this.indexQuestion.id
      );
      this.productionData.questionItem.map((index) => {
        if (item.id == index.id) {
          Object.assign(index, item);
        }
      });
      this.productionData.questionItem =
        this.productionData.questionItem.filter(
          (item) =>
            item.type == 5 ||
            item.type == 6 ||
            (item.type != 5 &&
              item.type != 6 &&
              item.questionGroup.length > 0 &&
              item.questionOrderList.length > 0)
        );
      // console.log(this.productionData.questionItem);
      this.$store.commit("SEVA_PRODUCTION", this.productionData);
      this.$store.commit("SET_PRODUCTION", this.productionData);
    },
    editSubjective() {
      let str = this.indexQuestion.questionNum.toString();
      if (str.indexOf("  ") != -1) {
        this.$message({
          message: `试题题号存在两个或两个以上连续空格，请修改后提交！`,
          type: "warning",
          showClose: true,
        });
        return;
      }

      this.productionData.questionItem.map((index) => {
        if (this.indexQuestion.type == 1 || this.indexQuestion.type == 2) {
          if (index.id == this.indexQuestion.id) {
            delete this.indexQuestion.rowNumber;

            Object.assign(index, this.indexQuestion);
            index.editListType = false;
            index.editTitleType = false;
          }
        }
        if (this.indexQuestion.type == 3 || this.indexQuestion.type == 4) {
          // console.log(index);
          if (index.type == 5 || index.type == 6) {
            return;
          }
          index.questionOrderList.map((item) => {
            if (item.id == this.indexQuestion.id) {
              delete this.indexQuestion.rowNumber;
              let { extendArr, content } = item;
              Object.assign(item, this.indexQuestion);
              item.extendArr = extendArr;
              item.content = content;
              item.editListType = false;
              item.editTitleType = false;
            }
          });
        }
      });

      // console.log(this.productionData.questionItem);
      this.indexQuestion.editListType = false;
      this.indexQuestion = {};
      this.$store.commit("SEVA_PRODUCTION", this.productionData);
      this.$store.commit("SET_PRODUCTION", this.productionData);
    },
    delComposition(item) {
      this.productionData.questionItem =
        this.productionData.questionItem.filter(
          (item) => item.id != this.indexQuestion.id
        );
      item.editListType = false;
      item.editTitleType = false;
      this.indexQuestion.editListType = false;
      this.indexQuestion.editTitleType = false;

      this.indexQuestion = {};
      this.$store.commit("SEVA_PRODUCTION", this.productionData);
      this.$store.commit("SET_PRODUCTION", this.productionData);
    },
    editComposition(item) {
      // console.log(this.indexQuestion);
      if (this.indexQuestion.sizeRow < 6) {
        this.$message({
          message: "每行至少6字！",
          type: "warning",
          showClose: true,
        });
        return;
      }
      this.productionData.questionItem.map((index) => {
        if (index.id == this.indexQuestion.id) {
          delete this.indexQuestion.rowNumber;

          Object.assign(index, this.indexQuestion);
          index.editListType = false;
          index.editTitleType = false;
        }
      });
      item.editListType = false;
      item.editTitleType = false;
      this.indexQuestion.editListType = false;
      this.indexQuestion.editTitleType = false;

      this.indexQuestion = {};
      this.$store.commit("SEVA_PRODUCTION", this.productionData);
      this.$store.commit("SET_PRODUCTION", this.productionData);
    },

    getConfig(height, id) {
      // console.log();
      height = height - 13.5;
      let editorConfigWarning = Object.assign({}, this.editorConfigWarning);
      editorConfigWarning.height = height + "mm";
      setTimeout(() => {
        // 重定义editor空格键
        this.bindHeadEditor(id);
      }, 500);
      // console.log(id);
      return editorConfigWarning;
    },
    // 重定义editor空格键
    bindHeadEditor(b) {
      this.$nextTick(() => {
        var g = this.$refs[b][0].instance;

        g.on("key", function (a) {
          if (32 == a.data.keyCode) return g.insertHtml("\x26#12288;"), !1;
        });
      });
    },
    getEditHeader(item) {
      let height = item.height;
      if (this.productionData.markType == 1) {
        if (item.questionType) {
          height = height - 4;
        } else {
          height = height - 13.5;
          if (item.showQuestionTitleArr) {
            let indexOf = item.showQuestionTitleArr.indexOf(1) == -1;
            let indexOf1 = item.showQuestionTitleArr.indexOf(2) == -1;
            if (indexOf && indexOf1) {
              height = height + 3;
            }
          }
        }
      } else {
        if (item.questionType) {
          height = height - 4;
        } else {
          height = height - 7.5;
          if (item.showQuestionTitleArr) {
            let indexOf = item.showQuestionTitleArr.indexOf(1) == -1;
            let indexOf1 = item.showQuestionTitleArr.indexOf(2) == -1;
            if (indexOf && indexOf1) {
              height = height + 3;
            }
          }
        }
      }
      return height + "mm";
    },
    // 获取试题框高度
    getHeight(item) {
      // console.log();
      let height = item.height;
      if (this.productionData.markType == 1) {
        if (!item.questionType) {
          height = height - 5.5;
        }
      }

      return height + "mm";
    },
    getObjHeiht(item) {
      let indexLast = item.questionOrderItem.map((i) => i.left).lastIndexOf(0);
      // console.log(indexLast); 获取最后一组最高的
      let height = 0;
      item.questionOrderItem.map((itemO, indexO) => {
        if (indexO >= indexLast) {
          if (itemO.height > height) {
            height = itemO.height;
          }
        }
      });
      if (!item.questionOrderItem || item.questionOrderItem.length == 0) {
        return;
      }
      let { top } = item.questionOrderItem[item.questionOrderItem.length - 1];
      height =
        top +
        height +
        (item.padding ? item.padding : 2) -
        (item.top ? item.top : 9.1);
      // if (item.questionType) {
      //   height = height - 1.5;
      // }
      return height + "mm";
    },
    getObjTop(v, item) {
      // console.log(item);
      let top = 0;
      top = item.top - v.top;
      // if (v.questionType) {
      //   top = top - 1.5;
      // }
      return top + "mm";
    },
    getEstimateTop(v, item) {
      let top = 0;
      top = item.top - v.top;
      if (!v.questionType) {
        top = top + 5.6;
      }
      // top = top;
      return top + "mm";
    },
    // 编辑试题属性
    editItem(v, key) {
      this.indexQuestion = JSON.parse(JSON.stringify(v));
      this.paperContent.questionItem.map((item) => {
        if (item.type == 3 || item.type == 4) {
          item.questionOrderItem.map((index) => {
            if (this.indexQuestion.id != index.id) {
              index.editListType = false;
              index.editTitleType = false;
            } else {
              if (index[key]) {
                index[key] = false;
              } else {
                index[key] = true;
              }
              if (key == "editTitleType") {
                index.editListType = false;
              }
              if (key == "editListType") {
                index.editTitleType = false;
              }
            }
          });
        }
        if (this.indexQuestion.id != item.id) {
          item.editListType = false;
          item.editTitleType = false;
        } else {
          if (item[key]) {
            item[key] = false;
          } else {
            item[key] = true;
          }
          if (key == "editTitleType") {
            item.editListType = false;
          }
          if (key == "editListType") {
            item.editTitleType = false;
          }
        }
      });
      this.paperContent = Object.assign({}, this.paperContent);
    },
    downList() {
      // console.log(this.indexQuestion);
      let indexQuestion = Object.assign({}, this.indexQuestion);
      indexQuestion.editListType = false;
      let array = this.indexQuestion.questionGroup.filter((item) => {
        if (Number(item.scoreMiss) > Number(item.score)) {
          return item;
        }
      });
      // console.log(array);
      array = array.map((item) => item.groupName);
      // console.log(array);
      if (array.length > 0) {
        this.$message({
          message: `${array.join(",")}组题漏选得分大于分数，请修改后保存！`,
          type: "warning",
          showClose: true,
        });
        return;
      }
      this.productionData.questionItem.map((item) => {
        if (item.id == indexQuestion.id) {
          Object.assign(item, indexQuestion);
          item.editListType = false;
        }
      });
      this.productionData.questionItem =
        this.productionData.questionItem.filter(
          (item) =>
            item.type == 5 ||
            item.type == 6 ||
            (item.type != 5 && item.type != 6 && item.questionGroup.length > 0)
        );
      // console.log(this.productionData.questionItem);
      this.$store.commit("SET_PRODUCTION", this.productionData);
      this.indexQuestion = {};
    },
    strlen(str) {
      var len = 0;
      str = str.toString();
      for (var i = 0; i < str.length; i++) {
        var c = str.charCodeAt(i);
        //单字节加1
        if ((c >= 0x0001 && c <= 0x007e) || (0xff60 <= c && c <= 0xff9f)) {
          len++;
        } else {
          len += 2;
        }
      }
      return len;
    },
    // 关闭标题
    downTitile() {
      if (this.indexQuestion.name && this.indexQuestion.name.length > 40) {
        this.$message({
          message: "题目名称不能超过10个文字",
          type: "warning",
        });
        return;
      }
      if (this.indexQuestion.intro && this.indexQuestion.intro.length > 40) {
        this.$message({
          message: "简介描述不能超过40个文字",
          type: "warning",
        });
        return;
      }
      this.indexQuestion.editTitleType = false;
      // 添加判断
      let array = [];
      if (this.indexQuestion.type == 4) {
        this.indexQuestion.namelist.map((item) => {
          let itemstr = item.questionNum.toString();
          if (itemstr.indexOf("  ") != -1) {
            array.push(itemstr);
          }
        });
      }
      let strName = "";
      if (this.indexQuestion.name) {
        strName = this.indexQuestion.name.toString();
      }

      if (strName.indexOf("  ") != -1 && this.indexQuestion.type == 5) {
        this.$message({
          message: `试题题号存在两个或两个以上连续空格，请修改后提交！`,
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (array.length > 0) {
        this.$message({
          message: `试题 ${array.join(
            ","
          )} 题号存在两个或两个以上连续空格，请修改后提交！`,
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (this.indexQuestion.type == 4) {
        let indexNamelist = this.indexQuestion.namelist.map(
          (index) => index.questionNum
        );
        let str = 0;
        this.indexQuestion.namelist.map((item) => {
          item.Qname = item.questionNum;
          if (this.strlen(item.questionNum) > str) {
            str = this.strlen(item.questionNum);
          }
        });
        this.indexQuestion.namelist.map((item) => {
          item.width = 12.1 + 1.6 * str;
        });
        this.indexQuestion.questionOrderItem.map((item, i) => {
          item.nameList = JSON.parse(
            JSON.stringify(this.indexQuestion.namelist)
          );
          item.nameList.map((iname) => {
            iname.questionNum =
              iname.questionNum +
              (this.indexQuestion.questionOrderItem.length > 1
                ? "（" + (i + 1) + "）"
                : "");
          });
          item.questionNum =
            indexNamelist.join("/") +
            (this.indexQuestion.questionOrderItem.length > 1
              ? "（" + (i + 1) + "）"
              : "");
        });
        this.indexQuestion.questionOrderList.map((item, i) => {
          item.nameList = JSON.parse(
            JSON.stringify(this.indexQuestion.namelist)
          );
          item.nameList.map((iname) => {
            iname.questionNum =
              iname.questionNum +
              (this.indexQuestion.questionOrderItem.length > 1
                ? "（" + (i + 1) + "）"
                : "");
          });
          item.questionNum =
            indexNamelist.join("/") +
            (this.indexQuestion.questionOrderItem.length > 1
              ? "（" + (i + 1) + "）"
              : "");
        });
      }
      // console.log(this.indexQuestion);
      // console.log(this.indexQuestion);
      this.productionData.questionItem.map((item) => {
        if (item.id == this.indexQuestion.id) {
          Object.assign(item, this.indexQuestion);
          if (item.type == 5) {
            item.questionNum = item.name;
          }
          item.editTitleType = false;
        }
      });
      this.productionData.questionItem =
        this.productionData.questionItem.filter(
          (item) =>
            item.type == 5 ||
            item.type == 6 ||
            (item.type != 5 && item.type != 6 && item.questionGroup.length > 0)
        );
      // console.log(this.productionData.questionItem);
      this.$store.commit("SEVA_PRODUCTION", this.productionData);
      this.$store.commit("SET_PRODUCTION", this.productionData);
      this.indexQuestion = {};
    },
    setChangeNum(item) {
      // console.log(this.studentIdNum);
      if (item.score < item.scoreMiss) {
        this.scoreMiss = this.scoreMiss.substr(0, this.scoreMiss.length - 1);
      }
    },
    // 删除试题组
    delItemGroup(indexGroup) {
      this.productionData.questionItem.map((item) => {
        if (item.id == this.indexQuestion.id) {
          if (item.type != 5) {
            item.questionGroup = item.questionGroup.filter(
              (index) => index.id != indexGroup.id
            );
          }
        }
      });
      this.indexQuestion.questionGroup =
        this.indexQuestion.questionGroup.filter(
          (index) => index.id != indexGroup.id
        );
    },
    // 关闭编辑弹框
    enterQuestion() {
      // this.$nextTick(() => {
      //   this.indexQuestion = {};
      // });
      // console.log(this.productionData.questionItem);
      // this.productionData.questionItem.map((item) => {
      //   if (item.id == this.indexQuestion.id) {
      //     Object.assign(item, this.indexQuestion);
      //   }
      // });
      // if (this.indexQuestion.type && this.indexQuestion.type != 5) {
      //   this.productionData.questionItem =
      //     this.productionData.questionItem.filter(
      //       (item) => item.questionGroup.length > 0
      //     );
      // }
      // console.log(this.productionData);
      // this.$store.commit("SET_PRODUCTION", this.productionData);
      // console.log("1111111");
    },
    addQuestion() {
      let questionData = {};
      if (this.indexQuestion.type == 1) {
        questionData = {
          questionStartOrder: "",
          questionNum: 5,
          score: 3,
          optionNum: 4,
          subType: 1,
          scoreMiss: 0,
        };
        // questionData.questionStartOrder = getQuestionMax(this.productionData);

        if (this.getIndexQuestionMax() > getQuestionMax()) {
          questionData.questionVal = this.getIndexQuestionMax();
          questionData.questionStartOrder = this.getIndexQuestionMax();
        } else {
          questionData.questionVal = getQuestionMax();
          questionData.questionStartOrder = getQuestionMax();
        }
        questionData.id = new Date().getTime();
        questionData.groupName =
          questionData.questionStartOrder +
          "-" +
          (questionData.questionNum + questionData.questionStartOrder - 1);
        if (this.indexQuestion.additionalQid) {
          questionData.groupName =
            questionData.questionStartOrder +
            "(1) - " +
            questionData.questionStartOrder +
            `(${questionData.questionNum})`;
        }
      }
      if (this.indexQuestion.type == 2) {
        questionData = {
          questionStartOrder: "",
          questionNum: 5,
          score: 3,
          itemNumber: 1,
          eachRowNumber: 2,
          additionalQid: false,
        };
        // questionData.questionStartOrder = getQuestionMax(this.productionData);
        if (this.getIndexQuestionMax() > getQuestionMax()) {
          questionData.questionVal = this.getIndexQuestionMax();
          questionData.questionStartOrder = this.getIndexQuestionMax();
        } else {
          questionData.questionVal = getQuestionMax();
          questionData.questionStartOrder = getQuestionMax();
        }
        questionData.id = new Date().getTime();

        questionData.groupName =
          questionData.questionStartOrder +
          "-" +
          (questionData.questionNum + questionData.questionStartOrder - 1);
        if (this.indexQuestion.additionalQid) {
          questionData.groupName =
            questionData.questionStartOrder +
            "(1) - " +
            questionData.questionStartOrder +
            `(${questionData.questionNum})`;
        }
      }
      this.indexQuestion.questionGroup.push(questionData);
      // console.log(this.productionData.questionItem);
    },
    getIndexQuestionMax() {
      let num = 0;
      this.indexQuestion.questionGroup.map((item) => {
        let val = Number(item.questionStartOrder) + Number(item.questionNum);
        if (val > num) {
          num = val;
        }
      });
      return num;
    },
  },
};
</script>
