import img from "@/static/sheet/uploadImg.svg";
import shortline from "@/static/sheet/shortline.svg";
import longline from "@/static/sheet/longline.svg";
import tzg from "@/static/sheet/webCreate/tzg.svg";
import table from "@/static/sheet/table.svg";
import store from "@/core/services/store";
import NProgress from "nprogress"; // progress bar
import { MessageBox } from "element-ui";
import { sheetUploadImg } from "@/core/api/exam/sheet";
// import img from '../../assets/img.png'

export function myImage() {
  // console.log(window.CKEDITOR);
  if (!window.CKEDITOR || !window.CKEDITOR.plugins) {
    return;
  }

  // window.CKEDITOR.config.readOnly = true;
  // console.log(window.CKEDITOR.instances["editor2"]);
  // 自定义上传图片
  // var editorKey = window.CKEDITOR.instances[b];
  // editorKey.on("key", function (a) {
  //   if (32 == a.data.keyCode) return editorKey.insertHtml("\x26#12288;"), !1;
  // });
  window.CKEDITOR.plugins.add("myImage", {
    icons: "myImage",
    init: function (editor) {
      editor.addCommand("myImage", {
        exec: function (editor) {
          let file = document.createElement("input");
          file.type = "file";
          file.accept = "image/*";
          file.addEventListener("change", () => {
            // const reader = new FileReader();

            let fileDate = file.files[0];
            const fileType = fileDate.type.substring(
              fileDate.type.lastIndexOf("/") + 1,
              fileDate.type.length
            );
            // console.log(fileDate);
            let type =
              fileType == "jpg" ||
              fileType == "jpeg" ||
              fileType == "png" ||
              fileType == "JPG" ||
              fileType == "JPEG";
            if (!type) {
              NProgress.error(
                "上传图片只能是jpg 、 jpeg 、 png 、 JPG 、 JPEG格式!"
              );
              return;
            }
            let formData = new FormData();
            formData.append("file", fileDate);
            sheetUploadImg(formData).then((res) => {
              let img = document.createElement("img");
              // 设置src值
              img.src = encodeURI(res.data.data.url);
              img.onload = function () {
                // console.log(img.width);
                // console.log(img.height);
                let str = `<img src="${res.data.data.url}"/>`;
                if (img.height > 200 || img.width > 200) {
                  if (img.height > img.width) {
                    let proportion = img.height / 200;
                    str = `<img style="height:200px;
                    width:${img.width / proportion}px"
                     src="${res.data.data.url}"/>`;
                  } else {
                    let proportion = img.width / 200;
                    str = `<img style="width:200px;
                    height:${img.height / proportion}px"
                     src="${res.data.data.url}"/>`;
                  }
                  //
                }
                // if (img.width > 200) {
                //   str = `<img style="width:200px" src="${res.data.data.url}"/>`;
                // }
                editor.insertHtml(str);
                // console.log(editor);
              };
            });
          });
          file.click();
        },
      });
      editor.ui.addButton("MyImage", {
        label: "上传图片",
        icon: img,
        command: "myImage",
      });
    },
  });
  const getStore = store;
  // 自定义画长线
  window.CKEDITOR.plugins.add("longLine", {
    icons: "longLine",
    init: (editor) => {
      editor.addCommand("longLine", {
        exec: (editor) => {
          // console.log();
          // \x3cp\x3e\x3cspan style\x3d'line-height:2.5'\x3e\x3cu\x3e\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x3c/u\x3e\x3c/span\x3e\x3c/p\x3e\x3cp\x3e\x3c/p\x3e
          let text =
            "\x3cp\x3e\x3cspan style\x3d'line-height:2.5'\x3e\x3cu\x3e\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x3c/u\x3e\x3c/span\x3e\x3c/p\x3e\x3cp\x3e\x3c/p\x3e";

          // console.log(getStore.getters.GET_PRODUCTION);
          // console.log(getStore.getters.GET_PRODUCTION.columnCount);
          // console.log(getStore.getters.GET_PRODUCTION.markType);
          if (getStore.getters.GET_PRODUCTION.gutter) {
            if (getStore.getters.GET_PRODUCTION.paperType == 2) {
              if (getStore.getters.GET_PRODUCTION.columnCount == 2) {
                text =
                  "\x3cp\x3e\x3cspan style\x3d'line-height:3'\x3e\x3cu\x3e\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x3c/u\x3e\x3c/span\x3e\x3c/p\x3e\x3cp\x3e\x3c/p\x3e";
              }
              if (getStore.getters.GET_PRODUCTION.columnCount == 3) {
                text =
                  "\x3cp\x3e\x3cspan style\x3d'line-height:3'\x3e\x3cu\x3e\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x3c/u\x3e\x3c/span\x3e\x3c/p\x3e\x3cp\x3e\x3c/p\x3e";
              }
            }
            if (getStore.getters.GET_PRODUCTION.paperType == 1) {
              text =
                "\x3cp\x3e\x3cspan style\x3d'line-height:3'\x3e\x3cu\x3e\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x3c/u\x3e\x3c/span\x3e\x3c/p\x3e\x3cp\x3e\x3c/p\x3e";
            }
          } else {
            if (getStore.getters.GET_PRODUCTION.paperType == 2) {
              if (getStore.getters.GET_PRODUCTION.columnCount == 2) {
                text =
                  "\x3cp\x3e\x3cspan style\x3d'line-height:3'\x3e\x3cu\x3e\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x3c/u\x3e\x3c/span\x3e\x3c/p\x3e\x3cp\x3e\x3c/p\x3e";
              }
              if (getStore.getters.GET_PRODUCTION.columnCount == 3) {
                text =
                  "\x3cp\x3e\x3cspan style\x3d'line-height:3'\x3e\x3cu\x3e\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x3c/u\x3e\x3c/span\x3e\x3c/p\x3e\x3cp\x3e\x3c/p\x3e";
              }
            }
            if (getStore.getters.GET_PRODUCTION.paperType == 1) {
              text =
                "\x3cp\x3e\x3cspan style\x3d'line-height:3'\x3e\x3cu\x3e\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x3c/u\x3e\x3c/span\x3e\x3c/p\x3e\x3cp\x3e\x3c/p\x3e";
            }
          }

          editor.insertHtml(text);
        },
      });
      editor.ui.addButton("LongLine", {
        label: "画长线",
        icon: longline,
        command: "longLine",
      });
    },
  });
  // 自定义画短线
  window.CKEDITOR.plugins.add("shortline", {
    icons: "shortline",
    init: function (editor) {
      editor.addCommand("shortline", {
        exec: function (editor) {
          let text =
            "\x3cspan style\x3d'line-height:2.5'\x3e\x3cu\x3e\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x3c/u\x3e\x3c/span\x3e\x3cspan\x3e\x3c/span\x3e";

          var b = !!window.ActiveXObject || "ActiveXObject" in window,
            c = -1 < navigator.userAgent.indexOf("Edge");
          if (b || c) text = text.replace(/&#12288;/g, "");
          editor.insertHtml(text);
        },
      });
      editor.ui.addButton("Shortline", {
        label: "画短线",
        icon: shortline,
        command: "shortline",
      });
    },
  });
  // 自定义画格子
  window.CKEDITOR.plugins.add("grid", {
    icons: "grid",
    init: function (editor) {
      editor.addCommand("grid", {
        exec: function (editor) {
          let arr = [];
          for (let i = 0; i < 19; i++) {
            arr.push(` <option value ='${i + 1}'>${i + 1}</option>`);
          }
          MessageBox.alert(
            `<div class='add-table'>每行插入：<select id='add-table1'>
            ${arr.join("")}
          </select> 个<div>
          <div class='add-table'>一共插入：<select id='add-table2'>
          ${arr.join("")}
          </select> 行<div>`,
            "插入表格",
            {
              dangerouslyUseHTMLString: true,
              confirmButtonText: "确定",
              cancelButtonText: "取消",
            }
          )
            .then(() => {
              var obj = document.getElementById("add-table1").selectedIndex;
              var obj1 = document.getElementById("add-table2").selectedIndex;
              let text = [];
              let textp = [];
              let textstr = `<td style='width:33px;height:33px'></td>`;
              for (let i = 0; i < obj + 1; i++) {
                textp.push(textstr);
              }
              let textstr1 = `<tr>${textp.join("")}</tr>`;
              for (let i = 0; i < obj1 + 1; i++) {
                text.push(textstr1);
              }
              editor.insertHtml(
                `<table border="1" cellspacing="0">${text.join("")}</table>`
              );
            })
            .catch(() => {});
        },
      });
      editor.ui.addButton("Grid", {
        label: "自定义表格",
        icon: table,
        command: "grid",
      });
    },
  });
  // 自定义田字格
  window.CKEDITOR.plugins.add("shortMatts", {
    icons: "shortMatts",
    init: function (editor) {
      editor.addCommand("shortMatts", {
        exec: function (editor) {
          const img =
            "<img style='margin-right:1px' src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAjCAMAAAApB0NrAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAAGUExURf///wAAAFXC034AAAA0SURBVHjaYmAkDBgYGdAAFoEBVcNAJTWj/qKNm8GpiAFOMkBJhPAQ9ddoGhs8/iIMAAIMAL/zALU7O2NNAAAAAElFTkSuQmCC' />";
          // console.log(img);
          let arr = [];
          for (let i = 0; i < 20; i++) {
            arr.push(` <option value ='${i + 1}'>${i + 1}</option>`);
          }
          MessageBox.alert(
            `<div class='add-table'>每行插入：<select id='add-table1'>
            ${arr.join("")}
            </select> 个<div>
            <div class='add-table'>一共插入：<select id='add-table2'>
            ${arr.join("")}
            </select> 行<div>`,
            "插入田字格",
            {
              dangerouslyUseHTMLString: true,
              confirmButtonText: "确定",
              cancelButtonText: "取消",
            }
          )
            .then(() => {
              var obj = document.getElementById("add-table1").selectedIndex;
              var obj1 = document.getElementById("add-table2").selectedIndex;
              // console.log(obj.selectedIndex);
              // console.log(obj1.selectedIndex);
              let text = [];
              let textp = [];
              for (let i = 0; i < obj + 1; i++) {
                textp.push(img);
              }
              textp = textp.join("");
              textp = `<span style='display:block;letter-spacing: 0;font-size: 14px;font-family:ui-monospace;'>${textp}</span>`;
              for (let i = 0; i < obj1 + 1; i++) {
                text.push(textp);
              }
              text = text.join("");
              text = `<span style='display:block;letter-spacing: 0;font-size: 14px;font-family: ui-monospace;'>${text}</span>`;
              editor.insertHtml(text);
            })
            .catch(() => {});
        },
      });
      editor.ui.addButton("ShortMatts", {
        label: "画田字格",
        icon: tzg,
        command: "shortMatts",
      });
    },
  });

  // // //ckeditor附加各种下划线样式
  window.CKEDITOR.config.image_previewText = window.CKEDITOR.tools.repeat(
    " ",
    1
  );
}
