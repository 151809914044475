var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.productionData.paper)?_c('div',{staticClass:"productionSheet",attrs:{"id":"productionSheet"}},[_c('div',{staticClass:"sheet-content front-sheet",class:{
      A4: _vm.examInformation.paperType == 1,
      A3: _vm.examInformation.paperType == 2,
      'A3-2':
        _vm.examInformation.columnCount == 2 && _vm.examInformation.paperType == 2,
      'A3-3':
        _vm.examInformation.columnCount == 3 && _vm.examInformation.paperType == 2,
      B4: _vm.examInformation.paperType == 3,
      'gutter-style': _vm.productionData.gutter,
    },style:({ width: _vm.productionData.paper.width + 'mm' }),attrs:{"id":"front-sheet"}},[(_vm.productionData.gutter)?_c('div',{staticClass:"gutter-box gutter-box-front"},[_c('div',{staticClass:"gutter-bar-code",style:(_vm.getKeyStyle('gutterBarCode', 'gutter'))},[_vm._v(" 贴条形码区 ")]),_c('div',{staticClass:"gutter-info",style:(_vm.getKeyStyle('gutterInfo', 'gutter'))},[_vm._m(0)])]):_vm._e(),(_vm.productionData.paperContent)?_c('div',{staticClass:"front-sheet-content",style:({
        width: _vm.productionData.paperContent.width + 'mm',
        left: _vm.productionData.frontPaper.firstContent.left + 'mm',
      })},[_c('div',{staticClass:"front-loction front-head-loction",style:({ width: _vm.productionData.paperContent.width + 'mm' })},[_c('span'),_c('span')]),(_vm.productionData.sheetHead)?_c('div',{ref:"frontHead",staticClass:"front-head",style:({ height: _vm.productionData.sheetHead.height + 'mm' })},[_c('div',{ref:"title",staticClass:"sheet-title",class:{
            'red-color': _vm.productionData.isRed,
          },style:({ height: _vm.productionData.sheetHead.title.height + 'mm' })},[_c('ckeditor',{staticClass:"CKEditor-style",style:({
              height: _vm.productionData.sheetHead.title.height + 'mm',
            }),attrs:{"type":"inline","config":_vm.editorConfig,"editor-url":_vm.editorUrl},model:{value:(_vm.productionData.sheetHead.title.content),callback:function ($$v) {_vm.$set(_vm.productionData.sheetHead.title, "content", $$v)},expression:"productionData.sheetHead.title.content"}}),_c('div',{staticClass:"hover-tips",on:{"mousedown":function($event){$event.stopPropagation();return _vm.editMove($event, 'title')}}})],1),(_vm.productionData.sheetHead.studentInfo)?_c('div',{ref:"studentInfo",staticClass:"border student-info",style:(_vm.getKeyStyle('studentInfo', 'sheetHead')),on:{"mousedown":function($event){return _vm.arrowMove($event, 'studentInfo')},"dblclick":function($event){$event.stopPropagation();return _vm.setEditor.apply(null, arguments)}}},[_c('ckeditor',{ref:"studentInfoBox",staticClass:"CKEditor-style",style:({
              height: _vm.productionData.sheetHead.studentInfo.height - 2 + 'mm',
            }),attrs:{"type":"inline","config":_vm.getConfig('studentInfoBox'),"read-only":_vm.editorType,"editor-url":_vm.editorUrl},model:{value:(_vm.productionData.sheetHead.studentInfo.content),callback:function ($$v) {_vm.$set(_vm.productionData.sheetHead.studentInfo, "content", $$v)},expression:"productionData.sheetHead.studentInfo.content"}}),_c('div',{staticClass:"hover-tips",on:{"mousedown":function($event){$event.stopPropagation();return _vm.editMove($event, 'studentInfo')}}})],1):_vm._e(),(_vm.productionData.sheetHead)?_c('div',{ref:"Absent",staticClass:"border Absent-box",class:{
            'red-color': _vm.productionData.isRed,
            'red-border': _vm.productionData.isRed,
          },style:(_vm.getKeyStyle('Absent', 'sheetHead')),on:{"mousedown":function($event){return _vm.arrowMove($event, 'Absent')}}},[_c('div',{style:({
              'line-height': _vm.productionData.sheetHead.Absent.height + 'mm',
            })},[_vm._v(" 缺考 ")]),_c('div',[_c('span',{staticClass:"border",class:{
                'red-border': _vm.productionData.isRed,
              }})]),_c('div',{staticClass:"hover-tips",on:{"mousedown":function($event){$event.stopPropagation();return _vm.editMove($event, 'Absent')}}})]):_vm._e(),(_vm.productionData.sheetHead.AbType)?_c('div',{ref:"AbType",staticClass:"border AbType-box",class:{
            'red-color': _vm.productionData.isRed,
            'red-border': _vm.productionData.isRed,
          },style:(_vm.getKeyStyle('AbType', 'sheetHead')),on:{"mousedown":function($event){return _vm.arrowMove($event, 'AbType')}}},[_c('div',{style:({
              'line-height':
                _vm.productionData.sheetHead.AbType.height / 2 + 'mm',
            })},[_c('div',[_vm._v("试卷A")]),_c('div',[_c('span',{staticClass:"border",class:{
                  'red-border': _vm.productionData.isRed,
                }})])]),_c('div',{style:({
              'line-height':
                _vm.productionData.sheetHead.AbType.height / 2 + 'mm',
            })},[_c('div',{style:({
                'line-height':
                  _vm.productionData.sheetHead.AbType.height / 2 + 'mm',
              })},[_vm._v(" 试卷B ")]),_c('div',[_c('span',{staticClass:"border",class:{
                  'red-border': _vm.productionData.isRed,
                }})])]),_c('div',{staticClass:"hover-tips",on:{"mousedown":function($event){$event.stopPropagation();return _vm.editMove($event, 'AbType')}}})]):_vm._e(),(_vm.productionData.sheetHead && _vm.productionData.sheetHead.warning)?_c('div',{ref:"warning",staticClass:"border warning-box",class:{
            'red-color': _vm.productionData.isRed,
            'red-border': _vm.productionData.isRed,
          },style:(_vm.getKeyStyle('warning', 'sheetHead')),on:{"mousedown":function($event){return _vm.arrowMove($event, 'warning')},"dblclick":function($event){$event.stopPropagation();return _vm.setEditor.apply(null, arguments)}}},[_c('ckeditor',{ref:"ckeditorKey",staticClass:"CKEditor-style",style:({
              height: _vm.productionData.sheetHead.warning.height - 4 + 'mm',
            }),attrs:{"type":"inline","config":_vm.getConfig('ckeditorKey'),"read-only":_vm.editorType,"editor-url":_vm.editorUrl},model:{value:(_vm.productionData.sheetHead.warning.content),callback:function ($$v) {_vm.$set(_vm.productionData.sheetHead.warning, "content", $$v)},expression:"productionData.sheetHead.warning.content"}}),_c('div',{staticClass:"hover-tips",on:{"mousedown":function($event){$event.stopPropagation();return _vm.editMove($event, 'warning')}}})],1):_vm._e(),(_vm.productionData.sheetHead.barCode)?_c('div',{staticClass:"bar-code",class:{
            'red-color': _vm.productionData.isRed,
            'red-border': _vm.productionData.isRed,
          },style:(_vm.getKeyStyle('barCode', 'sheetHead')),on:{"mousedown":function($event){return _vm.arrowMove($event, 'barCode')}}},[_vm._v(" 贴条形码区 ")]):_vm._e(),(_vm.productionData.sheetHead.studentId)?_c('table',{staticClass:"student-id",class:{
            'red-color': _vm.productionData.isRed,
            'red-border': _vm.productionData.isRed,
          },style:(_vm.getKeyStyle('studentId', 'sheetHead')),on:{"mousedown":function($event){return _vm.arrowMove($event, 'studentId')}}},[_c('tr',[_c('td',{staticClass:"student-id-title",attrs:{"colspan":_vm.productionData.sheetHead.studentId.studentIdNum}},[_vm._v(" 准考证号 ")])]),_c('tr',_vm._l((_vm.productionData.sheetHead.studentId
                .studentIdNum),function(item,index){return _c('td',{key:index,staticClass:"student-nums",class:{
                'red-border': _vm.productionData.isRed,
              },staticStyle:{"width":"6mm"}})}),0),_vm._l((10),function(v,i){return _c('tr',{key:i},_vm._l((_vm.productionData.sheetHead.studentId
                .studentIdNum),function(item,index){return _c('td',{key:index,staticClass:"student-id-nums",class:{
                'red-border': _vm.productionData.isRed,
              }},[_c('div',{class:{
                  'red-border': _vm.productionData.isRed,
                }},[_vm._v(" "+_vm._s(i)+" ")])])}),0)})],2):_vm._e(),_c('div',{staticClass:"ui-resizable-s",on:{"mousedown":function($event){return _vm.arrowHeight($event)}}})]):_vm._e(),(_vm.productionData.frontPaper)?[_c('questionsItem',{class:{ 'paper-msg-cnt-one': _vm.productionData.paperCnt },attrs:{"question":_vm.productionData.frontPaper.firstContent}},[(_vm.productionData.paperCnt)?_c('div',{staticClass:"btm-msg-box",class:{
              'red-color': _vm.productionData.isRed,
            }},[_vm._v(" 请在各题目答题区域内作答，超出黑色矩形边框限定区域的答案无效 ")]):_vm._e()])]:_vm._e(),_c('div',{staticClass:"front-loction front-foot-loction",style:({ width: _vm.productionData.paperContent.width + 'mm' })},[_vm._m(1),_c('span'),_c('p',[_vm._v("第 1 页")]),_c('span')])],2):_vm._e(),(
        _vm.productionData.frontPaper && _vm.productionData.frontPaper.secondContent
      )?_c('div',{staticClass:"front-sheet-content",style:({
        width: _vm.productionData.paperContent.width + 'mm',
        left: _vm.productionData.frontPaper.secondContent.left + 'mm',
      })},[_c('div',{staticClass:"front-loction front-head-loction",style:({ width: _vm.productionData.paperContent.width + 'mm' })},[_c('span'),(_vm.productionData.isCode)?_c('div',{staticClass:"codeimg"},[_c('img',{attrs:{"src":"https://static.wtjy.com/resource/document/%E5%AD%A6%E7%94%9FAPP%E4%B8%8B%E8%BD%BD_QRCODE.png","alt":""}}),_c('p',{staticClass:"code-exp"},[_vm._v("成绩查询：扫码下载文通云学生端APP")])]):_vm._e(),_c('span')]),(_vm.productionData.frontPaper)?[_c('questionsItem',{class:{ 'paper-msg-cnt': _vm.productionData.paperCnt },attrs:{"question":_vm.productionData.frontPaper.secondContent}},[(_vm.productionData.paperCnt)?_c('div',{staticClass:"top-msg-box",class:{
              'red-color': _vm.productionData.isRed,
            }},[_vm._v(" 请在各题目答题区域内作答，超出黑色矩形边框限定区域的答案无效 ")]):_vm._e(),(_vm.productionData.paperCnt)?_c('div',{staticClass:"btm-msg-box",class:{
              'red-color': _vm.productionData.isRed,
            }},[_vm._v(" 请在各题目答题区域内作答，超出黑色矩形边框限定区域的答案无效 ")]):_vm._e()])]:_vm._e(),_c('div',{staticClass:"front-loction front-foot-loction",style:({ width: _vm.productionData.paperContent.width + 'mm' })},[_c('span'),_c('p',[_vm._v("第 2 页")]),_c('span')])],2):_vm._e(),(
        _vm.productionData.frontPaper && _vm.productionData.frontPaper.thirdContent
      )?_c('div',{staticClass:"front-sheet-content",style:({
        width: _vm.productionData.paperContent.width + 'mm',
        left: _vm.productionData.frontPaper.thirdContent.left + 'mm',
      })},[_c('div',{staticClass:"front-loction front-head-loction",style:({ width: _vm.productionData.paperContent.width + 'mm' })},[_c('span'),_c('span')]),(_vm.productionData.frontPaper)?[_c('questionsItem',{class:{ 'paper-msg-cnt': _vm.productionData.paperCnt },attrs:{"question":_vm.productionData.frontPaper.thirdContent}},[(_vm.productionData.paperCnt)?_c('div',{staticClass:"top-msg-box",class:{
              'red-color': _vm.productionData.isRed,
            }},[_vm._v(" 请在各题目答题区域内作答，超出黑色矩形边框限定区域的答案无效 ")]):_vm._e(),(_vm.productionData.paperCnt)?_c('div',{staticClass:"btm-msg-box",class:{
              'red-color': _vm.productionData.isRed,
            }},[_vm._v(" 请在各题目答题区域内作答，超出黑色矩形边框限定区域的答案无效 ")]):_vm._e()])]:_vm._e(),_c('div',{staticClass:"front-loction front-foot-loction",style:({ width: _vm.productionData.paperContent.width + 'mm' })},[_c('span'),_c('p',[_vm._v("第 3 页")]),_c('span')])],2):_vm._e()]),_c('div',{staticClass:"sheet-content blank-sheet",class:{
      A4: _vm.examInformation.paperType == 1,
      A3: _vm.examInformation.paperType == 2,
      'A3-2':
        _vm.examInformation.columnCount == 2 && _vm.examInformation.paperType == 2,
      'A3-3':
        _vm.examInformation.columnCount == 3 && _vm.examInformation.paperType == 2,
      B4: _vm.examInformation.paperType == 3,
      'gutter-style': _vm.productionData.gutter,
    },style:({ width: _vm.productionData.paper.width + 'mm' }),attrs:{"id":"blank-sheet"}},[(_vm.productionData.blankPaper)?_c('div',{staticClass:"blank-sheet-content",style:({
        width: _vm.productionData.paperContent.width + 'mm',
        left: _vm.productionData.blankPaper.firstContent.left + 'mm',
      })},[_c('div',{staticClass:"blank-loction blank-head-loction",style:({ width: _vm.productionData.paperContent.width + 'mm' })},[(_vm.productionData.isCode && _vm.examInformation.paperType == 1)?_c('div',{staticClass:"codeimg"},[_c('img',{attrs:{"src":"https://static.wtjy.com/resource/document/%E5%AD%A6%E7%94%9FAPP%E4%B8%8B%E8%BD%BD_QRCODE.png","alt":""}}),_c('p',{staticClass:"code-exp"},[_vm._v("成绩查询：扫码下载文通云学生端APP")])]):_vm._e(),_c('span'),_c('span',{style:({
            visibility: _vm.examInformation.columnCount == 3 ? 'hidden' : '',
          })})]),(_vm.productionData.blankPaper)?[_c('questionsItem',{class:{ 'paper-msg-cnt': _vm.productionData.paperCnt },attrs:{"question":_vm.productionData.blankPaper.firstContent}},[(_vm.productionData.paperCnt)?_c('div',{staticClass:"top-msg-box",class:{
              'red-color': _vm.productionData.isRed,
            }},[_vm._v(" 请在各题目答题区域内作答，超出黑色矩形边框限定区域的答案无效 ")]):_vm._e(),(_vm.productionData.paperCnt)?_c('div',{staticClass:"btm-msg-box",class:{
              'red-color': _vm.productionData.isRed,
            }},[_vm._v(" 请在各题目答题区域内作答，超出黑色矩形边框限定区域的答案无效 ")]):_vm._e()])]:_vm._e(),_c('div',{staticClass:"blank-loction blank-foot-loction",style:({ width: _vm.productionData.paperContent.width + 'mm' })},[_vm._m(2),_c('span'),(_vm.examInformation.paperType == 1)?[_c('p',[_vm._v("第 2 页")])]:_vm._e(),(
            _vm.examInformation.columnCount == 2 && _vm.examInformation.paperType == 2
          )?[_c('p',[_vm._v("第 3 页")])]:_vm._e(),(
            _vm.examInformation.columnCount == 3 && _vm.examInformation.paperType == 2
          )?[_c('p',[_vm._v("第 4 页")])]:_vm._e(),_c('span',{style:({
            visibility: _vm.examInformation.columnCount == 3 ? 'hidden' : '',
          })})],2)],2):_vm._e(),(
        _vm.productionData.blankPaper && _vm.productionData.blankPaper.secondContent
      )?_c('div',{staticClass:"blank-sheet-content",style:({
        width: _vm.productionData.paperContent.width + 'mm',
        left: _vm.productionData.blankPaper.secondContent.left + 'mm',
      })},[_c('div',{staticClass:"blank-loction blank-head-loction",style:({ width: _vm.productionData.paperContent.width + 'mm' })},[_c('span'),_c('span')]),(_vm.productionData.blankPaper)?[_c('questionsItem',{class:{ 'paper-msg-cnt': _vm.productionData.paperCnt },attrs:{"question":_vm.productionData.blankPaper.secondContent}},[(_vm.productionData.paperCnt)?_c('div',{staticClass:"top-msg-box",class:{
              'red-color': _vm.productionData.isRed,
            }},[_vm._v(" 请在各题目答题区域内作答，超出黑色矩形边框限定区域的答案无效 ")]):_vm._e(),(_vm.productionData.paperCnt)?_c('div',{staticClass:"btm-msg-box",class:{
              'red-color': _vm.productionData.isRed,
            }},[_vm._v(" 请在各题目答题区域内作答，超出黑色矩形边框限定区域的答案无效 ")]):_vm._e()])]:_vm._e(),_c('div',{staticClass:"blank-loction blank-foot-loction",style:({ width: _vm.productionData.paperContent.width + 'mm' })},[_c('span'),(
            _vm.examInformation.columnCount == 2 && _vm.examInformation.paperType == 2
          )?[_c('p',[_vm._v("第 4 页")])]:_vm._e(),(
            _vm.examInformation.columnCount == 3 && _vm.examInformation.paperType == 2
          )?[_c('p',[_vm._v("第 5 页")])]:_vm._e(),_c('span')],2)],2):_vm._e(),(
        _vm.productionData.blankPaper && _vm.productionData.blankPaper.thirdContent
      )?_c('div',{staticClass:"blank-sheet-content",style:({
        width: _vm.productionData.paperContent.width + 'mm',
        left: _vm.productionData.blankPaper.thirdContent.left + 'mm',
      })},[_c('div',{staticClass:"blank-loction blank-head-loction",style:({ width: _vm.productionData.paperContent.width + 'mm' })},[_c('span',{staticStyle:{"visibility":"hidden"}}),_c('span')]),(_vm.productionData.blankPaper)?[_c('questionsItem',{class:{ 'paper-msg-cnt': _vm.productionData.paperCnt },attrs:{"question":_vm.productionData.blankPaper.thirdContent}},[(_vm.productionData.paperCnt)?_c('div',{staticClass:"top-msg-box",class:{
              'red-color': _vm.productionData.isRed,
            }},[_vm._v(" 请在各题目答题区域内作答，超出黑色矩形边框限定区域的答案无效 ")]):_vm._e(),(_vm.productionData.paperCnt)?_c('div',{staticClass:"btm-msg-box",class:{
              'red-color': _vm.productionData.isRed,
            }},[_vm._v(" 请在各题目答题区域内作答，超出黑色矩形边框限定区域的答案无效 ")]):_vm._e()])]:_vm._e(),_c('div',{staticClass:"blank-loction blank-foot-loction",style:({ width: _vm.productionData.paperContent.width + 'mm' })},[_c('span',{staticStyle:{"visibility":"hidden"}}),_c('p',[_vm._v("第 6 页")]),_c('span')])],2):_vm._e(),(_vm.productionData.gutter)?_c('div',{staticClass:"gutter-box gutter-box-blank"},[_c('span',[_vm._v("密 封 线 内 请 勿 作 答")])]):_vm._e()])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 考号 "),_c('span',{staticStyle:{"font-size":"24px"}},[_c('b'),_vm._v("  ")]),_vm._v(" 姓名 "),_c('span',{staticStyle:{"font-size":"24px"}},[_c('b'),_vm._v("  ")]),_vm._v(" 班级 "),_c('span',{staticStyle:{"font-size":"24px"}},[_c('b')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-mark"},[_c('div',{staticClass:"page-mark-list"}),_c('div',{staticClass:"page-mark-list"}),_c('div',{staticClass:"page-mark-list"}),_c('div',{staticClass:"page-mark-list"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-mark"},[_c('div',{staticClass:"page-mark-list"}),_c('div',{staticClass:"page-mark-list"}),_c('div',{staticClass:"page-mark-list"}),_c('div',{staticClass:"page-mark-list"})])
}]

export { render, staticRenderFns }