<template>
  <div class="sheetEdit">
    <sheetHeader @uploadHeader="uploadHeader">
      <div style="width: 316px; flex-shrink: 0">
        <el-button type="primary" :loading="btnLoading" @click="submitFile">
          保存
        </el-button>
        <el-button
          v-if="sheetInformation.pdfUrl"
          type="primary"
          :loading="downbtnLoading"
          :disabled="btnDisabled"
          @click="download()"
        >
          下载
        </el-button>
        <el-button
          v-if="sheetInformation.pdfUrl"
          type="primary"
          :loading="previewbtnLoading"
          :disabled="btnDisabled"
          @click="download(1)"
        >
          预览
        </el-button>
        <el-button type="danger" :disabled="btnDisabled" @click="clear()">
          清空
        </el-button>
      </div>
    </sheetHeader>
    <div class="sheet-title">
      {{ examInformation.name }}
    </div>
    <div
      v-if="examInformation.markType == 1"
      :class="{
        'A4-T': examInformation.paperType == 1,
        'A3-T': examInformation.paperType == 2,
      }"
    >
      <el-button type="text" @click="downTeacher">教师批阅规范下载</el-button>
    </div>
    <productionSheet ref="productionSheet"></productionSheet>
    <ckeditor
      style="height: 0; display: none"
      editor-url="./ckeditor/ckeditor.js"
      @namespaceloaded="onNamespaceLoaded"
    ></ckeditor>
    <div id="htmlShow" style="display: none"></div>
    <errorSheet ref="errorSheet"></errorSheet>
  </div>
</template>

<script>
import {
  exampapersheetmarkList,
  getExamPaperSheet,
  exampapersheetcontent,
} from "@/core/api/exam/sheet";
import {
  saveExampapersheetcontent,
  // examSheetOurs,
  // exampapersheetcontentHtmlImg,
} from "@/core/api/exam/sheet";
import sheetHeader from "./component/sheetHeader";
import errorSheet from "./component/errorSheet";
import productionSheet from "./component/productionSheet";
import { gutter } from "@/core/util/sheetPaper";
// import html2canvas from "html2canvas";
// import getJpeg from "@/core/util/htmlToImg";
import { cssJson, allcss, fontBorder } from "./js/pdf.js";
import { mapGetters } from "vuex";
import CKEditor from "ckeditor4-vue";
import { myImage } from "@/core/util/plugins";
// import pdfImg from "@/static/sheet/teacher.pdf";
import { fileDownloadByUrl } from "@/core/util/util";
// import jsPDF from "jspdf";
export default {
  name: "SheetEdit",
  components: {
    sheetHeader,
    productionSheet,
    errorSheet,
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      gutter: gutter,
      examInformation: {},
      sheetMarkList: [],
      sheetInformation: {},
      sheetHeader: [],
      btnLoading: false,
      downbtnLoading: false,
      previewbtnLoading: false,
      btnDisabled: false,
      clearbtnLoading: false,
    };
  },
  // sheetJson.sheetHead.warning
  computed: {
    ...mapGetters(["GET_SHEET_HEADER", "GET_PRODUCTION", "GET_LENGTH"]),
  },
  created() {
    this.$store.commit("SET_PRODUCTION", {});
    // this.exampapersheetmarkList();
    this.getExamPaperSheet();
  },
  // 参数 详解

  // gutter 装定线
  methods: {
    downTeacher() {
      let pdfImg =
        "https://static.wtjy.com/resource/document/%E6%95%99%E5%B8%88%E6%89%B9%E9%98%85%E8%A7%84%E8%8C%83.pdf";
      fileDownloadByUrl(pdfImg, "先阅后扫教师规范.pdf");
      // teacher-down
    },
    onNamespaceLoaded() {
      myImage();
    },
    clear() {
      const h = this.$createElement;
      this.$msgbox({
        title: "提示",
        message: h("p", null, [h("span", null, "是否清空答题卡？")]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          this.sheetInformation.contentJson = "";
          this.GET_PRODUCTION.questionItem = "";
          this.setSheetMsg();
        })
        .catch(() => {});
    },
    async submitFile() {
      let score = 0;
      let array = [];

      if (this.GET_PRODUCTION.questionItem) {
        // Promise.all(
        await new Promise((resolve) => {
          this.GET_PRODUCTION.questionItem.map((item, index) => {
            // array.push({
            //   height: item.height,
            //   id: item.id,
            // });
            if (item.type == 1 || item.type == 2) {
              item.questionGroup.map((index) => {
                score = score + index.score * index.questionNum;
              });
            }

            if (item.type == 3 || item.type == 4) {
              item.questionOrderList.map((index) => {
                score = score + Number(index.score);
              });
            }

            if (item.type == 5) {
              score = score + Number(item.score);
            }
            if (index == this.GET_PRODUCTION.questionItem.length - 1) {
              resolve();
            }
          });
          if (this.GET_PRODUCTION.questionItem.length == 0) {
            resolve();
          }
        });
        // console.log(this.GET_PRODUCTION);
        // 获取页面中的所有试题
        let { blankPaper, frontPaper } = this.GET_PRODUCTION;
        for (let i in frontPaper) {
          if (frontPaper[i].questionItem) {
            array = array.concat(frontPaper[i].questionItem);
          }
        }
        for (let i in blankPaper) {
          if (blankPaper[i].questionItem) {
            array = array.concat(blankPaper[i].questionItem);
          }
        }

        let errQuestionArr = [];
        array.map((item) => {
          if (item.type == 6) {
            let dom = document.getElementById(
              item.questionType ? item.id + "0" : item.id
            );

            if (dom) {
              let { offsetHeight, offsetWidth, scrollHeight, scrollWidth } =
                dom;
              scrollHeight = scrollHeight - 6;
              if (offsetHeight < scrollHeight || scrollWidth > offsetWidth) {
                errQuestionArr.push(
                  item.questionType
                    ? `非作答区域 ${item.order}续`
                    : `非作答区域 ${item.order}`
                );
              }
            }
          }
          if (item.type == 3 || item.type == 4) {
            item.questionOrderItem.map((index) => {
              let dom = document.getElementById(
                index.questionType
                  ? index.id + index.contentKey.toString()
                  : index.id
              );
              if (dom) {
                let { offsetHeight, offsetWidth, scrollHeight, scrollWidth } =
                  dom;

                scrollHeight = scrollHeight - 8;
                if (offsetHeight < scrollHeight || scrollWidth > offsetWidth) {
                  let str = index.questionType
                    ? `${item.name + index.questionNum}丶续`
                    : `${item.name + index.questionNum}`;
                  errQuestionArr.push(
                    item.type == 3 ? "解答题:" + str : "选做题:" + str
                  );
                }
              }
            });
          }
        });
        let sussScore = [90, 100, 110, 150, 300];
        if (sussScore.indexOf(score) == -1 || errQuestionArr.length > 0) {
          this.$refs.errorSheet.init(score, sussScore, errQuestionArr);
          // const h = this.$createElement;
          // this.$msgbox({
          //   title: "分数异常",
          //   message: h("p", null, [
          //     h("span", null, "当前试卷总分为 "),
          //     h("span", { style: "color: red" }, score + "分"),
          //     h("span", null, " 分数为非常规分数，是否继续保存？"),
          //   ]),
          //   showCancelButton: true,
          //   confirmButtonText: "确定",
          //   cancelButtonText: "取消",
          // })
          //   .then(() => {
          //     this.downImg();
          //   })
          //   .catch(() => {});
        } else {
          this.downImg();
        }
        // });
      } else {
        this.downImg();
      }
    },
    async downImg() {
      // console.log(data);
      if (this.GET_LENGTH) {
        this.$message({
          message: "答题卡超长，请修改后保存！",
          type: "warning",
          showClose: true,
        });
        return;
      }
      this.btnLoading = true;
      this.btnDisabled = true;

      // let resultcontentJpeg = await getJpeg(
      //   document.getElementById("front-sheet")
      // );
      // let form = new FormData();
      // form.append("file", resultcontentJpeg);
      // const res = await examSheetOurs(form);
      // this.frontSheet = res.data.data.url;
      // console.log(this.frontSheet);
      // 反面
      // let resultcontentJpeg1 = await getJpeg(
      //   document.getElementById("blank-sheet")
      // );
      // let form1 = new FormData();
      // form1.append("file", resultcontentJpeg1);
      // const res1 = await examSheetOurs(form1);
      // this.blankSheet = res1.data.data.url;
      // setTimeout(() => {
      //   this.btnLoading = false;
      //   this.btnDisabled = false;
      // }, 5000);
      // 正面
      this.saveExampapersheetcontent();
      // }
    },
    download(type) {
      if (type == 1) {
        this.previewbtnLoading = true;
      } else {
        this.downbtnLoading = true;
      }
      this.btnDisabled = true;

      // exampapersheetcontentHtmlImg(this.$route.query.sheetId)
      //   .then((res) => {
      // console.log(this.examInformation);
      let _this = this;
      if (type == 1) {
        fileDownloadByUrl(
          this.sheetInformation.pdfUrl + `?time=${new Date().getTime()}`,
          this.examInformation.name
        );
      } else {
        let req = new XMLHttpRequest();
        req.open(
          "GET",
          this.sheetInformation.pdfUrl + `?time=${new Date().getTime()}`,
          true
        );
        req.responseType = "blob";
        req.onload = function () {
          let url = window.URL.createObjectURL(req.response);
          let a = document.createElement("a");
          a.href = url;
          a.download = _this.examInformation.name + ".pdf";
          a.click();
          // resolve();
        };
        req.send();
      }

      if (type == 1) {
        this.previewbtnLoading = false;
      } else {
        this.downbtnLoading = false;
      }
      this.btnDisabled = false;
      // })
      // .catch(() => {
      //   this.btnDisabled = false;
      //   if (type == 1) {
      //     this.previewbtnLoading = false;
      //   } else {
      //     this.downbtnLoading = false;
      //   }
      // });
      // setTimeout(() => {}, 2000);
    },
    uploadHeader() {
      let newA = new Set(this.GET_SHEET_HEADER);
      let newB = new Set(this.sheetHeader);
      let diffArr = Array.from(new Set([...newA].filter((x) => !newB.has(x))));
      let diffArr1 = Array.from(new Set([...newB].filter((x) => !newA.has(x))));
      // console.log(newB);
      // console.log(newA);
      // 更新用户的选项
      this.sheetHeader = this.GET_SHEET_HEADER;

      // 重新初始话json
      if (
        diffArr[0] == "8" ||
        diffArr1[0] == "8" ||
        diffArr[0] == "9" ||
        diffArr1[0] == "9" ||
        diffArr[0] == "10" ||
        diffArr1[0] == "10"
      ) {
        this.setNewPaper();
      } else {
        this.hearSetJson();
      }
    },
    setNewPaper() {
      let sheetJson = this.GET_PRODUCTION;
      if (this.sheetHeader.indexOf("9") != -1) {
        sheetJson.isCode = true;
      } else {
        sheetJson.isCode = false;
      }
      if (this.sheetHeader.indexOf("8") != -1) {
        sheetJson.paperCnt = true;
      } else {
        sheetJson.paperCnt = false;
      }
      if (this.sheetHeader.indexOf("10") != -1) {
        sheetJson.isRed = true;
      } else {
        sheetJson.isRed = false;
      }
      this.$store.commit("SEVA_PRODUCTION", sheetJson);
      this.$store.commit("SET_PRODUCTION", sheetJson);
    },
    hearSetJson() {
      // console.log(this.sheetHeader);
      let sheetJson = {};
      // 1 先阅后扫  0 先扫后阅
      sheetJson.markType = this.examInformation.markType;
      // sheetJson.subjectId = this.examInformation.subjectId;
      sheetJson.columnCount = this.examInformation.columnCount;
      sheetJson.paperType = this.examInformation.paperType;
      sheetJson.sheetHead = {};
      sheetJson.sheetHead.title = {};
      sheetJson.sheetHead.Absent = {};
      sheetJson.sheetHead.AbType = {};
      sheetJson.sheetHead.warning = {};
      if (this.sheetHeader.indexOf("10") != -1) {
        sheetJson.isRed = true;
      } else {
        sheetJson.isRed = false;
      }
      if (this.sheetHeader.indexOf("9") != -1) {
        sheetJson.isCode = true;
      } else {
        sheetJson.isCode = false;
      }
      if (this.sheetHeader.indexOf("8") != -1) {
        sheetJson.paperCnt = true;
      } else {
        sheetJson.paperCnt = false;
      }

      // 判断有没有A B卷
      if (this.sheetHeader.indexOf("3") != 3) {
        // 单位mm
        sheetJson.sheetHead.height = 40.4;
        sheetJson.sheetHead.title.height = 10.93;
      } else {
        sheetJson.sheetHead.height = 40.4;
        sheetJson.sheetHead.title.height = 12;
      }
      sheetJson.sheetHead.nameStr = "答题卡卡头信息";
      sheetJson.sheetHead.title.nameStr = "答题卡标头";
      sheetJson.sheetHead.title.content = `<div style='text-align: center;'><p style='font-size: 16px'>${this.examInformation.name}</p></div>`;
      sheetJson.sheetHead.Absent = {
        left: 136.4,
        top: 14,
        width: 21,
        height: 24,
      };
      sheetJson.sheetHead.Absent.nameStr = "答题卡缺考标记";
      sheetJson.sheetHead.AbType = {
        left: 113.4,
        top: 14,
        width: 21,
        height: 24,
      };
      sheetJson.sheetHead.AbType.nameStr = "Ab卷标记";

      // console.log(sheetJson.sheetHead.warning);
      sheetJson.sheetHead.warning = {
        left: 0,
        top: 14,
        width: 111,
        height: 24,
        nameStr: "注意事项",
        content: `<div style='height: 18mm; position: relative'><p><strong><span>注意事项：</span></strong></p><p><span style='line-height: 1.3'>1.答题前，考生先将自己的姓名、准考证号码填写清楚。</span></p><p><span style='line-height: 1.3'>2.选择题必须使用2B铅笔填涂且按正确填涂方式填涂：<img src='${require("@/static/sheet/fullfilling.png")}' style="margin:0 3px;vertical-align: text-bottom;"> 非选择题必须使用0.5毫米黑色字迹的签字笔书写，字体工整，笔迹清晰。</span></p></div>`,
      };

      /**
     this.sheetHeader 参数详解
    1 准考证号 ；2 条形码； 3 AB卷； 4 装订线 ； 5 注意事项； 6 缺考； 7 学生信息 ；8 试卷信息 ；9 二维码
   **/
      // 判断有没有勾选装订线
      if (this.sheetHeader.indexOf("4") != -1) {
        sheetJson.gutter = this.gutter;
        // sheetJson.sheetHead.height = sheetJson.sheetHead.height+10;
        // 如果是没有考号的情况
        if (this.sheetHeader.indexOf("1") == -1) {
          sheetJson.sheetHead.warning.width = 121;
          sheetJson.sheetHead.warning.height = 25;
          sheetJson.sheetHead.warning.top = 12.5;

          //
          sheetJson.sheetHead.Absent.height = 9.35;
          sheetJson.sheetHead.Absent.width = 34.5;
          sheetJson.sheetHead.Absent.top = 28;
          sheetJson.sheetHead.Absent.left = 123;
          // 如果是没有AB卷
          if (this.sheetHeader.indexOf("3") == -1) {
            //
            sheetJson.sheetHead.Absent.height = 25;
            sheetJson.sheetHead.Absent.width = 34.5;
            sheetJson.sheetHead.Absent.top = 12.5;
            sheetJson.sheetHead.Absent.left = 123;
            //
            delete sheetJson.sheetHead.AbType;
          } else {
            //
            sheetJson.sheetHead.AbType.top = 12.5;
            sheetJson.sheetHead.AbType.left = 123;
            sheetJson.sheetHead.AbType.width = 34.5;
            sheetJson.sheetHead.AbType.height = 13.6;
          }
          //
        } else {
          // 有考号的情况
          sheetJson.sheetHead.height = 63;
          sheetJson.sheetHead.studentId = {
            left: 85.5,
            top: 12,
            width: 72,
            height: 49,
            nameStr: "考号",
          };
          //
          sheetJson.sheetHead.warning.width = 83;
          sheetJson.sheetHead.warning.height = 31;
          sheetJson.sheetHead.warning.top = 12;
          sheetJson.sheetHead.warning.left = 0;
          //
          sheetJson.sheetHead.Absent.height = 15;
          sheetJson.sheetHead.Absent.width = 40;
          sheetJson.sheetHead.Absent.top = 46;
          sheetJson.sheetHead.Absent.left = 0;

          // 如果是没有AB卷
          if (this.sheetHeader.indexOf("3") == -1) {
            //
            sheetJson.sheetHead.Absent.height = 16;
            sheetJson.sheetHead.Absent.width = 83;
            sheetJson.sheetHead.Absent.top = 45;
            sheetJson.sheetHead.Absent.left = 0;
            //
            delete sheetJson.sheetHead.AbType;
          } else {
            //
            sheetJson.sheetHead.AbType.top = 46;
            sheetJson.sheetHead.AbType.left = 43;
            sheetJson.sheetHead.AbType.width = 40;
            sheetJson.sheetHead.AbType.height = 15;
          }
        }
      } else {
        // 如果存在装订线 直接删除
        if (sheetJson.gutter) {
          delete sheetJson.gutter;
        }
        sheetJson.sheetHead.height = 50;
        // 如果是有条形码的情况
        if (this.sheetHeader.indexOf("2") != -1) {
          sheetJson.sheetHead.barCode = {
            left: 138.56,
            top: 22.5,
            width: 56,
            height: 25,
            nameStr: "条形码",
          };
          // 如果是没有考号的情况
          if (this.sheetHeader.indexOf("1") == -1) {
            sheetJson.sheetHead.warning.width = 100;
            sheetJson.sheetHead.warning.height = 25;
            sheetJson.sheetHead.warning.top = 22.5;

            //
            sheetJson.sheetHead.Absent.height = 9.35;
            sheetJson.sheetHead.Absent.width = 34.5;
            sheetJson.sheetHead.Absent.top = 38;
            sheetJson.sheetHead.Absent.left = 101.55;
            //
            // 如果是没有AB卷
            if (this.sheetHeader.indexOf("3") == -1) {
              //
              sheetJson.sheetHead.Absent.height = 25;
              sheetJson.sheetHead.Absent.width = 34.5;
              sheetJson.sheetHead.Absent.top = 22.5;
              sheetJson.sheetHead.Absent.left = 101.55;
              //
              delete sheetJson.sheetHead.AbType;
            } else {
              //
              sheetJson.sheetHead.AbType.top = 22.5;
              sheetJson.sheetHead.AbType.left = 101.55;
              sheetJson.sheetHead.AbType.width = 34.5;
              sheetJson.sheetHead.AbType.height = 13.6;
            }
          } else {
            // 有考号的情况
            sheetJson.sheetHead.height = 72;
            sheetJson.sheetHead.studentId = {
              left: 123,
              top: 22,
              width: 72,
              height: 49,
              nameStr: "考号",
            };
            //
            sheetJson.sheetHead.warning.width = 120;
            sheetJson.sheetHead.warning.height = 22;
            sheetJson.sheetHead.warning.top = 22;
            sheetJson.sheetHead.warning.left = 0;
            //
            sheetJson.sheetHead.barCode.width = 61;
            sheetJson.sheetHead.barCode.height = 24;
            sheetJson.sheetHead.barCode.top = 46;
            sheetJson.sheetHead.barCode.left = 59;
            //
            sheetJson.sheetHead.Absent.height = 24;
            sheetJson.sheetHead.Absent.width = 27;
            sheetJson.sheetHead.Absent.top = 46;
            sheetJson.sheetHead.Absent.left = 0;
            //
            sheetJson.sheetHead.AbType.top = 46;
            sheetJson.sheetHead.AbType.left = 29;
            sheetJson.sheetHead.AbType.width = 28;
            sheetJson.sheetHead.AbType.height = 24;
            // 如果是没有AB卷
            if (this.sheetHeader.indexOf("3") == -1) {
              //
              sheetJson.sheetHead.Absent.height = 24;
              sheetJson.sheetHead.Absent.width = 58;
              sheetJson.sheetHead.Absent.top = 46;
              sheetJson.sheetHead.Absent.left = 0;
              //
              delete sheetJson.sheetHead.AbType;
            }
          }
          sheetJson.sheetHead.studentInfo = {
            left: 0,
            top: 12.66,
            width: 195,
            height: 8,
            nameStr: "学生信息",
          };
          sheetJson.sheetHead.studentInfo.content =
            "<p>考号<span style='line-height:2'><u>\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;</u></span><span></span>\x26#12288;姓名<span style='line-height:2'><u>\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;</u></span><span></span>\x26#12288;班级<span style='line-height:2'><u>\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;</u></span><span></span>";
        } else {
          sheetJson.sheetHead.height = 75;
          sheetJson.sheetHead.studentId = {
            left: 123,
            top: 24,
            width: 72,
            height: 49,
            nameStr: "考号",
          };
          //

          sheetJson.sheetHead.warning.width = 84;
          sheetJson.sheetHead.warning.height = 49;
          sheetJson.sheetHead.warning.top = 24;
          sheetJson.sheetHead.warning.left = 0;

          //
          sheetJson.sheetHead.Absent.height = 20;
          sheetJson.sheetHead.Absent.width = 35;
          sheetJson.sheetHead.Absent.top = 24;
          sheetJson.sheetHead.Absent.left = 86;

          // 如果是没有AB卷
          if (this.sheetHeader.indexOf("3") == -1) {
            //
            sheetJson.sheetHead.Absent.height = 49;
            sheetJson.sheetHead.Absent.width = 35;
            sheetJson.sheetHead.Absent.top = 24;
            sheetJson.sheetHead.Absent.left = 86;
            //

            delete sheetJson.sheetHead.AbType;
          } else {
            //
            sheetJson.sheetHead.AbType.top = 46;
            sheetJson.sheetHead.AbType.left = 86;
            sheetJson.sheetHead.AbType.width = 35;
            sheetJson.sheetHead.AbType.height = 27;
          }
          sheetJson.sheetHead.studentInfo = {
            left: 0,
            top: 13,
            width: 195,
            height: 9,
            nameStr: "学生信息",
          };
          sheetJson.sheetHead.studentInfo.content =
            "<p>考号<span style='line-height:2'><u>\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;</u></span><span></span>\x26#12288;姓名<span style='line-height:2'><u>\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;</u></span><span></span>\x26#12288;班级<span style='line-height:2'><u>\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;</u></span><span></span></p>";
        }
      }
      sheetJson.frontPaper = {
        nameStr: "第一张纸的基本信息",
        height: 261,
        // - sheetJson.sheetHead.height
      };

      sheetJson.blankPaper = {
        nameStr: "第二张纸的基本信息",
        height: 261,
      };
      sheetJson.frontPaper.firstContent = {
        height: 261 - sheetJson.sheetHead.height,
        questionItem: [],
        residueHeight: 261 - sheetJson.sheetHead.height,
        defaultHeight: 261 - sheetJson.sheetHead.height,
      };
      sheetJson.blankPaper.firstContent = {
        height: 261,
        questionItem: [],
        residueHeight: 261,
        defaultHeight: 261,
      };
      // 每个内容的宽度
      sheetJson.paperContent = {
        nameStr: "试题显示的内容的宽度",
      };
      // console.log(this.sheetHeader);
      // console.log(sheetJson);
      // 如果是A4纸  剩下的框的高度
      if (sheetJson.paperType == 1) {
        // 存在装订线的情况下 试题内容显示的宽度
        if (sheetJson.gutter) {
          sheetJson.paperContent.width = 158;
          sheetJson.frontPaper.firstContent.left = 40;
          sheetJson.blankPaper.firstContent.left = 10;
        } else {
          // 不存在装订线的时候试题内容可显示的宽度
          sheetJson.paperContent.width = 195;
          sheetJson.frontPaper.firstContent.left = 10;
          sheetJson.blankPaper.firstContent.left = 10;
        }
        // sheetJson.frontQuestionHeight = ;
        // A4纸的高度
        sheetJson.paper = {
          nameStr: "纸张信息",
          width: 214,
          height: 297,
        };
      }
      // A3纸
      if (sheetJson.paperType == 2) {
        sheetJson.frontPaper.secondContent = {
          height: 261,
          questionItem: [],
          residueHeight: 261,
          defaultHeight: 261,
        };

        sheetJson.blankPaper.secondContent = {
          height: 261,
          questionItem: [],
          residueHeight: 261,
          defaultHeight: 261,
        };
        // 每个内容的宽度

        // console.log(sheetJson.columnCount);
        if (sheetJson.columnCount == 3) {
          sheetJson.frontPaper.thirdContent = {
            height: 261,
            questionItem: [],
            residueHeight: 261,
            defaultHeight: 261,
          };
          sheetJson.blankPaper.thirdContent = {
            height: 261,
            questionItem: [],
            residueHeight: 261,
            defaultHeight: 261,
          };
          if (sheetJson.gutter) {
            sheetJson.paperContent.width = 120;
            sheetJson.frontPaper.firstContent.left = 40;
            sheetJson.frontPaper.secondContent.left = 167;
            sheetJson.frontPaper.thirdContent.left = 293;
            sheetJson.blankPaper.firstContent.left = 10;
            sheetJson.blankPaper.secondContent.left = 136;
            sheetJson.blankPaper.thirdContent.left = 262;
          } else {
            // 不存在装订线的时候试题内容可显示的宽度
            sheetJson.paperContent.width = 130;
            sheetJson.frontPaper.firstContent.left = 10;
            sheetJson.frontPaper.secondContent.left = 146;
            sheetJson.frontPaper.thirdContent.left = 283;
            sheetJson.blankPaper.firstContent.left = 10;
            sheetJson.blankPaper.secondContent.left = 146;
            sheetJson.blankPaper.thirdContent.left = 283;
          }
        }
        if (sheetJson.columnCount == 2) {
          // 存在装订线的情况下 试题内容显示的宽度
          if (sheetJson.gutter) {
            sheetJson.paperContent.width = 182;
            sheetJson.frontPaper.firstContent.left = 40;
            sheetJson.frontPaper.secondContent.left = 230;
            sheetJson.blankPaper.firstContent.left = 10;
            sheetJson.blankPaper.secondContent.left = 198;
          } else {
            // 不存在装订线的时候试题内容可显示的宽度
            sheetJson.paperContent.width = 195;
            sheetJson.frontPaper.firstContent.left = 10;
            sheetJson.frontPaper.secondContent.left = 217;
            sheetJson.blankPaper.firstContent.left = 10;
            sheetJson.blankPaper.secondContent.left = 217;
          }
        }

        // sheetJson.frontQuestionHeight = ;
        // A4纸的高度
        sheetJson.paper = {
          nameStr: "纸张信息",
          width: 424,
          height: 297,
        };
        if (sheetJson.columnCount == 2) {
          // 判断有没有勾选装订线
          if (this.sheetHeader.indexOf("4") != -1) {
            sheetJson.gutter = this.gutter;
            // sheetJson.sheetHead.height = sheetJson.sheetHead.height+10;
            // 如果是没有考号的情况
            if (this.sheetHeader.indexOf("1") == -1) {
              sheetJson.sheetHead.warning.width = 121;
              sheetJson.sheetHead.warning.height = 25;
              sheetJson.sheetHead.warning.top = 12.5;

              //
              sheetJson.sheetHead.Absent.height = 25;
              sheetJson.sheetHead.Absent.width = 23;
              sheetJson.sheetHead.Absent.top = 12.5;
              sheetJson.sheetHead.Absent.left = 160;
              // 如果是没有AB卷
              if (this.sheetHeader.indexOf("3") == -1) {
                //
                sheetJson.sheetHead.Absent.height = 25;
                sheetJson.sheetHead.Absent.width = 29.5;
                sheetJson.sheetHead.Absent.top = 12.5;
                sheetJson.sheetHead.Absent.left = 123;
                //
                delete sheetJson.sheetHead.AbType;
              } else {
                //
                sheetJson.sheetHead.AbType.top = 12.5;
                sheetJson.sheetHead.AbType.left = 123;
                sheetJson.sheetHead.AbType.width = 32.5;
                sheetJson.sheetHead.AbType.height = 25;
              }
              //
            } else {
              // 有考号的情况
              sheetJson.sheetHead.height = 63;
              sheetJson.sheetHead.studentId = {
                left: 110,
                top: 12,
                width: 72,
                height: 49,
                nameStr: "考号",
              };
              //
              sheetJson.sheetHead.warning.width = 76;
              sheetJson.sheetHead.warning.height = 49;
              sheetJson.sheetHead.warning.top = 12;
              sheetJson.sheetHead.warning.left = 0;
              sheetJson.sheetHead.warning.content = `<div style='height: 18mm; position: relative'><p><strong><span>注意事项：</span></strong></p><p><span style='line-height: 1.3'>1.答题前，考生先将自己的姓名、准考证号码填写清楚。</span></p><p><span style='line-height: 1.3'>2.选择题必须使用2B铅笔填涂且按正确填涂方式填涂：<img src='${require("@/static/sheet/fullfilling.png")}' style="margin:0 3px;vertical-align: text-bottom;"> 非选择题必须使用0.5毫米黑色字迹的签字笔书写，字体工整，笔迹清晰。</span></p><p><span style="line-height: 1.3">3.请按照题号顺序在各题目的答题区域内作答，超出答题区域书写答案无效，在草稿纸、试题卷上答题无效。</span></p></div>`;
              //
              sheetJson.sheetHead.Absent.height = 22;
              sheetJson.sheetHead.Absent.width = 30.5;
              sheetJson.sheetHead.Absent.top = 12;
              sheetJson.sheetHead.Absent.left = 77;

              // 如果是没有AB卷
              if (this.sheetHeader.indexOf("3") == -1) {
                //
                sheetJson.sheetHead.Absent.height = 49;
                sheetJson.sheetHead.Absent.width = 30.5;
                sheetJson.sheetHead.Absent.top = 12;
                sheetJson.sheetHead.Absent.left = 77;
                //
                delete sheetJson.sheetHead.AbType;
              } else {
                //
                sheetJson.sheetHead.AbType.top = 35.5;
                sheetJson.sheetHead.AbType.left = 77;
                sheetJson.sheetHead.AbType.width = 30.5;
                sheetJson.sheetHead.AbType.height = 26;
              }
            }
          } else {
            // 如果存在装订线 直接删除
            if (sheetJson.gutter) {
              delete sheetJson.gutter;
            }
            sheetJson.sheetHead.height = 50;
            // 如果是有条形码的情况
            if (this.sheetHeader.indexOf("2") != -1) {
              sheetJson.sheetHead.barCode = {
                left: 138.56,
                top: 22.5,
                width: 56,
                height: 25,
                nameStr: "条形码",
              };
              // 如果是没有考号的情况
              if (this.sheetHeader.indexOf("1") == -1) {
                sheetJson.sheetHead.warning.width = 100;
                sheetJson.sheetHead.warning.height = 25;
                sheetJson.sheetHead.warning.top = 22.5;

                //
                sheetJson.sheetHead.Absent.height = 9.35;
                sheetJson.sheetHead.Absent.width = 34.5;
                sheetJson.sheetHead.Absent.top = 38;
                sheetJson.sheetHead.Absent.left = 101.55;
                //
                // 如果是没有AB卷
                if (this.sheetHeader.indexOf("3") == -1) {
                  //
                  sheetJson.sheetHead.Absent.height = 25;
                  sheetJson.sheetHead.Absent.width = 34.5;
                  sheetJson.sheetHead.Absent.top = 22.5;
                  sheetJson.sheetHead.Absent.left = 101.55;
                  //
                  delete sheetJson.sheetHead.AbType;
                } else {
                  //
                  sheetJson.sheetHead.AbType.top = 22.5;
                  sheetJson.sheetHead.AbType.left = 101.55;
                  sheetJson.sheetHead.AbType.width = 34.5;
                  sheetJson.sheetHead.AbType.height = 13.6;
                }
              } else {
                // 有考号的情况
                sheetJson.sheetHead.height = 72;
                sheetJson.sheetHead.studentId = {
                  left: 123,
                  top: 22,
                  width: 72,
                  height: 49,
                  nameStr: "考号",
                };
                //
                sheetJson.sheetHead.warning.width = 120;
                sheetJson.sheetHead.warning.height = 22;
                sheetJson.sheetHead.warning.top = 22;
                sheetJson.sheetHead.warning.left = 0;

                //
                sheetJson.sheetHead.barCode.width = 61;
                sheetJson.sheetHead.barCode.height = 24;
                sheetJson.sheetHead.barCode.top = 46;
                sheetJson.sheetHead.barCode.left = 59;
                //
                sheetJson.sheetHead.Absent.height = 24;
                sheetJson.sheetHead.Absent.width = 27;
                sheetJson.sheetHead.Absent.top = 46;
                sheetJson.sheetHead.Absent.left = 0;

                // 如果是没有AB卷
                if (this.sheetHeader.indexOf("3") == -1) {
                  //
                  sheetJson.sheetHead.Absent.height = 24;
                  sheetJson.sheetHead.Absent.width = 58;
                  sheetJson.sheetHead.Absent.top = 46;
                  sheetJson.sheetHead.Absent.left = 0;
                  //
                  delete sheetJson.sheetHead.AbType;
                } else {
                  //
                  sheetJson.sheetHead.AbType.top = 46;
                  sheetJson.sheetHead.AbType.left = 29;
                  sheetJson.sheetHead.AbType.width = 28;
                  sheetJson.sheetHead.AbType.height = 24;
                }
              }
              sheetJson.sheetHead.studentInfo = {
                left: 0,
                top: 12.66,
                width: 195,
                height: 8,
                nameStr: "学生信息",
              };
              sheetJson.sheetHead.studentInfo.content =
                "<p>考号<span style='line-height:2'><u>\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;</u></span><span></span>\x26#12288;</span>姓名<span style='line-height:2'><u>\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;</u></span><span></span>\x26#12288;班级<span style='line-height:2'><u>\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;</u></span><span></span></p>";
            } else {
              sheetJson.sheetHead.height = 75;
              sheetJson.sheetHead.studentId = {
                left: 123,
                top: 24,
                width: 72,
                height: 49,
                nameStr: "考号",
              };
              //

              sheetJson.sheetHead.warning.width = 84;
              sheetJson.sheetHead.warning.height = 49;
              sheetJson.sheetHead.warning.top = 24;
              sheetJson.sheetHead.warning.left = 0;
              sheetJson.sheetHead.warning.content = `<div style='height: 18mm; position: relative'><p><strong><span>注意事项：</span></strong></p><p><span style='line-height: 1.3'>1.答题前，考生先将自己的姓名、准考证号码填写清楚。</span></p><p><span style='line-height: 1.3'>2.选择题必须使用2B铅笔填涂且按正确填涂方式填涂：<img src='${require("@/static/sheet/fullfilling.png")}' style="margin:0 3px;vertical-align: text-bottom;"> 非选择题必须使用0.5毫米黑色字迹的签字笔书写，字体工整，笔迹清晰。</span></p><p><span style="line-height: 1.3">3.请按照题号顺序在答题区域内作答，超出答题区域书写答案无效。</span></p></div>`;

              //
              sheetJson.sheetHead.Absent.height = 20;
              sheetJson.sheetHead.Absent.width = 35;
              sheetJson.sheetHead.Absent.top = 24;
              sheetJson.sheetHead.Absent.left = 86;

              // 如果是没有AB卷
              if (this.sheetHeader.indexOf("3") == -1) {
                //
                sheetJson.sheetHead.Absent.height = 49;
                sheetJson.sheetHead.Absent.width = 35;
                sheetJson.sheetHead.Absent.top = 24;
                sheetJson.sheetHead.Absent.left = 86;
                //

                delete sheetJson.sheetHead.AbType;
              } else {
                //
                sheetJson.sheetHead.AbType.top = 46;
                sheetJson.sheetHead.AbType.left = 86;
                sheetJson.sheetHead.AbType.width = 35;
                sheetJson.sheetHead.AbType.height = 27;
              }
              sheetJson.sheetHead.studentInfo = {
                left: 0,
                top: 13,
                width: 195,
                height: 9,
                nameStr: "学生信息",
              };
              sheetJson.sheetHead.studentInfo.content =
                "<p>考号<span style='line-height:2'><u>\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;</u></span><span></span>\x26#12288;姓名<span style='line-height:2'><u>\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;</u></span><span></span>\x26#12288;班级<span style='line-height:2'><u>\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;</u></span><span></span></p>";
            }
          }
        }
        if (sheetJson.columnCount == 3) {
          // 判断有没有勾选装订线
          if (this.sheetHeader.indexOf("4") != -1) {
            sheetJson.gutter = this.gutter;
            // sheetJson.sheetHead.height = sheetJson.sheetHead.height+10;
            // 如果是没有考号的情况
            if (this.sheetHeader.indexOf("1") == -1) {
              sheetJson.sheetHead.height = 47;
              sheetJson.sheetHead.warning.width = 74;
              sheetJson.sheetHead.warning.height = 33;
              sheetJson.sheetHead.warning.top = 13;

              //
              sheetJson.sheetHead.Absent.height = 33;
              sheetJson.sheetHead.Absent.width = 21;
              sheetJson.sheetHead.Absent.top = 13;
              sheetJson.sheetHead.Absent.left = 76;
              // 如果是没有AB卷
              if (this.sheetHeader.indexOf("3") == -1) {
                sheetJson.sheetHead.height = 38;
                //
                sheetJson.sheetHead.Absent.height = 23;
                sheetJson.sheetHead.Absent.width = 19;
                sheetJson.sheetHead.Absent.top = 13;
                sheetJson.sheetHead.Absent.left = 101;
                //
                sheetJson.sheetHead.warning.width = 100;
                sheetJson.sheetHead.warning.height = 23;
                sheetJson.sheetHead.warning.top = 13;
                sheetJson.sheetHead.warning.left = 0;
                delete sheetJson.sheetHead.AbType;
              } else {
                //
                sheetJson.sheetHead.AbType.top = 13;
                sheetJson.sheetHead.AbType.left = 99;
                sheetJson.sheetHead.AbType.width = 21.5;
                sheetJson.sheetHead.AbType.height = 33;
              }
              //
            } else {
              // 有考号的情况
              sheetJson.sheetHead.height = 89;
              sheetJson.sheetHead.studentId = {
                left: 48,
                top: 37,
                width: 72,
                height: 49,
                nameStr: "考号",
              };
              //
              sheetJson.sheetHead.warning.width = 120;
              sheetJson.sheetHead.warning.height = 23;
              sheetJson.sheetHead.warning.top = 12.5;
              sheetJson.sheetHead.warning.left = 0;
              //
              sheetJson.sheetHead.Absent.height = 24;
              sheetJson.sheetHead.Absent.width = 46;
              sheetJson.sheetHead.Absent.top = 37;
              sheetJson.sheetHead.Absent.left = 0;

              // 如果是没有AB卷
              if (this.sheetHeader.indexOf("3") == -1) {
                sheetJson.sheetHead.height = 64;
                //
                sheetJson.sheetHead.Absent.height = 6;
                sheetJson.sheetHead.Absent.width = 46;
                sheetJson.sheetHead.Absent.top = 56;
                sheetJson.sheetHead.Absent.left = 0;
                //

                sheetJson.sheetHead.warning.width = 46;
                sheetJson.sheetHead.warning.height = 42;
                sheetJson.sheetHead.warning.top = 13;
                sheetJson.sheetHead.warning.left = 0;
                //
                sheetJson.sheetHead.studentId = {
                  left: 48,
                  top: 13,
                  width: 72,
                  height: 49,
                  nameStr: "考号",
                };
                delete sheetJson.sheetHead.AbType;
              } else {
                //
                sheetJson.sheetHead.AbType.top = 63;
                sheetJson.sheetHead.AbType.left = 0;
                sheetJson.sheetHead.AbType.width = 46;
                sheetJson.sheetHead.AbType.height = 23;
              }
            }
          } else {
            // 如果存在装订线 直接删除
            if (sheetJson.gutter) {
              delete sheetJson.gutter;
            }
            sheetJson.sheetHead.height = 50;
            // 如果是有条形码的情况
            if (this.sheetHeader.indexOf("2") != -1) {
              sheetJson.sheetHead.height = 74;
              sheetJson.sheetHead.barCode = {
                left: 138.56,
                top: 22.5,
                width: 56,
                height: 25,
                nameStr: "条形码",
              };
              // 如果是没有考号的情况
              if (this.sheetHeader.indexOf("1") == -1) {
                sheetJson.sheetHead.warning.width = 83;
                sheetJson.sheetHead.warning.height = 32;
                sheetJson.sheetHead.warning.top = 13;
                sheetJson.sheetHead.warning.left = 48;

                //
                sheetJson.sheetHead.Absent.height = 25;
                sheetJson.sheetHead.Absent.width = 35;
                sheetJson.sheetHead.Absent.top = 47;
                sheetJson.sheetHead.Absent.left = 0;
                //
                sheetJson.sheetHead.barCode = {
                  left: 75,
                  top: 47,
                  width: 56,
                  height: 25,
                  nameStr: "条形码",
                };
                // 如果是没有AB卷
                if (this.sheetHeader.indexOf("3") == -1) {
                  //
                  sheetJson.sheetHead.Absent.height = 25;
                  sheetJson.sheetHead.Absent.width = 63;
                  sheetJson.sheetHead.Absent.top = 47;
                  sheetJson.sheetHead.Absent.left = 0;
                  //
                  sheetJson.sheetHead.warning.width = 81.5;
                  sheetJson.sheetHead.warning.height = 32;
                  sheetJson.sheetHead.warning.top = 13;
                  sheetJson.sheetHead.warning.left = 48;
                  //
                  sheetJson.sheetHead.barCode = {
                    left: 65,
                    top: 47,
                    width: 65,
                    height: 25,
                    nameStr: "条形码",
                  };

                  delete sheetJson.sheetHead.AbType;
                } else {
                  //
                  sheetJson.sheetHead.AbType.top = 47;
                  sheetJson.sheetHead.AbType.left = 37;
                  sheetJson.sheetHead.AbType.width = 36;
                  sheetJson.sheetHead.AbType.height = 25;
                }
              } else {
                // 有考号的情况
                sheetJson.sheetHead.height = 97;
                sheetJson.sheetHead.studentId = {
                  left: 58,
                  top: 46,
                  width: 72,
                  height: 49,
                  nameStr: "考号",
                };
                //
                sheetJson.sheetHead.warning.width = 56;
                sheetJson.sheetHead.warning.height = 49;
                sheetJson.sheetHead.warning.top = 46;
                sheetJson.sheetHead.warning.left = 0;
                sheetJson.sheetHead.warning.content = `<div style='height: 18mm; position: relative'><p><strong><span>注意事项：</span></strong></p><p><span style='line-height: 1.3'>1.答题前，考生先将自己的姓名、准考证号码填写清楚。</span></p><p><span style='line-height: 1.3'>2.选择题必须使用2B铅笔填涂且按正确填涂方式填涂：<img src='${require("@/static/sheet/fullfilling.png")}' style="margin:0 3px;vertical-align: text-bottom;"> 非选择题必须使用0.5毫米黑色字迹的签字笔书写，字体工整，笔迹清晰。</span></p><p><span style="line-height: 1.3">3.请按照题号顺序在答题区域内作答，超出答题区域书写答案无效。</span></p></div>`;

                //
                sheetJson.sheetHead.barCode.width = 54;
                sheetJson.sheetHead.barCode.height = 32;
                sheetJson.sheetHead.barCode.top = 13;
                sheetJson.sheetHead.barCode.left = 76;
                //
                sheetJson.sheetHead.Absent.height = 15;
                sheetJson.sheetHead.Absent.width = 28;
                sheetJson.sheetHead.Absent.top = 13;
                sheetJson.sheetHead.Absent.left = 47;

                // 如果是没有AB卷
                if (this.sheetHeader.indexOf("3") == -1) {
                  //
                  sheetJson.sheetHead.Absent.height = 32;
                  sheetJson.sheetHead.Absent.width = 28;
                  sheetJson.sheetHead.Absent.top = 13;
                  sheetJson.sheetHead.Absent.left = 47;
                  //
                  delete sheetJson.sheetHead.AbType;
                } else {
                  //
                  sheetJson.sheetHead.AbType.top = 29;
                  sheetJson.sheetHead.AbType.left = 47;
                  sheetJson.sheetHead.AbType.width = 28;
                  sheetJson.sheetHead.AbType.height = 16;
                }
              }
              sheetJson.sheetHead.studentInfo = {
                left: 0,
                top: 13,
                width: 46,
                height: 32,
                nameStr: "学生信息",
              };
              sheetJson.sheetHead.studentInfo.content =
                "<p style='margin-bottom:3mm'>考号<span style='line-height:2'><u>\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;</u></span><span></span></span></p>" +
                "<p style='margin-bottom:3mm'>姓名<span style='line-height:2'><u>\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;</u></span><span></span></p>" +
                "<p>班级<span style='line-height:2'><u>\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;</u></span><span></span></p>";
            } else {
              sheetJson.sheetHead.height = 98;
              sheetJson.sheetHead.studentId = {
                left: 58,
                top: 47,
                width: 72,
                height: 49,
                nameStr: "考号",
              };
              //

              sheetJson.sheetHead.warning.width = 82;
              sheetJson.sheetHead.warning.height = 32;
              sheetJson.sheetHead.warning.top = 13;
              sheetJson.sheetHead.warning.left = 48;

              //
              sheetJson.sheetHead.Absent.height = 20;
              sheetJson.sheetHead.Absent.width = 56;
              sheetJson.sheetHead.Absent.top = 47;
              sheetJson.sheetHead.Absent.left = 0;

              // 如果是没有AB卷
              if (this.sheetHeader.indexOf("3") == -1) {
                //
                sheetJson.sheetHead.Absent.height = 6;
                sheetJson.sheetHead.Absent.width = 56;
                sheetJson.sheetHead.Absent.top = 68;
                sheetJson.sheetHead.Absent.left = 0;
                //

                sheetJson.sheetHead.studentInfo = {
                  left: 0,
                  top: 13,
                  width: 130,
                  height: 11,
                  nameStr: "学生信息",
                };
                sheetJson.sheetHead.studentInfo.content =
                  "<p><br/>考号<span style='line-height:2'><u>\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;</u></span><span></span>\x26#12288;姓名<span style='line-height:2'><u>\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;</u></span><span></span>\x26#12288;班级<span style='line-height:2'><u>\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;</u></span><span></span></p>";
                //
                sheetJson.sheetHead.warning.width = 56;
                sheetJson.sheetHead.warning.height = 42;
                sheetJson.sheetHead.warning.top = 24;
                sheetJson.sheetHead.warning.left = 0;
                sheetJson.sheetHead.warning.content = `<div style='height: 18mm; position: relative'><p><strong><span>注意事项：</span></strong></p><p><span style='line-height: 1.3'>1.答题前，考生先将自己的姓名、准考证号码填写清楚。</span></p><p><span style='line-height: 1.3'>2.选择题必须使用2B铅笔填涂且按正确填涂方式填涂：<img src='${require("@/static/sheet/fullfilling.png")}' style="margin:0 3px;vertical-align: text-bottom;"> 非选择题必须使用0.5毫米黑色字迹的签字笔书写，字体工整，笔迹清晰。</span></p><p><span style="line-height: 1.3">3.请按照题号顺序在答题区域内作答，超出答题区域书写答案无效。</span></p></div>`;

                sheetJson.sheetHead.studentId.top = 24;
                sheetJson.sheetHead.height = 76;
                delete sheetJson.sheetHead.AbType;
              } else {
                //
                sheetJson.sheetHead.AbType.top = 69;
                sheetJson.sheetHead.AbType.left = 0;
                sheetJson.sheetHead.AbType.width = 56;
                sheetJson.sheetHead.AbType.height = 27;
                sheetJson.sheetHead.studentInfo = {
                  left: 0,
                  top: 13,
                  width: 46,
                  height: 32,
                  nameStr: "学生信息",
                };
                sheetJson.sheetHead.studentInfo.content =
                  "<p style='margin-bottom:3mm'>考号<span style='line-height:2'>\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;</u></span><span></span></p> " +
                  "<p style='margin-bottom:3mm'>姓名<span style='line-height:2'>\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;</u></span><span></span></p>" +
                  "<p>班级<span style='line-height:2'>\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;</u></span><span></span></p>";
              }
            }
          }
        }
      }
      if (this.GET_PRODUCTION && this.GET_PRODUCTION.questionItem) {
        sheetJson.questionItem = this.GET_PRODUCTION.questionItem;
      }

      sheetJson.frontPaper.firstContent.height =
        261 - sheetJson.sheetHead.height;
      sheetJson.frontPaper.firstContent.residueHeight =
        261 - sheetJson.sheetHead.height;
      sheetJson.frontPaper.firstContent.defaultHeight =
        261 - sheetJson.sheetHead.height;
      if (sheetJson.sheetHead.studentId) {
        sheetJson.sheetHead.studentId.studentIdNum = 12;
      }
      if (sheetJson.gutter) {
        sheetJson.frontPaper.firstContent.left = 42;
      } else {
        sheetJson.frontPaper.firstContent.left = 10;
      }

      sheetJson.sheetHeader = this.sheetHeader;
      if (this.sheetHeader.indexOf("5") == -1) {
        delete sheetJson.sheetHead.warning;
      }
      // console.log(this.sheetHeader);
      // console.log(sheetJson.sheetHead);
      // sheetJson = Object.assign({}, sheetJson);
      this.$store.commit("SEVA_PRODUCTION", sheetJson);
      this.$store.commit("SET_PRODUCTION", sheetJson);
      // setTimeout(() => {
      //   this.$refs.productionSheet.onloadHeader();
      // }, 500);

      // console.log(sheetJson);
    },
    // 根据返回的数据 设置用户基础信息
    setSheetMsg() {
      let array = ["4396", "6", "7", "5"];
      this.sheetHeader = this.examInformation.sheetHeader.split(",");
      this.sheetHeader = this.sheetHeader.concat(array);
      this.$store.commit("SET_SHEET_HEADER", this.sheetHeader);

      // 如果不存在json 就说明用户没有 保存过答题卡  基础信息设置就根据用户选择是创建
      if (
        !this.sheetInformation.contentJson ||
        this.sheetInformation.contentJson.length < 10
      ) {
        this.hearSetJson();
      } else {
        let Base64 = require("js-base64").Base64;
        let html = Base64.decode(this.sheetInformation.contentJson);
        // console.log(html);
        let sheetJson = JSON.parse(html);
        // console.log(sheetJson);
        if (sheetJson.sheetHeader) {
          let array = sheetJson.sheetHeader;
          // sheetJson.isCode = true;    sheetJson.paperCnt = false;
          if (sheetJson.isCode) {
            array.push("9");
          }
          if (sheetJson.paperCnt) {
            array.push("8");
          }
          array = [...new Set(array)];
          // console.log(array);
          this.sheetHeader = array;
          this.$store.commit("SET_SHEET_HEADER", this.sheetHeader);
        }

        // console.log(sheetJson);
        // let sheetJson = this.sheetInformation.contentJson;
        this.$store.commit("SEVA_PRODUCTION", sheetJson);
        // 如果存在  就根据返回的数据设置html
      }
    },
    // 获取试题题号是否存在，,;；
    getQuestion() {
      let examQuestionArr = [];
      if (!this.GET_PRODUCTION.questionItem) return examQuestionArr;
      this.GET_PRODUCTION.questionItem.map((item) => {
        if (item.type == 5) {
          let examQuestionNumber = item.questionNum.toString();
          if (
            examQuestionNumber.indexOf(",") != -1 ||
            examQuestionNumber.indexOf(";") != -1 ||
            examQuestionNumber.indexOf("，") != -1 ||
            examQuestionNumber.indexOf("；") != -1
          ) {
            // console.log(examQuestionNumber);
            examQuestionArr.push(examQuestionNumber);
          }
        }
        if (!item.questionOrderList) return;
        item.questionOrderList.map((index) => {
          // console.log(index);
          if (index.type == 3) {
            let examQuestionNumber = index.questionNum.toString();
            if (
              examQuestionNumber.indexOf(",") != -1 ||
              examQuestionNumber.indexOf(";") != -1 ||
              examQuestionNumber.indexOf("，") != -1 ||
              examQuestionNumber.indexOf("；") != -1
            ) {
              examQuestionArr.push(examQuestionNumber);
            }
          }
          if (index.type == 4) {
            index.nameList.map((i) => {
              // console.log(i);
              let examQuestionNumber = i.questionNum.toString();
              if (
                examQuestionNumber.indexOf(",") != -1 ||
                examQuestionNumber.indexOf(";") != -1 ||
                examQuestionNumber.indexOf("，") != -1 ||
                examQuestionNumber.indexOf("；") != -1
              ) {
                examQuestionArr.push(examQuestionNumber);
              }
            });
          }
        });
      });
      return examQuestionArr;
    },
    // 保存答题卡
    saveExampapersheetcontent() {
      let data = {
        id: this.sheetInformation.id,
        sheetUuid: this.sheetInformation.sheetUuid,
        // urls: this.frontSheet + "，" + this.blankSheet,
      };

      let Base64 = require("js-base64").Base64;

      let Array = this.getQuestion();
      // console.log(this.GET_PRODUCTION);
      if (Array.length > 0) {
        const h = this.$createElement;
        this.$msgbox({
          title: "题号异常提示",
          message: h("p", null, [
            h("span", { style: "color: red" }, Array.join(" ") + "题"),
            h("span", null, "题号存在"),
            h("span", { style: "color: red" }, "；，, ;"),
            h("span", null, "等符号，请修改后重试 "),
          ]),
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(() => {})
          .catch(() => {});
        this.btnLoading = false;
        this.btnDisabled = false;
        return;
      }

      let frontSheet =
        `<div id='front-sheet' class='sheet-content front-sheet${
          (this.GET_PRODUCTION.paperType == 1 ? " A4" : "") +
          (this.GET_PRODUCTION.paperType == 2 ? " A3" : "") +
          (this.GET_PRODUCTION.columnCount == 2 &&
          this.GET_PRODUCTION.paperType == 2
            ? " A3-2"
            : "") +
          (this.GET_PRODUCTION.columnCount == 3 &&
          this.GET_PRODUCTION.paperType == 2
            ? " A3-3"
            : "") +
          (this.GET_PRODUCTION.paperType == 3 ? " B4" : "") +
          (this.GET_PRODUCTION.gutter ? " gutter-style" : "")
        }' style='${"width:" + this.GET_PRODUCTION.paper.width + "mm"}'>` +
        document.getElementById("front-sheet").innerHTML.replace(/"/g, "'") +
        "</div>";
      let blankSheet =
        `<div id='blank-sheet' class='sheet-content blank-sheet ${
          (this.GET_PRODUCTION.paperType == 1 ? " A4" : "") +
          (this.GET_PRODUCTION.paperType == 2 ? " A3" : "") +
          (this.GET_PRODUCTION.columnCount == 2 &&
          this.GET_PRODUCTION.paperType == 2
            ? " A3-2"
            : "") +
          (this.GET_PRODUCTION.columnCount == 3 &&
          this.GET_PRODUCTION.paperType == 2
            ? " A3-3"
            : "") +
          (this.GET_PRODUCTION.paperType == 3 ? " B4" : "") +
          (this.GET_PRODUCTION.gutter ? " gutter-style" : "")
        }' style='${"width:" + this.GET_PRODUCTION.paper.width + "mm"}'>` +
        document.getElementById("blank-sheet").innerHTML.replace(/"/g, "'") +
        "</div>";

      frontSheet = frontSheet.replace(/&nbsp;/g, "\x26#12288;");
      blankSheet = blankSheet.replace(/&nbsp;/g, "\x26#12288;");
      frontSheet = frontSheet.replace(/<font/g, "<span");
      blankSheet = blankSheet.replace(/<font/g, "<span");
      frontSheet = frontSheet.replace(/font>/g, "span>");
      blankSheet = blankSheet.replace(/font>/g, "span>");

      let sheets = [encodeURIComponent(frontSheet)];
      // Front only
      this.GET_PRODUCTION.frontOnly = true;
      // console.log(frontSheet);
      if (this.GET_PRODUCTION.blankPaper.firstContent.questionItem.length > 0) {
        sheets.push(encodeURIComponent(blankSheet));
        this.GET_PRODUCTION.frontOnly = false;
      }
      // console.log(this.GET_PRODUCTION);
      if (this.GET_PRODUCTION.paperType == 1) {
        this.GET_PRODUCTION.width = 1180;
        this.GET_PRODUCTION.height = 1637;
      } else {
        this.GET_PRODUCTION.width = 2337;
        this.GET_PRODUCTION.height = 1637;
      }
      if (!this.GET_PRODUCTION.questionItem) {
        this.GET_PRODUCTION.questionItem = [];
      }
      // console.log(this.GET_PRODUCTION.questionItem);
      let arr = this.GET_PRODUCTION.questionItem.map((item) => {
        if (item.type == 3 || item.type == 4) {
          item.questionOrderList.map((index) => {
            index.content = index.content.replace(
              /"><\/span>/g,
              `">\x26#12288;</span>`
            );
            index.extendArr.map((i) => {
              i.content = i.content.replace(
                /"><\/span>/g,
                `">\x26#12288;</span>`
              );
            });
          });
        }
        if (item.type == 6) {
          // console.log(item);
          item.content = item.content.replace(
            /"><\/span>/g,
            `">\x26#12288;</span>`
          );
          // if (!item.extendArr) {
          //   return;
          // }
          // item.extendArr.map((i) => {
          //   i.content = i.content.replace(
          //     /"><\/span>/g,
          //     `">\x26#12288;</span>`
          //   );
          // });
        }
        return item;
      });
      // console.log(arr);
      // if (arr) return;
      this.GET_PRODUCTION.questionItem = arr;
      data.contentJson = Base64.encode(JSON.stringify(this.GET_PRODUCTION));
      data.contentHtml = JSON.stringify({
        sheets: sheets,
        dpi: this.GET_PRODUCTION.paperType == 1 ? 140 : 140,
        css: encodeURIComponent(cssJson + fontBorder + allcss),
      });
      // console.log(this.GET_PRODUCTION);
      // setTimeout(() => {
      //   this.btnLoading = false;
      //   this.btnDisabled = false;
      // }, 20000);
      saveExampapersheetcontent(data)
        .then(() => {
          this.$message({
            message: "保存成功！",
            type: "success",
            showClose: true,
          });
          this.exampapersheetcontent();
          this.btnLoading = false;
          this.btnDisabled = false;
        })
        .catch(() => {
          this.btnLoading = false;
          this.btnDisabled = false;
        });
    },
    // 通过UUID查询答题卡内容
    exampapersheetcontent() {
      exampapersheetcontent(this.$route.query.sheetId).then((res) => {
        this.sheetInformation = res.data.data;
        this.setSheetMsg();
      });
    },
    // 获取答题卡基本信息
    getExamPaperSheet() {
      getExamPaperSheet(this.$route.query.sheetId).then((res) => {
        this.examInformation = res.data.data;
        this.$store.commit("SET_EXAM_INFORMATION", this.examInformation);
        this.exampapersheetcontent();
      });
    },
    // 获取答题卡标注信息
    exampapersheetmarkList() {
      let data = {
        sheetUuid: this.$route.query.sheetId,
      };
      exampapersheetmarkList(data).then((res) => {
        this.sheetMarkList = res.data.data;
      });
    },
    getCssBlock() {
      // const cssBlock = document.styleSheets;
      const cssBlock = document.styleSheets;
      // console.log(document.styleSheets);
      const styleData = [...cssBlock].reverse().find((item) => {
        //
        let cssRules = JSON.parse(JSON.stringify(item)).cssRules;
        // console.log(cssRules);
        if (cssRules.length > 1) {
          // return [...item.cssRules].find((rule) => {
          //   return [".blank-sheet"].includes(rule.selectorText);
          // });
        }
      });
      // console.log(styleData);
      return styleData.ownerNode.innerText;
    },
  },
};
</script>

<style lang="scss" scoped>
.sheetEdit {
  padding: 60px 0 40px;
  background: #f4f4f4;
  // letter-spacing: 0;
  // line-height: 12px;
  .sheet-title {
    margin: 24px 0;
    text-align: center;
  }
}
</style>
<style lang="scss">
body {
  line-height: unset;
}
.top-msg-box {
  -moz-user-select: none;
  /* Safari 和 欧朋 */
  -webkit-user-select: none;
  /* IE10+ and Edge */
  -ms-user-select: none;
  /* Standard syntax 标准语法(谷歌) */
  user-select: none;
}
.btm-msg-box {
  -moz-user-select: none;
  /* Safari 和 欧朋 */
  -webkit-user-select: none;
  /* IE10+ and Edge */
  -ms-user-select: none;
  /* Standard syntax 标准语法(谷歌) */
  user-select: none;
}
.edit-question-input {
  .el-input__inner {
    padding-right: 50px;
  }
}
.student-id,
.score-box-table,
.completion-question-content,
.objective-question-content,
.gutter-box {
  -moz-user-select: none;
  /* Safari 和 欧朋 */
  -webkit-user-select: none;
  /* IE10+ and Edge */
  -ms-user-select: none;
  /* Standard syntax 标准语法(谷歌) */
  user-select: none;
}
.add-table {
  select {
    height: 24px;
    padding: 0 5px;
    border: 1px solid #d1cbcb;
    border-radius: 3px;
    margin: 5px;
  }
}
.A3,
.A4 {
  margin: 0 auto;
}
.A4-T {
  width: 214mm;
}
.A3-T {
  width: 424mm;
}
.A4-T,
.A3-T {
  margin: 0 auto;

  .el-button {
    font-size: 16px;
  }
}
// .question-group-name {
//   display: none;
// }
.question-group-name {
  font-size: 12px;
  color: red;
  position: absolute;
  top: -6px;
  left: 8px;
}
.sheet-content.blank-sheet {
  margin-top: 5mm;
}
.sheet-content {
  .sheet-title {
    font-size: 16px;
    border-bottom: 1px solid lightgrey;
    width: 100%;
  }
}
</style>
<style lang="scss">
@import "./css/sheet.scss";
</style>
