<template>
  <div class="errorSheet">
    <el-dialog
      title="答题卡信息异常提示"
      :visible.sync="dialogVisible"
      width="400px"
    >
      <div v-if="sussScore.indexOf(score) == -1" class="error-tips-box">
        分数异常:当前分数为<span>{{ score }}</span
        >分,分数为非常规分数。
      </div>
      <div v-if="errorItem.length > 0" class="error-tips-box">
        以下试题内容超出试题区域:
        <p v-for="(item, index) in errorItem" :key="index">{{ item }}</p>
        <div>
          试题内容超出试题区域可能会导致生成的<span>答题卡多页</span>或者<span>客观题识别失败</span>等。
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit()"> 忽略并保存 </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "ErrorSheet",
  data() {
    return {
      dialogVisible: false,
      sussScore: [],
      score: 0,
      errorItem: "",
    };
  },
  created() {},
  methods: {
    init(score, sussScore, errorItem) {
      this.score = score;
      this.sussScore = sussScore;
      this.errorItem = errorItem;
      this.dialogVisible = true;
    },
    submit() {
      this.dialogVisible = false;
      this.$parent.downImg();
    },
  },
};
</script>
<style lang="scss" scoped>
.errorSheet {
  line-height: 1.5;
  .error-tips-box {
    margin-bottom: 12px;
    span {
      color: red;
    }
    p {
      font-size: 14px;
      color: #2474ed;
      line-height: 1.5;
    }
    div {
      font-size: 12px;

      line-height: 1.5;
    }
  }
}
</style>
