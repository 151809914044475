<template>
  <div v-if="productionData.paper" id="productionSheet" class="productionSheet">
    <!-- {{ productionData.sheetHead.warning.content }} -->
    <div
      id="front-sheet"
      class="sheet-content front-sheet"
      :style="{ width: productionData.paper.width + 'mm' }"
      :class="{
        A4: examInformation.paperType == 1,
        A3: examInformation.paperType == 2,
        'A3-2':
          examInformation.columnCount == 2 && examInformation.paperType == 2,
        'A3-3':
          examInformation.columnCount == 3 && examInformation.paperType == 2,
        B4: examInformation.paperType == 3,
        'gutter-style': productionData.gutter,
      }"
    >
      <!-- 装订线 -->
      <div v-if="productionData.gutter" class="gutter-box gutter-box-front">
        <div
          class="gutter-bar-code"
          :style="getKeyStyle('gutterBarCode', 'gutter')"
        >
          贴条形码区
        </div>
        <!-- 学生基本信息 -->
        <div class="gutter-info" :style="getKeyStyle('gutterInfo', 'gutter')">
          <p>
            考号
            <span style="font-size: 24px"><b></b>&nbsp; </span>
            姓名
            <span style="font-size: 24px"><b></b>&nbsp; </span>
            班级
            <span style="font-size: 24px"><b></b> </span>
          </p>
        </div>
      </div>
      <!-- 答题卡基本信息 试题  考号  各种信息显示的盒子 -->
      <div
        v-if="productionData.paperContent"
        class="front-sheet-content"
        :style="{
          width: productionData.paperContent.width + 'mm',
          left: productionData.frontPaper.firstContent.left + 'mm',
        }"
      >
        <!-- 顶部定位点 -->
        <div
          class="front-loction front-head-loction"
          :style="{ width: productionData.paperContent.width + 'mm' }"
        >
          <span></span>
          <span></span>
        </div>
        <!--  考号  各种信息显示的盒子 -->
        <div
          v-if="productionData.sheetHead"
          ref="frontHead"
          class="front-head"
          :style="{ height: productionData.sheetHead.height + 'mm' }"
        >
          <!-- 考试名称 -->
          <div
            ref="title"
            class="sheet-title"
            :style="{ height: productionData.sheetHead.title.height + 'mm' }"
            :class="{
              'red-color': productionData.isRed,
            }"
          >
            <ckeditor
              v-model="productionData.sheetHead.title.content"
              :style="{
                height: productionData.sheetHead.title.height + 'mm',
              }"
              class="CKEditor-style"
              type="inline"
              :config="editorConfig"
              :editor-url="editorUrl"
            ></ckeditor>
            <div
              class="hover-tips"
              @mousedown.stop="editMove($event, 'title')"
            ></div>
          </div>
          <!-- 学生信息 -->
          <div
            v-if="productionData.sheetHead.studentInfo"
            ref="studentInfo"
            class="border student-info"
            :style="getKeyStyle('studentInfo', 'sheetHead')"
            @mousedown="arrowMove($event, 'studentInfo')"
            @dblclick.stop="setEditor"
          >
            <ckeditor
              ref="studentInfoBox"
              v-model="productionData.sheetHead.studentInfo.content"
              :style="{
                height: productionData.sheetHead.studentInfo.height - 2 + 'mm',
              }"
              class="CKEditor-style"
              type="inline"
              :config="getConfig('studentInfoBox')"
              :read-only="editorType"
              :editor-url="editorUrl"
            ></ckeditor>
            <div
              class="hover-tips"
              @mousedown.stop="editMove($event, 'studentInfo')"
            ></div>
          </div>
          <!-- 缺考标记 -->
          <div
            v-if="productionData.sheetHead"
            ref="Absent"
            :class="{
              'red-color': productionData.isRed,
              'red-border': productionData.isRed,
            }"
            class="border Absent-box"
            :style="getKeyStyle('Absent', 'sheetHead')"
            @mousedown="arrowMove($event, 'Absent')"
          >
            <div
              :style="{
                'line-height': productionData.sheetHead.Absent.height + 'mm',
              }"
            >
              缺考
            </div>
            <div>
              <span
                class="border"
                :class="{
                  'red-border': productionData.isRed,
                }"
              ></span>
            </div>
            <div
              class="hover-tips"
              @mousedown.stop="editMove($event, 'Absent')"
            ></div>
          </div>
          <!-- Ab卷标记 -->
          <div
            v-if="productionData.sheetHead.AbType"
            ref="AbType"
            :class="{
              'red-color': productionData.isRed,
              'red-border': productionData.isRed,
            }"
            class="border AbType-box"
            :style="getKeyStyle('AbType', 'sheetHead')"
            @mousedown="arrowMove($event, 'AbType')"
          >
            <div
              :style="{
                'line-height':
                  productionData.sheetHead.AbType.height / 2 + 'mm',
              }"
            >
              <div>试卷A</div>
              <div>
                <span
                  class="border"
                  :class="{
                    'red-border': productionData.isRed,
                  }"
                ></span>
              </div>
            </div>
            <div
              :style="{
                'line-height':
                  productionData.sheetHead.AbType.height / 2 + 'mm',
              }"
            >
              <div
                :style="{
                  'line-height':
                    productionData.sheetHead.AbType.height / 2 + 'mm',
                }"
              >
                试卷B
              </div>
              <div>
                <span
                  class="border"
                  :class="{
                    'red-border': productionData.isRed,
                  }"
                ></span>
              </div>
            </div>
            <div
              class="hover-tips"
              @mousedown.stop="editMove($event, 'AbType')"
            ></div>
          </div>
          <!-- 注意事项 -->

          <div
            v-if="productionData.sheetHead && productionData.sheetHead.warning"
            ref="warning"
            class="border warning-box"
            :class="{
              'red-color': productionData.isRed,
              'red-border': productionData.isRed,
            }"
            :style="getKeyStyle('warning', 'sheetHead')"
            @mousedown="arrowMove($event, 'warning')"
            @dblclick.stop="setEditor"
          >
            <!-- <div v-html="productionData.sheetHead.warning.content"></div> -->
            <ckeditor
              ref="ckeditorKey"
              v-model="productionData.sheetHead.warning.content"
              :style="{
                height: productionData.sheetHead.warning.height - 4 + 'mm',
              }"
              class="CKEditor-style"
              type="inline"
              :config="getConfig('ckeditorKey')"
              :read-only="editorType"
              :editor-url="editorUrl"
            ></ckeditor>
            <div
              class="hover-tips"
              @mousedown.stop="editMove($event, 'warning')"
            ></div>

            <!-- content -->
          </div>
          <!-- 贴条形码区 -->
          <div
            v-if="productionData.sheetHead.barCode"
            class="bar-code"
            :class="{
              'red-color': productionData.isRed,
              'red-border': productionData.isRed,
            }"
            :style="getKeyStyle('barCode', 'sheetHead')"
            @mousedown="arrowMove($event, 'barCode')"
          >
            贴条形码区
          </div>
          <!-- 考号 -->
          <table
            v-if="productionData.sheetHead.studentId"
            class="student-id"
            :style="getKeyStyle('studentId', 'sheetHead')"
            :class="{
              'red-color': productionData.isRed,
              'red-border': productionData.isRed,
            }"
            @mousedown="arrowMove($event, 'studentId')"
          >
            <tr>
              <!--  -->
              <!-- <th class="student-id-title" :colspan="12"></th> -->

              <td
                class="student-id-title"
                :colspan="productionData.sheetHead.studentId.studentIdNum"
              >
                准考证号
              </td>
            </tr>
            <tr>
              <td
                v-for="(item, index) in productionData.sheetHead.studentId
                  .studentIdNum"
                :key="index"
                style="width: 6mm"
                class="student-nums"
                :class="{
                  'red-border': productionData.isRed,
                }"
              ></td>
            </tr>
            <tr v-for="(v, i) in 10" :key="i">
              <td
                v-for="(item, index) in productionData.sheetHead.studentId
                  .studentIdNum"
                :key="index"
                class="student-id-nums"
                :class="{
                  'red-border': productionData.isRed,
                }"
              >
                <!-- {{ i }} -->
                <div
                  :class="{
                    'red-border': productionData.isRed,
                  }"
                >
                  {{ i }}
                </div>
              </td>
            </tr>
          </table>
          <div class="ui-resizable-s" @mousedown="arrowHeight($event)"></div>
        </div>
        <template v-if="productionData.frontPaper">
          <questionsItem
            :class="{ 'paper-msg-cnt-one': productionData.paperCnt }"
            :question="productionData.frontPaper.firstContent"
          >
            <div
              v-if="productionData.paperCnt"
              class="btm-msg-box"
              :class="{
                'red-color': productionData.isRed,
              }"
            >
              请在各题目答题区域内作答，超出黑色矩形边框限定区域的答案无效
            </div>
          </questionsItem>
        </template>
        <!--底部定位点  -->
        <div
          class="front-loction front-foot-loction"
          :style="{ width: productionData.paperContent.width + 'mm' }"
        >
          <div class="page-mark">
            <div class="page-mark-list"></div>
            <div class="page-mark-list"></div>
            <div class="page-mark-list"></div>
            <div class="page-mark-list"></div>
          </div>
          <span></span>
          <p>第 1 页</p>
          <span></span>
        </div>
      </div>
      <!-- 第二栏 -->
      <div
        v-if="
          productionData.frontPaper && productionData.frontPaper.secondContent
        "
        class="front-sheet-content"
        :style="{
          width: productionData.paperContent.width + 'mm',
          left: productionData.frontPaper.secondContent.left + 'mm',
        }"
      >
        <!-- 顶部定位点 -->
        <div
          class="front-loction front-head-loction"
          :style="{ width: productionData.paperContent.width + 'mm' }"
        >
          <span></span>
          <div v-if="productionData.isCode" class="codeimg">
            <img
              src="https://static.wtjy.com/resource/document/%E5%AD%A6%E7%94%9FAPP%E4%B8%8B%E8%BD%BD_QRCODE.png"
              alt=""
            />
            <p class="code-exp">成绩查询：扫码下载文通云学生端APP</p>
          </div>
          <span></span>
        </div>
        <template v-if="productionData.frontPaper">
          <questionsItem
            :class="{ 'paper-msg-cnt': productionData.paperCnt }"
            :question="productionData.frontPaper.secondContent"
          >
            <div
              v-if="productionData.paperCnt"
              class="top-msg-box"
              :class="{
                'red-color': productionData.isRed,
              }"
            >
              请在各题目答题区域内作答，超出黑色矩形边框限定区域的答案无效
            </div>
            <div
              v-if="productionData.paperCnt"
              class="btm-msg-box"
              :class="{
                'red-color': productionData.isRed,
              }"
            >
              请在各题目答题区域内作答，超出黑色矩形边框限定区域的答案无效
            </div>
          </questionsItem>
        </template>
        <!--底部定位点  -->
        <div
          class="front-loction front-foot-loction"
          :style="{ width: productionData.paperContent.width + 'mm' }"
        >
          <span></span>
          <p>第 2 页</p>
          <span></span>
        </div>
      </div>
      <!-- 第三栏 -->
      <div
        v-if="
          productionData.frontPaper && productionData.frontPaper.thirdContent
        "
        class="front-sheet-content"
        :style="{
          width: productionData.paperContent.width + 'mm',
          left: productionData.frontPaper.thirdContent.left + 'mm',
        }"
      >
        <!-- 顶部定位点 -->
        <div
          class="front-loction front-head-loction"
          :style="{ width: productionData.paperContent.width + 'mm' }"
        >
          <span></span>
          <span></span>
        </div>
        <template v-if="productionData.frontPaper">
          <questionsItem
            :class="{ 'paper-msg-cnt': productionData.paperCnt }"
            :question="productionData.frontPaper.thirdContent"
          >
            <div
              v-if="productionData.paperCnt"
              class="top-msg-box"
              :class="{
                'red-color': productionData.isRed,
              }"
            >
              请在各题目答题区域内作答，超出黑色矩形边框限定区域的答案无效
            </div>
            <div
              v-if="productionData.paperCnt"
              class="btm-msg-box"
              :class="{
                'red-color': productionData.isRed,
              }"
            >
              请在各题目答题区域内作答，超出黑色矩形边框限定区域的答案无效
            </div>
          </questionsItem>
        </template>
        <!--底部定位点  -->
        <div
          class="front-loction front-foot-loction"
          :style="{ width: productionData.paperContent.width + 'mm' }"
        >
          <span></span>
          <p>第 3 页</p>
          <span></span>
        </div>
      </div>
    </div>
    <!-- 第二页 thirdContent -->
    <div
      id="blank-sheet"
      class="sheet-content blank-sheet"
      :style="{ width: productionData.paper.width + 'mm' }"
      :class="{
        A4: examInformation.paperType == 1,
        A3: examInformation.paperType == 2,
        'A3-2':
          examInformation.columnCount == 2 && examInformation.paperType == 2,
        'A3-3':
          examInformation.columnCount == 3 && examInformation.paperType == 2,
        B4: examInformation.paperType == 3,
        'gutter-style': productionData.gutter,
      }"
    >
      <!-- 答题卡基本信息 试题  考号  各种信息显示的盒子 -->

      <!-- 第一页 -->
      <div
        v-if="productionData.blankPaper"
        class="blank-sheet-content"
        :style="{
          width: productionData.paperContent.width + 'mm',
          left: productionData.blankPaper.firstContent.left + 'mm',
        }"
      >
        <!-- 顶部定位点 -->
        <div
          class="blank-loction blank-head-loction"
          :style="{ width: productionData.paperContent.width + 'mm' }"
        >
          <div
            v-if="productionData.isCode && examInformation.paperType == 1"
            class="codeimg"
          >
            <img
              src="https://static.wtjy.com/resource/document/%E5%AD%A6%E7%94%9FAPP%E4%B8%8B%E8%BD%BD_QRCODE.png"
              alt=""
            />
            <p class="code-exp">成绩查询：扫码下载文通云学生端APP</p>
          </div>
          <span></span>
          <span
            :style="{
              visibility: examInformation.columnCount == 3 ? 'hidden' : '',
            }"
          ></span>
        </div>
        <template v-if="productionData.blankPaper">
          <questionsItem
            :class="{ 'paper-msg-cnt': productionData.paperCnt }"
            :question="productionData.blankPaper.firstContent"
          >
            <div
              v-if="productionData.paperCnt"
              class="top-msg-box"
              :class="{
                'red-color': productionData.isRed,
              }"
            >
              请在各题目答题区域内作答，超出黑色矩形边框限定区域的答案无效
            </div>
            <div
              v-if="productionData.paperCnt"
              class="btm-msg-box"
              :class="{
                'red-color': productionData.isRed,
              }"
            >
              请在各题目答题区域内作答，超出黑色矩形边框限定区域的答案无效
            </div>
          </questionsItem>
        </template>
        <!--底部定位点  -->
        <div
          class="blank-loction blank-foot-loction"
          :style="{ width: productionData.paperContent.width + 'mm' }"
        >
          <div class="page-mark">
            <div class="page-mark-list"></div>
            <div class="page-mark-list"></div>
            <div class="page-mark-list"></div>
            <div class="page-mark-list"></div>
          </div>
          <span></span>
          <!-- examInformation.columnCount == 2 && examInformation.paperType == 2 -->
          <template v-if="examInformation.paperType == 1">
            <p>第 2 页</p>
          </template>
          <template
            v-if="
              examInformation.columnCount == 2 && examInformation.paperType == 2
            "
          >
            <p>第 3 页</p>
          </template>
          <template
            v-if="
              examInformation.columnCount == 3 && examInformation.paperType == 2
            "
          >
            <p>第 4 页</p>
          </template>

          <span
            :style="{
              visibility: examInformation.columnCount == 3 ? 'hidden' : '',
            }"
          ></span>
        </div>
      </div>
      <!-- 第二页 -->
      <div
        v-if="
          productionData.blankPaper && productionData.blankPaper.secondContent
        "
        class="blank-sheet-content"
        :style="{
          width: productionData.paperContent.width + 'mm',
          left: productionData.blankPaper.secondContent.left + 'mm',
        }"
      >
        <!-- 顶部定位点 -->
        <div
          class="blank-loction blank-head-loction"
          :style="{ width: productionData.paperContent.width + 'mm' }"
        >
          <span></span>
          <span></span>
        </div>
        <template v-if="productionData.blankPaper">
          <questionsItem
            :class="{ 'paper-msg-cnt': productionData.paperCnt }"
            :question="productionData.blankPaper.secondContent"
          >
            <div
              v-if="productionData.paperCnt"
              class="top-msg-box"
              :class="{
                'red-color': productionData.isRed,
              }"
            >
              请在各题目答题区域内作答，超出黑色矩形边框限定区域的答案无效
            </div>
            <div
              v-if="productionData.paperCnt"
              class="btm-msg-box"
              :class="{
                'red-color': productionData.isRed,
              }"
            >
              请在各题目答题区域内作答，超出黑色矩形边框限定区域的答案无效
            </div>
          </questionsItem>
        </template>
        <!--底部定位点  -->
        <div
          class="blank-loction blank-foot-loction"
          :style="{ width: productionData.paperContent.width + 'mm' }"
        >
          <span></span>
          <!-- <p>第 2 页</p> -->
          <template
            v-if="
              examInformation.columnCount == 2 && examInformation.paperType == 2
            "
          >
            <p>第 4 页</p>
          </template>
          <template
            v-if="
              examInformation.columnCount == 3 && examInformation.paperType == 2
            "
          >
            <p>第 5 页</p>
          </template>
          <span></span>
        </div>
      </div>
      <!-- 第三页 -->
      <div
        v-if="
          productionData.blankPaper && productionData.blankPaper.thirdContent
        "
        class="blank-sheet-content"
        :style="{
          width: productionData.paperContent.width + 'mm',
          left: productionData.blankPaper.thirdContent.left + 'mm',
        }"
      >
        <!-- 顶部定位点 -->
        <div
          class="blank-loction blank-head-loction"
          :style="{ width: productionData.paperContent.width + 'mm' }"
        >
          <span style="visibility: hidden"></span>
          <span></span>
        </div>
        <template v-if="productionData.blankPaper">
          <questionsItem
            :class="{ 'paper-msg-cnt': productionData.paperCnt }"
            :question="productionData.blankPaper.thirdContent"
          >
            <div
              v-if="productionData.paperCnt"
              class="top-msg-box"
              :class="{
                'red-color': productionData.isRed,
              }"
            >
              请在各题目答题区域内作答，超出黑色矩形边框限定区域的答案无效
            </div>
            <div
              v-if="productionData.paperCnt"
              class="btm-msg-box"
              :class="{
                'red-color': productionData.isRed,
              }"
            >
              请在各题目答题区域内作答，超出黑色矩形边框限定区域的答案无效
            </div>
          </questionsItem>
        </template>
        <!--底部定位点  -->
        <div
          class="blank-loction blank-foot-loction"
          :style="{ width: productionData.paperContent.width + 'mm' }"
        >
          <span style="visibility: hidden"></span>
          <p>第 6 页</p>
          <span></span>
        </div>
      </div>
      <!-- 装订线 -->
      <div v-if="productionData.gutter" class="gutter-box gutter-box-blank">
        <span>密&nbsp;封&nbsp;线&nbsp;内&nbsp;请&nbsp;勿&nbsp;作&nbsp;答</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { choiceQuestionTypeList, optionArray } from "@/core/util/sheetPaper";
import { unitConversion } from "@/core/util/util";
import CKEditor from "ckeditor4-vue";
import questionsItem from "./questionsItem";
export default {
  name: "ProductionSheet",
  components: { ckeditor: CKEditor.component, questionsItem: questionsItem },
  data() {
    return {
      editorUrl: "./ckeditor/ckeditor.js" + new Date().getTime(),
      choiceQuestionTypeList: choiceQuestionTypeList,
      optionArray: optionArray(),
      // 装订线的json
      // gutter: gutter,
      editorType: true,
      headerLoad: 1,
      examInformation: {},
      // 页面渲染的json
      productionData: {},
      // cditor 配置
      editorConfig: {
        title: false,
        language: "zh-cn",
        allowedContent: true,

        removeButtons: "Undo",
        extraPlugins: "font,myImage,longLine,shortline,lineheight",
        coreStylesUnderline: {
          element: "span",
          attributes: { class: "Underline" },
        },
        toolbar: [
          {
            name: "basicstyles",
            items: [
              // "basicstyles",
              // "Underline",
              // "Bold",
              "Italic",
              // "Strike",
              // "TextColor",
              // "BGColor",
              // "Font",
              // "FontSize",
              // "MyImage",
              // "quicktable",
              // "LongLine",
              // "Shortline",
              "lineheight",
            ],
          },
          { name: "styles", items: ["Format", "FontSize"] },
          {
            name: "align",
            items: ["JustifyLeft", "JustifyCenter", "JustifyRight"],
          },
          // { name: "styles", items: ["styles", "Underline"] },
          // {
          //   name: "MyToolbar",
          //   items: ["styles", "Underline"],
          // },
        ],
      },
      editorConfigWarning: {
        title: false,
        language: "zh-cn",
        toolbarCanCollapse: false,
        toolbarStartupExpanded: false,
        allowedContent: true,
        removeButtons: "Undo",
        extraPlugins: "font,myImage,longLine,shortline,lineheight",
        coreStylesUnderline: {
          element: "span",
          attributes: { class: "Underline" },
        },
        toolbar: [
          {
            name: "basicstyles",
            items: ["Italic", "FontSize", "lineheight", "Shortline"],
          },
          {
            name: "align",
            items: ["JustifyLeft", "JustifyCenter", "JustifyRight"],
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["GET_EXAM_INFORMATION", "GET_PRODUCTION"]),
  },
  watch: {
    GET_EXAM_INFORMATION: {
      deep: true,
      handler() {
        this.examInformation = Object.assign({}, this.GET_EXAM_INFORMATION);
      },
    },
    // 获取答题卡json数据组装基本数据
    GET_PRODUCTION: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.productionData = Object.assign({}, this.GET_PRODUCTION);
        });
      },
    },
  },
  created() {
    this.examInformation = Object.assign({}, this.GET_EXAM_INFORMATION);
    this.productionData = this.GET_PRODUCTION;
    // 鼠标点击页面事件
    window.addEventListener("mousedown", this.handleonmousedown);
  },
  mounted() {
    // this.$nextTick(() => {
    //   console.log(window.CKEDITOR);
    // });
    // CKEditor.component.on("instanceReady", () => {
    //   CKEditor.setReadOnly(false);
    // });
    // console.log(CKEditor.component.inline());
    // console.log(CKEditor.component.instances["ckeditorKey"]);
    // CKEditor.setReadOnly(false); ckeditorKey
    // CKEditor.component.mounted.setReadOnly(true);
  },
  destroyed() {
    window.removeEventListener("mousedown", this.handleonmousedown);
  },
  methods: {
    handleonmousedown($event) {
      let str = "";
      const urlPath =
        $event.path || ($event.composedPath && $event.composedPath());
      urlPath.map((index) => {
        str = str + index.className;
      });
      if (
        str.indexOf("warning-box") == -1 &&
        str.indexOf("cke_editor") == -1 &&
        str.indexOf("student-info") == -1
      ) {
        this.editorType = true;
      }

      let practice_item = document.getElementsByClassName("cke_editable");
      let practice_item_length = practice_item.length;
      for (let i = 0; i < practice_item_length; i++) {
        if (practice_item[i].getAttribute("contenteditable") == "true") {
          practice_item[i].style.cursor = "text";
        } else {
          practice_item[i].style.cursor = "move";
        }
      }
    },
    setEditor() {
      this.editorType = false;
      let practice_item = document.getElementsByClassName("cke_editable");
      let practice_item_length = practice_item.length;
      for (let i = 0; i < practice_item_length; i++) {
        if (practice_item[i].getAttribute("contenteditable") == "true") {
          practice_item[i].style.cursor = "text";
        } else {
          practice_item[i].style.cursor = "move";
        }
      }
    },
    // 重定义editor空格键
    bindHeadEditor(b) {
      this.$nextTick(() => {
        if (!this.$refs[b]) {
          return;
        }
        var g = this.$refs[b].instance;
        g.on("key", function (a) {
          if (32 == a.data.keyCode) return g.insertHtml("\x26#12288;"), !1;
        });
      });
    },
    getConfig(id) {
      let editorConfigWarning = JSON.parse(
        JSON.stringify(this.editorConfigWarning)
      );
      setTimeout(() => {
        // 重定义editor空格键
        this.bindHeadEditor(id);
      }, 1000);
      return editorConfigWarning;
    },
    onloadHeader() {
      // console.log("111111111");
      // console.log(this.productionData);
      this.headerLoad = new Date().getTime();
    },
    arrowHeight(e) {
      let _this = this;

      let elH = this.$refs.frontHead.offsetHeight;

      let elT = new unitConversion().pxConversionMm(
        this.$refs.frontHead.offsetTop
      );
      let h = this.productionData.paperContent.height;
      let y = e.clientY;
      let height;
      document.onmousemove = function (e) {
        // 根据鼠标位置计算出移动的距离
        // let width = new unitConversion().pxConversionMm(elW - (x - e.clientX));
        height = new unitConversion().pxConversionMm(elH - (y - e.clientY));
        // 禁止越界 start
        // console.log(height);
        if (height < 5) {
          height = 5;
        }

        if (height + elT > h) {
          height = h - elT;
        }

        _this.$refs.frontHead.style.height = height + "mm";
      };
      document.onmouseup = function () {
        document.onmousemove = null;
        document.onmouseup = null;
        _this.productionData.sheetHead.height = height;
        _this.productionData.frontPaper.firstContent.height =
          261 - _this.productionData.sheetHead.height;
        _this.productionData.frontPaper.firstContent.residueHeight =
          261 - _this.productionData.sheetHead.height;
        _this.productionData.frontPaper.firstContent.defaultHeight =
          261 - _this.productionData.sheetHead.height;
        _this.$store.commit("SEVA_PRODUCTION", _this.productionData);
        _this.$store.commit("SET_PRODUCTION", _this.productionData);
      };
    },
    // 改变元素大小
    editMove(e, key) {
      let _this = this;
      let el = this.$refs[key];
      // 元素大小
      let elW = this.$refs[key].offsetWidth;

      let elH = this.$refs[key].offsetHeight;

      // 鼠标位置
      let x = e.clientX;
      let y = e.clientY;
      // 元素位置
      let elL = new unitConversion().pxConversionMm(this.$refs[key].offsetLeft);

      let elT = new unitConversion().pxConversionMm(this.$refs[key].offsetTop);
      // let
      // 窗口大小
      let w = _this.productionData.paperContent.width;
      let h = _this.productionData.sheetHead.height;
      document.onmousemove = function (e) {
        // 根据鼠标位置计算出移动的距离
        let width = new unitConversion().pxConversionMm(elW - (x - e.clientX));
        let height = new unitConversion().pxConversionMm(elH - (y - e.clientY));
        // 禁止越界 start
        if (width < 15) {
          width = 15;
        }
        if (height < 5) {
          height = 5;
        }
        // console.log(w);
        // console.log(elL);
        // console.log(width);
        // console.log(elT);
        // console.log(h);
        if (width + elL > w) {
          width = w - elL;
        }
        if (height + elT > h) {
          height = h - elT;
        }
        // 禁止越界 end
        el.style.width = width + "mm";
        el.style.height = height + "mm";
        _this.productionData.sheetHead[key].width = width;
        _this.productionData.sheetHead[key].height = height;
        _this.$store.commit("SEVA_PRODUCTION", _this.productionData);
      };
      document.onmouseup = function () {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },
    // 移动元素
    arrowMove(e, key) {
      // e.dragging = true;
      if (!this.editorType) {
        return;
      }
      // 元素大小
      let elW = new unitConversion().pxConversionMm(
        e.currentTarget.offsetWidth
      );
      let elH = new unitConversion().pxConversionMm(
        e.currentTarget.offsetHeight
      );
      // 元素位置
      let elL = e.currentTarget.offsetLeft;
      let elT = e.currentTarget.offsetTop;
      // 鼠标位置
      let x = e.clientX;
      let y = e.clientY;
      // 窗口大小
      let w = this.productionData.paperContent.width;
      let h = this.productionData.sheetHead.height;
      // let w = window.innerWidth;
      // let h = window.innerHeight;
      // // 鼠标到元素左边距离
      // let moveX = x - elL;
      // let moveY = y - elT;
      let el = e.currentTarget;
      let left, top;
      let _this = this;
      //
      document.onmousemove = function (e) {
        // 根据鼠标位置计算出移动的距离
        left = new unitConversion().pxConversionMm(elL - (x - e.clientX));
        top = new unitConversion().pxConversionMm(elT - (y - e.clientY));
        // 禁止越界 start
        if (left < 0) {
          left = 0;
        }
        if (top < 0) {
          top = 0;
        }
        if (elW + left > w) {
          left = w - elW;
        }
        if (elH + top > h) {
          top = h - elH;
        }
        // 禁止越界 end
        el.style.left = left + "mm";
        el.style.top = top + "mm";
      };
      //  console.log(this.productionData);

      document.onmouseup = function () {
        document.onmousemove = null;
        document.onmouseup = null;
        if ((!left && left != 0) || (!top && top != 0)) {
          return;
        }
        _this.productionData.sheetHead[key].left = left;
        _this.productionData.sheetHead[key].top = top;
        _this.$store.commit("SEVA_PRODUCTION", _this.productionData);
      };
    },

    getKeyStyle(key, json) {
      // console.log(this[json]);
      if (!this.productionData[json] || !this.productionData[json][key]) {
        return;
      }
      // this[json][key]
      let width = `width:${this.productionData[json][key].width}mm;`;
      let height = `height:${this.productionData[json][key].height}mm;`;
      let top = `top:${this.productionData[json][key].top}mm;`;
      let left = `left:${this.productionData[json][key].left}mm;`;
      if (key == "barCode") {
        height =
          height + `line-height:${this.productionData[json][key].height}mm;`;
      }
      return width + height + top + left;
    },
  },
};
</script>
<style></style>
<style lang="scss"></style>
